AgrimarketServices.service('contractsService', ['$http', '$rootScope', 'configurationFactory', 'enumFactory', '$filter',
    function ($http, $rootScope, configurationFactory, enumFactory, $filter) {

        var sendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetValidatedContractsPaginate = function (idStateCover, idCrop, idProduct, idTypeOrder, date, idContractStr, numClient, idCompagny, idContractBaseStr, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetValidatedContractsPaginate',
                params: {
                    idStateCover: idStateCover,
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOrder: idTypeOrder,
                    date: date,
                    idContractStr: idContractStr,
                    idContractBaseStr: idContractBaseStr,
                    numClient: numClient,
                    idCompagny: idCompagny,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetPendingContractsPaginate = function (idStateCover, idCrop, idProduct, idTypeOrder, date, idContractStr, numClient, idCompagny, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetPendingContractsPaginate',
                params: {
                    idStateCover: idStateCover,
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOrder: idTypeOrder,
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    idCompagny: idCompagny,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetToValidatedContractsPaginate = function (idCrop, idProduct, idTypeOrder, date, idContractStr, numClient, idCompagny, nameContractor, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetToValidatedContractsPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOrder: idTypeOrder,
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    idCompagny: idCompagny,
                    nameContractor: nameContractor,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetCanceledContractsPaginate = function (idCrop, idProduct, idTypeOrder, date, idContractStr, numClient, idCompagny, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetCanceledContractsPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOrder: idTypeOrder,
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    idCompagny: idCompagny,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetDelayedSigningContractsPaginate = function (idCrop, idProduct, date, numClient, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetDelayedSigningContractsPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    date: date,
                    numClient: numClient,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetPricedContractsPaginate = function (idStateCover, idCrop, idProduct, idTypeOrder, date, idContractStr, numClient, idCompagny, idTypeFixation, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetPricedContractsPaginate',
                params: {
                    idStateCover: idStateCover,
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOrder: idTypeOrder,
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    idCompagny: idCompagny,
                    idTypeFixation: idTypeFixation,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetIdContractsToSelect = function (idTabContracts, idStateCover, idCrop, idProduct, idTypeOrder, date, idContractStr, numClient, idCompagny, idTypeFixation, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetIdContractsToSelect',
                params: {
                    idTabContracts: idTabContracts,
                    idStateCover: idStateCover,
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOrder: idTypeOrder,
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    idCompagny: idCompagny,
                    idTypeFixation: idTypeFixation
                }
            };
            sendRequest(config, callback);
        };


        this.GetDetailExpiry = function (LstExpiries, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'WsdApi/GetExpiriesByIdExpiries',
                params: {
                    lstExpiry: JSON.stringify(LstExpiries)
                }
            };

            sendRequest(config, callback);
        };

        this.CoverContrats = function (lstToCover, isValidatePriceObjectifView, isCoverContractToFixed, prixTargetPricedContractFIxed, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/CoverContractsSelected',
                cache: false,
                data: lstToCover,
                params: {
                    isValidatePriceObjectifView: isValidatePriceObjectifView,
                    isCoverContractToFixed: isCoverContractToFixed,
                    prixTargetPricedContractFIxed: prixTargetPricedContractFIxed
                }
            };
            sendRequest(config, callback);
        };

        this.SaveDateDebutSubscriptionContract = function (callback) {
            var config = {
                method: "POST",
                url: configurationFactory.Url['Api'] + 'Contracts/SaveDateDebutSubscriptionContract',
            };
            sendRequest(config, callback);
        };

        this.SaveNewContract = function (contractVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/SaveNewContract',
                cache: false,
                crossDomain: true,
                data: contractVm
            };

            sendRequest(config, callback);
        };

        this.SignContractForDelayedSigning = function (idContract, idConnectionSelected, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/SignContractForDelayedSigning',
                cache: false,
                crossDomain: true,
                params: {
                    idContract: idContract,
                    idConnectionSelected: idConnectionSelected
                }
            };
            sendRequest(config, callback);
        };

        this.SaveNewContractAndSendSmsYouSign = function (contractVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/SaveNewContractAndSendSmsYouSign',
                cache: false,
                crossDomain: true,
                data: contractVm
            };
            sendRequest(config, callback);
        };

        this.SetIsLogicallyDeleted = function (idContract, callback) {
            var config = {
                method: 'PUT',
                url: configurationFactory.Url['Api'] + 'Contracts/SetIsLogicallyDeleted',
                params: {
                    idContract: idContract
                }
            };

            sendRequest(config, callback);
        };

        this.UpdateContract = function (currentContract, callback) {
            var config = {
                method: 'PUT',
                url: configurationFactory.Url['Api'] + 'Contracts/UpdateContract',
                data: currentContract
            };

            sendRequest(config, callback);
        };

        this.GetHistoContract = function (idContract, idCrop, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetHistoryContractsTargetPriceByIdInit',
                params: { idParentContract: idContract, idCrop: idCrop }
            };

            sendRequest(config, callback);
        };

        this.GetFiltersWithContractsByCropAndTabContract = function (idCrop, idTabContracts, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetFiltersWithContractsByCropAndTabContract',
                params: { idCrop: idCrop, idTabContracts: idTabContracts }
            };

            sendRequest(config, callback);
        };

        this.GetFiltersCommitmentsByCropAndProductAndTypeCommitment = function (idCrop, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetFiltersCommitmentsByCropAndProductAndTypeCommitment',
                params: { idCrop: idCrop }
            };
            sendRequest(config, callback);
        };

        this.GetCompatibleCommitmentsWithFilter = function (idExpiry, max, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetCompatibleCommitmentsWithFilter',
                params: {
                    idExpiry: idExpiry,
                    itemPerPage: max,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetTypeCommitmentsByCropAndProductAndTypeCommitment = function (idCrop, idProduct, idTypeCommitment, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetTypeCommitmentsByCropAndProductAndTypeCommitment',
                params: { idCrop: idCrop, idProduct: idProduct, idTypeCommitment: idTypeCommitment }
            };
            sendRequest(config, callback);
        };

        this.GetContractCommitmentsWithFilter = function (idCrop, idProduct, idTypeCommitment, max, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetContractCommitmentsWithFilter',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeCommitment: idTypeCommitment,
                    itemPerPage: max,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetContractsCommitmentValidatedPaginate = function (idCrop, idProduct, idTypeCommitment, date, idContractStr, numClient, idCompagny, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetContractsCommitmentValidatedPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeCommitment: idTypeCommitment,
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    idCompagny: idCompagny,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetContractsSurfaceCommitmentValidatedPaginate = function (idCrop, idProduct, date, idContractStr, numClient, idCompagny, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetContractsSurfaceCommitmentValidatedPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    idCompagny: idCompagny,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetFiltersWithContractsCommitmentByCrop = function (idCrop, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetFiltersWithContractsCommitmentByCrop',
                params: { idCrop: idCrop }
            };

            sendRequest(config, callback);
        };

        this.GetFiltersWithContractsSurfaceCommitmentByCrop = function (idCrop, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetFiltersWithContractsSurfaceCommitmentByCrop',
                params: { idCrop: idCrop }
            };

            sendRequest(config, callback);
        };

        this.GetFiltersWithContractsCommitment = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetFiltersWithContractsCommitment',
            };

            sendRequest(config, callback);
        };

        this.GetFiltersWithContractsSurfaceCommitment = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetFiltersWithContractsSurfaceCommitment',
            };

            sendRequest(config, callback);
        };

        this.GetFiltersWithUserContractsByCropAndTabContract = function (idTabContracts, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetFiltersWithUserContractsByCropAndTabContract',
                params: {
                    idTabContracts: idTabContracts
                }
            };

            sendRequest(config, callback);
        };

        this.GetUserValidatedContractsPaginate = function (idCrop, idProduct, idTypeOrder, date, idContractStr, numClient, itemPerPage, pageNo, sortKey, reverse, nameOffer, idTypeContract, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetUserValidatedContractsPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOrder: idTypeOrder,
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse,
                    nameOffer: nameOffer,
                    idTypeContract: idTypeContract
                }
            };
            sendRequest(config, callback);
        };

        this.GetUserPendingContractsPaginate = function (idCrop, idProduct, idTypeOrder, date, idContractStr, numClient, itemPerPage, pageNo, sortKey, reverse, nameOffer, idTypeContract, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetUserPendingContractsPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOrder: idTypeOrder,
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse,
                    nameOffer: nameOffer,
                    idTypeContract: idTypeContract
                }
            };
            sendRequest(config, callback);
        };

        this.GetUserDelayedSigningContractsPaginate = function (idCrop, idProduct, date, numClient, itemPerPage, pageNo, sortKey, reverse, nameOffer, idTypeContract, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetUserDelayedSigningContractsPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    date: date,
                    numClient: numClient,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse,
                    nameOffer: nameOffer,
                    idTypeContract: idTypeContract
                }
            };
            sendRequest(config, callback);
        };

        this.GetUserPricedContractsPaginate = function (idCrop, idProduct, idTypeOrder, date, idContractStr, numClient, idTypeFixation, itemPerPage, pageNo, sortKey, reverse, nameOffer, idTypeContract, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetUserPricedContractsPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOrder: idTypeOrder,
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    idTypeFixation: idTypeFixation,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse,
                    nameOffer: nameOffer,
                    idTypeContract: idTypeContract
                }
            };
            sendRequest(config, callback);
        };

        this.GetContractForDelayedSigning = function (idContract, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetContractForDelayedSigning',
                params: {
                    idContract: idContract
                }
            };
            sendRequest(config, callback);
        };

        this.GetUserCanceledContractsPaginate = function (idCrop, idProduct, idTypeOrder, date, idContractStr, numClient, itemPerPage, pageNo, sortKey, reverse, nameOffer, idTypeContract, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetUserCanceledContractsPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOrder: idTypeOrder,
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse,
                    nameOffer: nameOffer,
                    idTypeContract: idTypeContract
                }
            };
            sendRequest(config, callback);
        };

        this.GetContractCommitmentByIdContract = function (idContract, idOffer, basePrice, targetBasePrice, marketPrice, targetPrice, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetContractCommitmentByIdContract',
                params: { idContract: idContract, idOffer: idOffer, basePrice: basePrice, targetBasePrice: targetBasePrice, marketPrice: marketPrice, targetPrice: targetPrice }
            };
            sendRequest(config, callback);
        };

        this.GetTargetContractsToValidate = function (price, listOfferExpiriesVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/GetTargetContractsToValidate',
                data: {
                    ListOfferExpiriesVm: listOfferExpiriesVm,
                    Price: price
                }
            };
            sendRequest(config, callback);
        };

        this.ValidTargetContracts = function (idOffer, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/ValidTargetContracts',
                params: { idOffer: idOffer }
            };
            sendRequest(config, callback);
        };

        this.GetContractFutureByIdContractFuture = function (idContractFuture, basePrice, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetContractFutureByIdContractFuture',
                params: { idContractFuture: idContractFuture, basePrice: basePrice }
            };
            sendRequest(config, callback);
        };

        this.GetCompatibleContractFuturesWithFilter = function (idExpiry, max, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetCompatibleContractFuturesWithFilter',
                params: {
                    idExpiry: idExpiry,
                    itemPerPage: max,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };


        this.GetFixationsByIdContractCommitment = function (idContractCommitment, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetFixationsByIdContractCommitment',
                params: {
                    idContractCommitment: idContractCommitment,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.ChkTargetPriceIsValid = function (selectedContract, offerPriceToCompare, paramAuthorizeTargetPriceUnderOfferPrice) {
            if (!selectedContract.hasOwnProperty("NewTargetPrice") || selectedContract.NewTargetPrice === null || selectedContract.NewTargetPrice < 0) {
                selectedContract.NewTargetPrice = null;
                return $rootScope.editableObjectsFactory.EditableObjects.Error_UserContractsFirmMonitoring_MessageTargetPrice.Content;
            } else {
                //Si fixation price
                if (selectedContract.IdTypeFixation == 3 && selectedContract.OfferCurrencyUnit != null && selectedContract.OfferCurrencyUnit !== undefined && selectedContract.OfferCurrencyUnit.DecimalFinal != null) {
                    offerPriceToCompare = selectedContract.OfferPriceUpdatedWithCoeffConverted;
                }
                selectedContract.NewTargetPrice = selectedContract.NewTargetPrice.toString().replace(",", ".");
                if (!parseFloat(selectedContract.NewTargetPrice)) {
                    return $rootScope.editableObjectsFactory.EditableObjects.Error_UserContractsFirmMonitoring_PriceMessage.Content;
                } else {
                    selectedContract.NewTargetPrice = Number(selectedContract.NewTargetPrice);
                }
                if (!paramAuthorizeTargetPriceUnderOfferPrice && selectedContract.NewTargetPrice < offerPriceToCompare) {
                    selectedContract.NewTargetPrice = null;
                    return $rootScope.editableObjectsFactory.EditableObjects.Error_UserContractsFirmMonitoring_TargetPriceCompareMessage.Content + " " + offerPriceToCompare;
                }
            }
            return null;
        };

        this.GetInfosForFixContractCommitment = function (idContractCommitment, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetInfosForFixContractCommitment',
                params: {
                    idContractCommitment: idContractCommitment,
                }
            };
            sendRequest(config, callback);
        };


        this.FixNewContractFromContractCommitment = function (vm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/FixNewContractFromContractCommitment',
                data: vm
            };
            sendRequest(config, callback);
        };

        this.GetContractTargetPrice = function (idContract, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetContractTargetPrice',
                params: {
                    idContract: idContract,
                }
            };
            sendRequest(config, callback);
        };

        this.ValidatedContract = function (idContract, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/ValidatedContract',
                params: {
                    idContract: idContract,
                }
            };
            sendRequest(config, callback);
        };

        this.RefusedContract = function (idContract, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/RefusedContract',
                params: {
                    idContract: idContract,
                }
            };
            sendRequest(config, callback);
        };

        this.EditedContract = function (contract, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/EditedContract',
                data: contract
            };
            sendRequest(config, callback);
        };

        this.GetFiltersWithContractsBuiltPriceByCrop = function (idCrop, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetFiltersWithContractsBuiltPriceByCrop',
                params: { idCrop: idCrop }
            };

            sendRequest(config, callback);
        };

        this.GetContractsBuiltPricePaginate = function (idCrop, idProduct, dateContract, idContract, numClient, idContractState, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Contracts/GetContractsBuiltPricePaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    dateContract: dateContract,
                    idContract: idContract,
                    numClient: numClient,
                    idContractState: idContractState,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };
        
        this.EditedBuiltPriceContract = function (idContract, newQuantity, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/EditQuantityContractBuiltPrice',
                params: {
                    idContract: idContract,
                    newQuantity: newQuantity
                }
            };
            sendRequest(config, callback);
        };

        this.UpdateContractPendingCover = function (idContract, callback) {
            var config = {
                method: 'PUT',
                url: configurationFactory.Url['Api'] + 'Contracts/UpdateContractPendingCover',
                params: {
                    idContract: idContract
                }
            };

            sendRequest(config, callback);
        };

    }]);