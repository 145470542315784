AgrimarketControllers.controller('createofferController', ['$scope', '$filter', '$rootScope', '$routeParams', '$http', '$location', '$upload',
    "currentUserFactory", 'configurationFactory', 'enumFactory', '$log', 'wsdApiService', '$timeout', 'productsService', 'typeOffersService',
    'typeQuotesService', 'cropsService', 'offerService', 'typeOptionsService', 'typeCalculOptionsService', 'offerVisibilityService', 'deliveryPeriodService', 'validityDurationService', 'groupContactService', 'templatesService',
    'conditionsService', '$q', '$sce', 'dateService', 'compagnyService', 'typeOfferCommitmentsService', 'typeCommitmentsService', 'parametersFactory', 'operationService', 'contractsService', 'languageService', 'contractBaseService', 'typeFixationBasesService', 'currencyService', 'unitService', 'categoryOfferService', 'ceremisApiService', 'criteriaOfferService', 'modulesFactory', 'typePriceService',
    function ($scope, $filter, $rootScope, $routeParams, $http, $location, $upload, currentUserFactory, configurationFactory, enumFactory, $log, wsdApiService,
        $timeout, productsService, typeOffersService, typeQuotesService, cropsService, offerService, typeOptionsService, typeCalculOptionsService, offerVisibilityService, deliveryPeriodService, validityDurationService, groupContactService,
        templatesService, conditionsService, $q, $sce, dateService, compagnyService, typeOfferCommitmentsService, typeCommitmentsService, parametersFactory, operationService, contractsService, languageService, contractBaseService, typeFixationBasesService, currencyService, unitService, categoryOfferService, ceremisApiService, criteriaOfferService, modulesFactory, typePriceService) {

        $scope.disableSubmitedButton = false;
        $scope.errorMessage = '';
        $scope.infosMultiCropMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_MultiCrop_Text_step1.Content;
        $scope.lstSelectedVarieties = [];
        $scope.lstSelectedGroup = [];
        $scope.lstSelectedContract = [];
        $scope.lstSelectedtmp = [];
        $scope.currentOffer = {};
        $scope.lstOffersCeremis = [];
        $scope.refIdsSubCrops  = [];
        $scope.fixe = false;
        $scope.blankContract = false;
        $scope.tmpOffer = {};
        $scope.watchedit = null;
        $scope.watchEditBuiltPrice = null;
        $scope.watchTypeFixation = null;
        $scope.watchFixationBaseConstraint = null;
        $scope.master = false;
        $scope.master2 = false;
        $scope.fileName = "";
        $scope.ModeDupliquer = false;
        $scope.ModeEdition = false;
        $scope.validTypeTemplateNotSame = false;
        $scope.paramWatcher = {};
        $scope.year = 0;
        $scope.years = [];
        $scope.enumTypeQuotes = enumFactory.TypeQuotes;
        $scope.enumValidityDurations = enumFactory.ValidityDurations;
        $scope.enumTypeOffers = enumFactory.TypeOffer;
        $scope.enumTypeOfferCommitments = enumFactory.TypeOfferCommitment;
        $scope.enumTypeCommitments = enumFactory.TypeCommitment;
        $scope.paramDisplaySurfaceYield = parametersFactory.Parameters.DisplaySurfaceYield.Value === "1";
        $scope.IsUneal = $rootScope.paramNameCoop === enumFactory.Coops.Uneal ? true : false;
        $scope.enumTypeOption = enumFactory.TypeOption;
        $scope.enumVisibilityOffer = enumFactory.VisibilityOffer;
        $scope.strikeAutoVm = [];
        $scope.TotalQuantityLimit = 0;
        $scope.HasQuantityLimit = false;
        $scope.EditableObjectComment = $rootScope.editableObjectsFactory.getIsVisibleByKey("Admin_Offer_Step2_Comment");
        $scope.parambChooseEntryModeActive = parametersFactory.Parameters.bChooseEntryMode.Value === "1";
        $scope.pushNotificationEnabled = parametersFactory.Parameters.GoogleConsoleCloudServiceFilename.Value ;
        $scope.bActivateContractsValidation = parametersFactory.Parameters.bActivateContractsValidation.Value === "1";
        $scope.bActivateSignatureOffer = parametersFactory.Parameters.bActivateSignatureOffer.Value === "1";
        $scope.bActiveDecomposeBase = parametersFactory.Parameters.bActiveDecomposeBase.Value === "1";
        $scope.paramBActiveLinkWithCeremis = parametersFactory.Parameters.bActiveLinkWithCeremis.Value === "1";
        $scope.paramBActivateModeDeltaForStrike = parametersFactory.Parameters.bActivateModeDeltaForStrike.Value === "1";
        $scope.step2_Title = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Step2_Label.Content;

        $scope.Label_Admin_Offer_Strike_ExerciceDate = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_ExerciceDate.Content;
        $scope.Label_Admin_Offer_Strike_Prime = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_Prime.Content;
        $scope.Label_Admin_Offer_Strike_SubscriptionCost = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_SubscriptionCost.Content;
        $scope.Label_Admin_Offer_Strike_Variations = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_Variations.Content;
        $scope.Label_Admin_Offer_Strike_Add = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_Add.Content;
        $scope.Label_Admin_Offer_Strike_Edit = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_Edit.Content;
        $scope.Label_Admin_Offer_Strike_Strike = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_Strike.Content;

        $scope.Admin_CreateOffer_PopUpStrikeAuto_Title = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_PopUpStrikeAuto_Title.Content;
        $scope.Admin_CreateOffer_PopUpStrikeAuto_Label_Margin = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_PopUpStrikeAuto_Label_Margin.Content;
        $scope.Admin_CreateOffer_PopUpStrikeAuto_Label_Cost = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_PopUpStrikeAuto_Label_Cost.Content;
        $scope.Admin_CreateOffer_PopUpStrikeAuto_Column_Label_ExcerciceLimitDate = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_PopUpStrikeAuto_Column_Label_ExcerciceLimitDate.Content;
        $scope.Admin_CreateOffer_PopUpStrikeAuto_Column_Label_Gap = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_PopUpStrikeAuto_Column_Label_Gap.Content;
        $scope.Admin_CreateOffer_PopUpStrikeAuto_Button_Add = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_PopUpStrikeAuto_Button_Add.Content;
        $scope.Admin_CreateOffer_Error_Times_TargetPrice = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Error_Times_TargetPrice.Content;
        $scope.Admin_CreateOffer_Error_Times_TargetPrice2 = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Error_Times_TargetPrice2.Content;
        $scope.Admin_CreateOffer_Error_SelectDurationValidity_TargetPrice = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Error_SelectDurationValidity_TargetPrice.Content;
        $scope.Admin_CreateOffer_Error_SelectTypeCommitment = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Error_SelectTypeCommitment.Content;

        $scope.Label_Admin_Offer_Step3_Display = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_Display.Content;
        $scope.Label_Admin_Offer_Step3_FixationMode = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_FixationMode.Content;
        $scope.Label_Admin_Offer_Step3_CommitmentConstraint = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_CommitmentConstraint.Content;
        $scope.Label_Admin_Offer_Step3_TypeCommitment = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_TypeCommitment.Content;
        $scope.Label_Admin_Offer_Step3_FixationBaseConstraint = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_FixationBaseConstraint.Content;
        $scope.Label_Admin_Offer_Step3_FixationCurrencyUnit = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_FixationCurrencyUnit.Content;
        $scope.Label_Admin_Offer_Step3_FixationDecimal = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_FixationDecimal.Content;
        $scope.Label_Admin_Offer_Step3_FixationExerciceOption = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_FixationExerciceOption.Content;
        $scope.Label_Admin_Offer_Step3_HoursOpenFixationExerciceOption = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_HoursOpenFixationExerciceOption.Content;
        $scope.Label_Admin_Offer_Step3_HoursOpenFixationTargetExerciceOption = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_HoursOpenFixationTargetExerciceOption.Content;
        $scope.Label_Admin_Offer_Step3_StartOpenFixationCommon = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_StartOpenFixationCommon.Content;
        $scope.Label_Admin_Offer_Step3_EndOpenFixationCommon = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_EndOpenFixationCommon.Content;
        $scope.Label_Admin_Offer_Step3_OpenH24FixationCommon = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step3_OpenH24FixationCommon.Content;

        $scope.Label_Admin_Offer_Step1_CategoryOffer = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step1_CategoryOffer.Content;
        $scope.Label_Admin_Offer_Step1_CriteriaOffer = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step1_CriteriaOffer.Content;


        $scope.Label_Admin_Offer_Step4_TonnageLimit = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step4_TonnageLimit.Content;
        $scope.Label_Admin_Offer_Step4_SurfaceLimit = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step4_SurfaceLimit.Content;
        $scope.Label_Admin_Offer_Step4_Label_TonnageLimit = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step4_Label_TonnageLimit.Content;
        $scope.Label_Admin_Offer_Step4_Label_SurfaceLimit = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step4_Label_SurfaceLimit.Content;
        $scope.Label_Admin_Offer_Step4_Tonnes = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step4_Tonnes.Content;
        $scope.Label_Admin_Offer_Step4_Hectares = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step4_Hectares.Content;

        $scope.Label_Admin_Offer_Step5_DistributionTonnageLimit = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step5_DistributionTonnageLimit.Content;
        $scope.Label_Admin_Offer_Step5_TotalTonnage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step5_TotalTonnage.Content;
        $scope.Label_Admin_Offer_Step5_DistributionSurfaceLimit = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step5_DistributionSurfaceLimit.Content;
        $scope.Label_Admin_Offer_Step5_TotalSurface = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step5_TotalSurface.Content;

        $scope.Label_Admin_Offer_Step2_Comment = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step2_Comment.Content;
        $scope.Label_Admin_Offer_Step2_Strike_Button_Validate = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_Button_Validate.Content;

        $scope.Label_Admin_Offer_ErrorMessage_Step5_TonnageByGroup = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_ErrorMessage_Step5_TonnageByGroup.Content;
        $scope.Label_Admin_Offer_ErrorMessage_Step5_Sum_LowerTonnage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_ErrorMessage_Step5_Sum_LowerTonnage.Content;
        $scope.Label_Admin_Offer_ErrorMessage_Step5_Sum_HigherTonnage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_ErrorMessage_Step5_Sum_HigherTonnage.Content;
        $scope.Label_Admin_Offer_ErrorMessage_Step5_TonnageGroup = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_ErrorMessage_Step5_TonnageGroup.Content;
        $scope.Label_Admin_Offer_ErrorMessage_Step4_DistributionTonnage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_ErrorMessage_Step4_DistributionTonnage.Content;

        $scope.Admin_CreateOffer_Error_MissingFixingTime_ExerciceOption = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Error_MissingFixingTime_ExerciceOption.Content;
        $scope.Admin_CreateOffer_Error_InvalidDateFormat_ExerciceOption = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Error_InvalidDateFormat_ExerciceOption.Content;
        $scope.Admin_CreateOffer_Error_InvalidFixingTime_ExerciceOption = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Error_InvalidFixingTime_ExerciceOption.Content;
        $scope.Admin_CreateOffer_Error_MissingFixingTime_TargetExerciceOption = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Error_MissingFixingTime_TargetExerciceOption.Content;
        $scope.Admin_CreateOffer_Error_InvalidDateFormat_TargetExerciceOption = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Error_InvalidDateFormat_TargetExerciceOption.Content;
        $scope.Admin_CreateOffer_Error_InvalidFixingTime_TargetExerciceOption = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Error_InvalidFixingTime_TargetExerciceOption.Content;

        $scope.Label_Admin_Offer_PrimeExpiry = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_PrimeExpiry_ErrorMessage.Content;
        $scope.Label_Admin_Offer_MarginExpiry = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_MarginExpiry_ErrorMessage.Content;
        $scope.Label_Admin_Offer_FinancialCostsExpiry = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_FinancialCostsExpiry_ErrorMessage.Content;
        $scope.Label_Admin_Offer_LogisticCostsExpiry = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_LogisticCostsExpiry_ErrorMessage.Content;
        $scope.Label_Admin_Offer_DepotExpiry = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_DepotExpiry_ErrorMessage.Content;
        $scope.Label_Admin_Offer_OthersExpiry = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_OthersExpiry_ErrorMessage.Content;
    

        $scope.moduleAutomaticCover = $rootScope.ModuleAutomaticCover.Active;
        $scope.signingSmsGlobalyActived = modulesFactory.Modules.ModuleSignatureElectroniqueYouSign.Active || modulesFactory.Modules.ModuleElectronicSignature.Active;

        var start;
        var end;
        var startpriseposition;
        var endpriseposition;
        var startpriseobjectif;
        var endpriseobjectif;
        var startprisepositionBase;
        var endprisepositionBase;
        var startpriseobjectifBase;
        var endpriseobjectifBase;
        var startprisepositionExerciceOption;
        var endprisepositionExerciceOption;
        var startPositionTargetExerciceOption;
        var endPositionTargetExerciceOption;
        var nbStep = 5;
        var idOffer = $routeParams.idoffer;
        var lstAllTypeQuotes;
        var ducroquetValidTypeCoverPopUp = false;
        var lstTypeFixationBasesOrigin;

        $scope.lstEntryModes = [
            { Id: false, Label: $rootScope.editableObjectsFactory.EditableObjects.TonLabel.Content },
            { Id: true, Label: $rootScope.editableObjectsFactory.EditableObjects.LotLabel.Content }
        ];

        $scope.init = function () {
            productsService.GetAllProducts(function (data, status) {
                if (status === null) {
                    $scope.lstProducts = data;
                    $scope.lstAllProducts = angular.copy(data);
                    if (idOffer === undefined || idOffer === null || idOffer == -1) {
                        offerService.GetBodyOffer(function (data) {
                            $scope.currentOffer = data;
                            $scope.step = 1;
                            $scope.currentOffer.VarietiesVm = [];
                            $scope.currentOffer.GroupContacts = [];
                            $scope.currentOffer.GroupContacts.OfferGroupContactTonnages = [];
                            $scope.currentOffer.Templates = [];
                            $scope.currentOffer.Validate = false;
                            $scope.currentOffer.IsVisibleTC = false;
                            $scope.currentOffer.ContractSigningEnabled = true;
                            $scope.currentOffer.DisplaySurfaceYield = false;
                            $scope.currentOffer.AllowDateEndTargetPrice = false;
                            $scope.currentOffer.IdVisibilityOffer = 1; // On préselectionne Tous par défaut
                            $scope.currentOffer.LotEntryMode = undefined;
                            $scope.lstOfferExpiry = $scope.currentOffer.OfferExpiriesVm = [];
                            $scope.currentOffer.OfferCurrencyUnits = {
                                IdCurrencyFinal: null,
                                IdUnitFinal: null,
                                IdCurrencyForComponents: null,
                                IdUnitForComponents: null,
                                DecimalFinal: 2,
                                DecimalForComponents: 2
                            };
                            $scope.currentOffer.TypeFixationTypeOrders = {
                                SoldBase: false,
                                TargetPriceBase: false,
                                SoldFuture: false,
                                TargetPriceFuture: false,
                                SoldGlobalPrice: false,
                                TargetPriceGlobalPrice: false,
                                SoldExerciceOption: false,
                                TargetExerciceOption: false
                            };

                            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme)
                                $scope.currentOffer.IdTypeOfferCommitment = $scope.enumTypeOfferCommitments.EngagementFacultatif;
                            $scope.currentOffer.OfferGroupContactTonnageVm = [];
                            $scope.currentOffer.IdsSubCrops = [];
                        });
                    } else {
                        offerService.GetOfferById(idOffer, function (data) {
                            $scope.currentOffer = data;
                            $scope.currentOffer.StartValidity = $filter('date')($scope.currentOffer.StartValidity, 'dd/MM/yyyy');
                            $scope.currentOffer.EndValidity = $filter('date')($scope.currentOffer.EndValidity, 'dd/MM/yyyy');
                            $scope.currentOffer.isVisibleH24 = $scope.currentOffer.StartVisibility === "00:00:00" && $scope.currentOffer.EndVisibility === "23:59:59";
                            $scope.currentOffer.isPositionH24 = $scope.currentOffer.StartPosition === "00:00:00" && $scope.currentOffer.EndPosition === "23:59:59";
                            $scope.currentOffer.isTargetPriceH24 = $scope.currentOffer.StartTargetPrice === "00:00:00" && $scope.currentOffer.EndTargetPrice === "23:59:59";
                            $scope.currentOffer.isPositionBaseH24 = $scope.currentOffer.StartPositionBase === "00:00:00" && $scope.currentOffer.EndPositionBase === "23:59:59";
                            $scope.currentOffer.isTargetPriceBaseH24 = $scope.currentOffer.StartTargetPriceBase === "00:00:00" && $scope.currentOffer.EndTargetPriceBase === "23:59:59";
                            $scope.currentOffer.isPositionExerciceOptionH24 = $scope.currentOffer.StartPositionExerciceOption === "00:00:00" && $scope.currentOffer.EndPositionExerciceOption === "23:59:59";
                            $scope.currentOffer.isTargetExerciceOptionH24 = $scope.currentOffer.StartPositionTargetExerciceOption === "00:00:00" && $scope.currentOffer.EndPositionTargetExerciceOption === "23:59:59";
                            $scope.step2_Title = $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Engagement ? $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Step2_Label_Commitment.Content : $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Step2_Label.Content;

                            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.PrixConstruit) {
                                $scope.ObservationPeriodStarted = new Date() >= new Date(data.ObservationPeriodBuiltPriceStartDate);
                                $scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate = $filter('date')($scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate, 'dd/MM/yyyy');
                                $scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate = $filter('date')($scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate, 'dd/MM/yyyy');
                                $scope.currentOffer.ObservationPeriodBuiltPriceStartDate = $filter('date')($scope.currentOffer.ObservationPeriodBuiltPriceStartDate, 'dd/MM/yyyy');
                                $scope.currentOffer.ObservationPeriodBuiltPriceEndDate = $filter('date')($scope.currentOffer.ObservationPeriodBuiltPriceEndDate, 'dd/MM/yyyy');
                            }

                            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Engagement || $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.EngagementSurface) {
                                if ($rootScope.paramBUseDeliveriesDates) {
                                    $scope.currentOffer.DeliveryStartDate = $filter('date')($scope.currentOffer.DeliveryStartDate, 'dd/MM/yyyy');
                                    $scope.currentOffer.DeliveryEndDate = $filter('date')($scope.currentOffer.DeliveryEndDate, 'dd/MM/yyyy');
                                }

                                if ($rootScope.paramBUsePaymentDate)
                                    $scope.currentOffer.PaymentDate = $filter('date')($scope.currentOffer.PaymentDate, 'dd/MM/yyyy');
                            }

                            if ($scope.currentOffer.OfferExpiriesVm && $scope.currentOffer.OfferExpiriesVm.length > 0) {
                                var offerExpiries = $scope.currentOffer.OfferExpiriesVm;
                                $scope.lstOfferExpiry = offerExpiries.filter(function (element) {
                                    element.DateClosed = $filter('date')(element.DateClosed, 'dd/MM/yyyy');
                                    if (element.DateStart)
                                        element.DateStart = $filter('date')(element.DateStart, 'dd/MM/yyyy');
                                    if (element.OptionLimitExerciceDate)
                                        element.OptionLimitExerciceDate = $filter('date')(element.OptionLimitExerciceDate, 'dd/MM/yyyy');

                                    if ($scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.Option) {
                                        if ($rootScope.paramBUseDeliveriesDates) {
                                            element.DeliveryStartDate = $filter('date')(element.DeliveryStartDate, 'dd/MM/yyyy');
                                            element.DeliveryEndDate = $filter('date')(element.DeliveryEndDate, 'dd/MM/yyyy');
                                        }

                                        if ($rootScope.paramBUsePaymentDate)
                                            element.PaymentDate = $filter('date')(element.PaymentDate, 'dd/MM/yyyy');
                                    }

                                    element.DateMin = new Date();
                                    return element;
                                });
                                loadStrikeAutoVm();

                            } else {
                                $scope.lstOfferExpiry = [];
                            }
                            $scope.currentOffer.IdMarket = $scope.currentOffer.IdMarket;
                            $scope.currentOffer.IdExpiry = !$scope.lstOfferExpiry || $scope.lstOfferExpiry.length === 0 ? null : $scope.lstOfferExpiry[0].IdExpiryWSD;

                            $scope.HasQuantityLimit = $scope.currentOffer.QuantityLimit === undefined || $scope.currentOffer.QuantityLimit === null ? false : true;
                            $scope.HasContract = $scope.currentOffer.HasContract;
                            $scope.HasContractBase = $scope.currentOffer.HasContractBase;
                            $scope.HasContractOption = $scope.currentOffer.HasContractOption;
                            $scope.GetDataStep2();
                            $scope.GetDataStep3();
                            $scope.GetDataStep5();
                            if ($scope.currentOffer.IdAttachment !== undefined && $scope.currentOffer.IdAttachment !== null) {
                                $scope.fileName = $scope.currentOffer.Attachment.PublicLabel;
                            }
                            if (!$scope.fixe) {
                                if ($scope.currentOffer.IdMarket !== undefined && $scope.currentOffer.IdMarket !== null)
                                    $scope.GetExpiries(true);
                            }
                            if (data.Validate) {
                                $scope.step = 1;
                                if (($scope.watchedit === undefined || $scope.watchedit === null)) {
                                    $scope.VerifChangementData();
                                }
                            }
                            else {
                                $scope.step = data.Step;
                            }
                            if ($location.path() == '/admin/offer/dupliquer/' + idOffer) {
                                $scope.ModeDupliquer = true;
                                $scope.HasContract = false;
                                $scope.HasContractBase = false;
                                $scope.HasContractOption = false;
                                $scope.currentOffer.HasContract = false;
                                $scope.currentOffer.HasContractBase = false;
                                $scope.currentOffer.HasContractOption = false;
                                $scope.currentOffer.Validate = false;
                                if ($scope.currentOffer.OfferExpiriesVm)
                                    $scope.currentOffer.OfferExpiriesVm.forEach(function (oe) {
                                        oe.HasContract = false;
                                        oe.HasContractBase = false;
                                        oe.HasContractOption = false;
                                    });
                                $scope.step = 1;
                                if ($scope.lstOfferExpiry.length > 0)
                                    removeExpiryClosed();
                            }
                            else if ($scope.currentOffer.Validate) {
                                $scope.ModeEdition = true;
                            }

                        });
                    }
                } else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }

                compagnyService.GetAll(function (data, status) {
                    if (status === null) {
                        $scope.lstCompagnies = data;
                        if ($scope.lstCompagnies != null && $scope.lstCompagnies.length == 1)
                            $scope.FilterProducts($scope.lstCompagnies[0].IdCompagny, false);
                        else
                            $scope.FilterProducts(null, false);

                    }
                    else if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else {
                        $scope.errorMessage = status;
                    }
                });

            });

            typeOffersService.GetTypeOffers(function (data, status) {
                if (status === null) {
                    $scope.lstTypeOffer = data;
                }
                else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            if ($rootScope.paramBUseOperation) {
                operationService.GetOperationsByTreatment(function (data, status) {
                    if (status === null) {
                        $scope.lstOperations = data;
                    }
                    else if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = status;
                });

                typePriceService.GetTypePrices(function (data, status) {
                    if (status === null) {
                        $scope.lstTypePrices = data;
                    }
                    else if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = status;
                });
            }

            typeQuotesService.GetTypeQuotes(function (data, status) {
                if (status === null) {
                    lstAllTypeQuotes = data;
                    $scope.updateLstTypeQuote();
                }
                else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            cropsService.GetCropsVisible(function (data, status) {
                if (status === null) {
                    $scope.lstCrops = data;

                }
                else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            conditionsService.GetTreesForSelect(function (data, status) {//On charge maintenant à la première étape à cause de du cas de l'engagement sans achat de base
                if (status === null) {
                    $scope.lstTree = data;
                } else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            typeOptionsService.GetTypeOptions(function (data, status) {
                if (status === null) {
                    $scope.lstTypeOption = data;
                }
                else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            typeCalculOptionsService.GetTypeCalculOptions(function (data, status) {
                if (status === null) {
                    $scope.lstTypeCalculOption = data;
                }
                else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            languageService.GetDefaultLanguage(function (data, status) {
                if (status === null) {
                    $scope.defaultLanguage = data;
                }
                else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            categoryOfferService.GetAllCategoryOffers(function (data, status) {
                if (status === null) {
                    $scope.lstCategoryOffer = data;
                }
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            criteriaOfferService.GetAllCriteriaOffers(function (data, status) {
                if (status === null) {
                    $scope.lstCriteriaOffer = data;
                }
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });
        };

        $scope.updateLstTypeQuoteAndSetContractValidationDisabled = function () {
            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.MultiCampagne)
                $scope.currentOffer.IdTypeQuote = enumFactory.TypeQuotes.Fixe;
            else
                $scope.currentOffer.IdTypeQuote = null;

            $scope.currentOffer.ContractValidationDisabled = $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.ContratVierge;
            $scope.step2_Title = $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Engagement ? $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Step2_Label_Commitment.Content : $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateOffer_Step2_Label.Content;
            $scope.updateLstTypeQuote();
        };

        $scope.updateLstTypeQuote = function () {
            if ($scope.currentOffer.IdTypeOffer == $scope.enumTypeOffers.Option) {
                $scope.lstTypeQuotes = lstAllTypeQuotes.filter(function (quote) { return quote.IdTypeQuote != 1; });
                $scope.currentOffer.IdTypeOption = 1;
                $scope.currentOffer.IdTypeCalculOption = $scope.paramBActiveLinkWithCeremis ? enumFactory.TypeCalculOption.Automatic : enumFactory.TypeCalculOption.Manual;
            }
            else {
                $scope.lstTypeQuotes = lstAllTypeQuotes;
            }
        };

        $scope.GetDataStep2 = function () {
            loadMarkets(loadAndFilterOffersCeremis);

            $scope.fixe = $scope.currentOffer.IdTypeQuote === enumFactory.TypeQuotes.Fixe;
            $scope.blankContract = $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.ContratVierge;
            $scope.contractBuiltPrice = $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.PrixConstruit;
            $scope.multiCampagne = $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.MultiCampagne;

            //valeur par défaut quand on n'a jamais coché la case et qu'on est venu la premiere fois
            if (!$scope.fixe && !$scope.currentOffer.BasisMajorationValue && !$scope.BasisMajorationMonth && !$scope.currentOffer.BasisMajorationEnabled) {
                $scope.currentOffer.BasisMajorationValue = 0.93;
                $scope.currentOffer.BasisMajorationMonth = 7;
            }
        };

        $scope.GetDataStep3 = function () {
            if($scope.multiCampagne) {
                $scope.currentOffer.TypeFixationTypeOrders.FixationGlobalPrice = true;
                $scope.currentOffer.TypeFixationTypeOrders.SoldGlobalPrice = true;
            }

            validityDurationService.GetAll(function (data, status) {
                if (status === null)
                    $scope.lstValidityDuration = data;
                else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            typeFixationBasesService.GetTypeFixationBases(function (data, status) {
                if (status === null) {
                    lstTypeFixationBasesOrigin = data;
                    $scope.lstTypeFixationBases = angular.copy(lstTypeFixationBasesOrigin);
                }
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            typeOfferCommitmentsService.GetTypeOfferCommitments(function (data, status) {
                if (status === null) {
                    $scope.lstTypeOfferCommitments = data;
                }
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            typeCommitmentsService.GetTypeCommitments(function (data, status) {
                if (status === null) {
                    $scope.lstTypeCommitments = data;
                }
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            currencyService.GetAllCurrencies(function (data, status) {
                if (status === null) {
                    $scope.lstCurrencies = data;

                    if (!$scope.currentOffer.OfferCurrencyUnits.IdOfferCurrencyUnit && $scope.lstCurrencies.length == 1) // si une seule devise
                    {
                        $scope.currentOffer.OfferCurrencyUnits.IdCurrencyForComponents = data[0].IdCurrency;
                        $scope.currentOffer.OfferCurrencyUnits.IdCurrencyFinal = data[0].IdCurrency;
                    }
                    // si les devises par défaut n'est pas dans la table de la coop
                    if ($filter('filter')(data, { IdCurrency: $scope.currentOffer.OfferCurrencyUnits.IdCurrencyForComponents }).length === 0 && $filter('filter')(data, { IdCurrency: $scope.currentOffer.OfferCurrencyUnits.IdCurrencyFinal }).length === 0 && !($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme && $scope.lstCurrencies.length > 1)) {
                        $scope.currentOffer.OfferCurrencyUnits.IdCurrencyForComponents = data[0].IdCurrency;
                        $scope.currentOffer.OfferCurrencyUnits.IdCurrencyFinal = data[0].IdCurrency;
                    }

                    if (($scope.currentOffer.IdTypeOffer == 2 || $scope.currentOffer.IdTypeOffer == 3 || $scope.currentOffer.IdTypeOffer == 6) && $scope.lstCurrencies.length >= 1) // si offre d'engagement, engagement de surface ou option, on met les devises par défaut
                    {
                        $scope.currentOffer.OfferCurrencyUnits.IdCurrencyForComponents = data[0].IdCurrency;
                        $scope.currentOffer.OfferCurrencyUnits.IdCurrencyFinal = data[0].IdCurrency;
                    }
                }
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            unitService.GetAllUnits(function (data, status) {
                if (status === null) {
                    $scope.lstUnits = data;

                    // Initialisation Devises, unités et décimales
                    if(!($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme && $scope.lstUnits.length > 1))
                    {
                        $scope.currentOffer.OfferCurrencyUnits = {
                            IdCurrencyFinal: $scope.currentOffer.OfferCurrencyUnits.IdCurrencyFinal != null ? $scope.currentOffer.OfferCurrencyUnits.IdCurrencyFinal : 1,
                            IdUnitFinal: $scope.currentOffer.OfferCurrencyUnits.IdUnitFinal != null ? $scope.currentOffer.OfferCurrencyUnits.IdUnitFinal : 1,
                            IdCurrencyForComponents: $scope.currentOffer.OfferCurrencyUnits.IdCurrencyForComponents != null ? $scope.currentOffer.OfferCurrencyUnits.IdCurrencyForComponents : 1,
                            IdUnitForComponents: $scope.currentOffer.OfferCurrencyUnits.IdUnitForComponents != null ? $scope.currentOffer.OfferCurrencyUnits.IdUnitForComponents : 1,
                            DecimalFinal: $scope.currentOffer.OfferCurrencyUnits.DecimalFinal != null ? $scope.currentOffer.OfferCurrencyUnits.DecimalFinal : 2,
                            DecimalForComponents: $scope.currentOffer.OfferCurrencyUnits.DecimalForComponents != null ? $scope.currentOffer.OfferCurrencyUnits.DecimalForComponents : 2
                        };
                    }

                    if (!$scope.currentOffer.OfferCurrencyUnits.IdOfferCurrencyUnit && $scope.lstUnits.length == 1) // si une seule unité
                    {
                        $scope.currentOffer.OfferCurrencyUnits.IdUnitForComponents = data[0].IdUnit;
                        $scope.currentOffer.OfferCurrencyUnits.IdUnitFinal = data[0].IdUnit;
                    }

                    // si les unités par défaut n'est pas dans la table de la coop
                    if ($filter('filter')(data, { IdUnit: $scope.currentOffer.OfferCurrencyUnits.IdUnitForComponents }).length === 0 && $filter('filter')(data, { IdUnit: $scope.currentOffer.OfferCurrencyUnits.IdUnitFinal }).length === 0 && !($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme && $scope.lstUnits.length > 1))
                    {
                        $scope.currentOffer.OfferCurrencyUnits.IdUnitForComponents = data[0].IdUnit;
                        $scope.currentOffer.OfferCurrencyUnits.IdUnitFinal = data[0].IdUnit;
                    }

                    if (($scope.currentOffer.IdTypeOffer == 2 || $scope.currentOffer.IdTypeOffer == 3 || $scope.currentOffer.IdTypeOffer == 6) && $scope.lstUnits.length >= 1) // si offre d'engagement, engagement de surface ou option, on met les unités par défaut
                    {
                        $scope.currentOffer.OfferCurrencyUnits.IdUnitForComponents = data[0].IdUnit;
                        $scope.currentOffer.OfferCurrencyUnits.IdUnitFinal = data[0].IdUnit;
                    }
                }
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            if (($scope.watchTypeFixation === undefined || $scope.watchTypeFixation === null) && $scope.step === 3){
                $scope.watchTypeFixation = $scope.$watchCollection("currentOffer.TypeFixationTypeOrders", function (value) {
                    var indexTypeFixationBaseAuto = $scope.lstTypeFixationBases.indexOf($filter('filter')($scope.lstTypeFixationBases, { "IdTypeFixationBase": enumFactory.TypeFixationBases.Automatic }, true)[0]);
                    
                    if (value.TargetPriceFuture){
                        $scope.lstTypeFixationBases = angular.copy(lstTypeFixationBasesOrigin);

                    } else if (indexTypeFixationBaseAuto !== -1){
                        $scope.lstTypeFixationBases.splice(indexTypeFixationBaseAuto, 1);
                        if($scope.currentOffer.IdTypeFixationBase == enumFactory.TypeFixationBases.Automatic)
                            $scope.currentOffer.IdTypeFixationBase = null;
                    }
                });
            }

            if (($scope.watchFixationBaseConstraint === undefined || $scope.watchFixationBaseConstraint === null) && $scope.step === 3){
                $scope.watchFixationBaseConstraint = $scope.$watchCollection("currentOffer.IdTypeFixationBase", function (value) {
                    if(value === enumFactory.TypeFixationBases.Automatic){
                        $scope.currentOffer.TypeFixationTypeOrders = {
                            FixationBase:true,
                            SoldBase: true,
                            TargetPriceBase: false,
                            FixationFuture:true,
                            SoldFuture: false,
                            TargetPriceFuture: true,
                            SoldGlobalPrice: false,
                            TargetPriceGlobalPrice: false
                        };
                    }
                });
            }
        };

        $scope.GetDataStep5 = function () {
            groupContactService.GetAll(function (data, status) {
                if (status === null) {
                    $scope.lstGroupContacts = {};
                    $scope.lstGroupContacts = data;

                    $scope.lstGroupContacts = $scope.lstGroupContacts.filter(function (element) { return $scope.currentOffer.GroupContacts.filter(function (e) { return e.IdGroupContact === element.IdGroupContact; }); });
                    $scope.UpdateOfferGroupContact();
                } else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
                $scope.setSelectedList();
            });

            $scope.UpdateOfferGroupContact = function () {
                if ($scope.HasQuantityLimit && $scope.currentOffer.IsTonnageLimit) {
                    $scope.currentOffer.OfferGroupContactTonnageVm = [];

                    $scope.currentOffer.GroupContacts.forEach(function (currentGroupContact) {
                        if (currentGroupContact.OfferGroupContactTonnages != null)
                            currentGroupContact.OfferGroupContactTonnages = currentGroupContact.OfferGroupContactTonnages.filter(function (element) { return element.IdOffer === $scope.currentOffer.IdOffer; });
                        else currentGroupContact.OfferGroupContactTonnages = [];
                    });

                    $scope.currentOffer.GroupContacts.forEach(function (currentGroupContact) {
                        if (currentGroupContact.OfferGroupContactTonnages.length == 0) {
                            var offerGroup = {
                                Label: currentGroupContact.Label,
                                IdGroupContact: currentGroupContact.IdGroupContact,
                                IdOffer: $scope.currentOffer.IdOffer
                            };
                            $scope.currentOffer.OfferGroupContactTonnageVm.push(offerGroup);
                        }
                        else {
                            currentGroupContact.OfferGroupContactTonnages.forEach(function (groupContact) {
                                if (groupContact.IdGroupContact == currentGroupContact.IdGroupContact && groupContact.IdOffer == $scope.currentOffer.IdOffer) {
                                    var offerGroup = {
                                        IdOfferGroupContactTonnage: groupContact.IdOfferGroupContactTonnage,
                                        Label: currentGroupContact.Label,
                                        IdGroupContact: groupContact.IdGroupContact,
                                        IdOffer: $scope.currentOffer.IdOffer,
                                        QuantityLimit: groupContact.QuantityLimit
                                    };
                                    $scope.currentOffer.OfferGroupContactTonnageVm.push(offerGroup);
                                }
                            });
                        }
                    });
                    $scope.SumTonnageLimit($scope.currentOffer.OfferGroupContactTonnageVm);
                }
            };

            offerVisibilityService.GetOfferVisibilities(function (data, status) {
                if (status === null) {
                    if ($scope.blankContract) {
                        $scope.lstVisibilityOffer = data.filter(function (element) { return (element.IdVisibilityOffer === $scope.enumVisibilityOffer.Tc_Only || element.IdVisibilityOffer === $scope.enumVisibilityOffer.Dr_Ra || element.IdVisibilityOffer === $scope.enumVisibilityOffer.Siege); });
                        if (idOffer === undefined || idOffer === null || idOffer == -1) { //Si c'est une nouvelle offre
                            $scope.currentOffer.IdVisibilityOffer = $scope.enumVisibilityOffer.Tc_Only; //On préselectionne Tc uniquement si on a un type d'offre: contrat vierge
                        }
                    } else {
                        $scope.lstVisibilityOffer = data;
                    }
                }
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            templatesService.GetTemplates(function (data, status) {
                if (status === null) {

                    $scope.lstTemplates = data;
                    var typeTemplatesNecessary = [];

                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Engagement) {
                        typeTemplatesNecessary.push(enumFactory.TypeTemplates.Engagement);
                    }

                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.EngagementSurface) {
                        typeTemplatesNecessary.push(enumFactory.TypeTemplates.EngagementSurface);
                    }

                    if ($scope.blankContract) {
                        typeTemplatesNecessary.push(enumFactory.TypeTemplates.BlankContract);
                    }

                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.PrixConstruit) {
                        typeTemplatesNecessary.push(enumFactory.TypeTemplates.ContratBuiltPrice);
                        typeTemplatesNecessary.push(enumFactory.TypeTemplates.ExitAnticipatedBuiltPrice);
                    }
                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Option) {
                        typeTemplatesNecessary.push(enumFactory.TypeTemplates.Option);

                        if ($scope.currentOffer.TypeFixationTypeOrders.SoldExerciceOption) {
                            typeTemplatesNecessary.push(enumFactory.TypeTemplates.ExerciseOption);
                        }

                        if ($scope.currentOffer.TypeFixationTypeOrders.TargetExerciceOption) {
                            typeTemplatesNecessary.push(enumFactory.TypeTemplates.TargetExerciseOption);
                        }
                    }

                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme) {

                        if ($scope.currentOffer.TypeFixationTypeOrders.SoldFuture || $scope.currentOffer.TypeFixationTypeOrders.SoldGlobalPrice) {
                            typeTemplatesNecessary.push(enumFactory.TypeTemplates.Vente);
                        }

                        if ($scope.currentOffer.TypeFixationTypeOrders.TargetPriceFuture || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceGlobalPrice) {
                            typeTemplatesNecessary.push(enumFactory.TypeTemplates.Objectif);
                        }

                        if ($scope.currentOffer.TypeFixationTypeOrders.SoldBase) {
                            typeTemplatesNecessary.push(enumFactory.TypeTemplates.VenteBase);
                        }

                        if ($scope.currentOffer.TypeFixationTypeOrders.TargetPriceBase) {
                            typeTemplatesNecessary.push(enumFactory.TypeTemplates.ObjectifBase);
                        }
                    }

                    if ($scope.multiCampagne) {
                        typeTemplatesNecessary.push(enumFactory.TypeTemplates.MultiCrop);
                    }

                    $scope.lstTemplates = $scope.lstTemplates.filter(function (element) {
                        return element.IsEnable === true &&
                            typeTemplatesNecessary.includes(element.IdTypeTemplate) &&
                            $scope.currentOffer.Templates.filter(function (e) { return e.IdTemplate === element.IdTemplate; });
                    });

                    $scope.lstTemplates = $scope.lstTemplates.sort(function (a, b) {
                        if (a.Label.toLowerCase() < b.Label.toLowerCase()) {
                          return -1;
                        }
                        if (a.Label.toLowerCase() > b.Label.toLowerCase()) {
                          return 1;
                        }
                        return 0;
                      });
                }
                else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
                $scope.setSelectedList();
            });

            if ($scope.currentOffer.IdAttachment !== undefined && $scope.currentOffer.IdAttachment !== null && $scope.currentOffer.IdAttachment !== "")
                $scope.fileName = $scope.currentOffer.Attachment.PublicLabel;
        };

        $scope.Cancel = function () {
            if ($scope.watchEditBuiltPrice !== null && $scope.watchEditBuiltPrice !== undefined) {
                $scope.noWatchBuiltPriceData();
            }
            if ($scope.watchedit !== null && $scope.watchedit !== undefined) {
                $scope.noWatch();
            }
            if ($scope.watchTypeFixation !== null && $scope.watchTypeFixation !== undefined) {
                $scope.noWatchTypeFixation();
            }
            if ($scope.watchFixationBaseConstraint !== null && $scope.watchFixationBaseConstraint !== undefined) {
                $scope.nowatchFixationBaseConstraint();
            }
            $location.path('/admin/offers/' + ($rootScope.filtersListingOffers ? $rootScope.filtersListingOffers.idStatus : '' ));
        };

        $scope.NextStep = function (goToStep) { //suivant
            if (goToStep)
                $scope.step = goToStep;
            else
                $scope.step += 1;
            if ($scope.watchedit !== undefined && $scope.watchedit !== null) {
                $scope.noWatch();
            }
            if (!$scope.ModeDupliquer && $scope.watchEditBuiltPrice == undefined && $scope.watchEditBuiltPrice == null && idOffer > 0) {
                $scope.VerifChangementBuiltPriceData();
            }
        };

        $scope.PrevStep = function () { //precedent
            if ($scope.step === 3 && ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.EngagementSurface))
                $scope.step = 1;
            else if ($scope.step === 4 && $scope.blankContract) {
                $scope.step = 2;
            }
            else if ($scope.step === 4 && $scope.contractBuiltPrice) {
                $scope.step = 2;
            }
            else
                $scope.step -= 1;
            $scope.errorMessage = "";
            if (($scope.watchedit === undefined || $scope.watchedit === null) && !$scope.HasContract && $scope.step === 1) {
                $scope.VerifChangementData();
            }
            if (!$scope.ModeDupliquer && ($scope.watchEditBuiltPrice === undefined || $scope.watchEditBuiltPrice === null) && ($scope.step === 2 || $scope.step === 4) && idOffer > 0) {
                $scope.VerifChangementBuiltPriceData();
            }
        };

        function SaveOffer(callBack) {
            $scope.disableSubmitedButton = true;
            offerService.SaveOffer($scope.currentOffer, function (data, status) {
                if (status === null) {
                    if (callBack)
                        callBack();
                    $location.path('/admin/offers/' + data);
                }
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else
                    $scope.errorMessage = status;
                $scope.disableSubmitedButton = false;
            });
        }

        // Activé watcher sur champs de l'offre
        $scope.VerifChangementData = function () {
            $scope.watchedit = $scope.$watchCollection("currentOffer", function (value, old) {
                var param = {};
                param.oldValue = old;
                param.newValue = value;
                $scope.paramWatcher = angular.copy(param);
                if (value === undefined || value === null) return;

                if (value.IdCrop != old.IdCrop ||
                    value.IdProduct != old.IdProduct ||
                    value.IdTypeOffer != old.IdTypeOffer ||
                    value.IdTypeQuote != old.IdTypeQuote) {
                    if (!$scope.ModeDupliquer) {
                        $scope.showPopUpEditOffer(param); //directive
                    } else if ($scope.ModeDupliquer) {
                        $scope.showPopUpEditOffer(param);
                    }
                }
            });
        };

        // désactivé watcher type Fixation
        $scope.noWatchTypeFixation = function () {
            $scope.watchTypeFixation = null;
        };
        $scope.nowatchFixationBaseConstraint = function () {
            $scope.watchFixationBaseConstraint = null;
        };
        
        // désactivé watcher sur champs de l'offre
        $scope.noWatch = function () {
            $scope.watchedit();
            $scope.watchedit = null;
        };

        //appelée par directive popup dans la vue
        $scope.disableWatcher = function () {
            $scope.noWatch();
            $scope.ClearStep($scope.step);
        };

        //appelée par directive popup dans la vue
        $scope.cancelModif = function (param) {
            if ($scope.watchedit !== undefined && $scope.watchedit !== null) {
                $scope.noWatch();
            }
            $scope.currentOffer = param.oldValue;
            $scope.VerifChangementData();
            $scope.setSelectedList();
        };

        //*************************** POPUP Modification offre BUILT PRICE *******************************************/
        $scope.VerifChangementBuiltPriceData = function () {
            $scope.watchEditBuiltPrice = $scope.$watchCollection("currentOffer", function (value, old) {
                var param = {};
                param.oldValue = old;
                param.newValue = value;
                $scope.paramWatcher = angular.copy(param);
                if (value === undefined || value === null) return;
                if ($scope.currentOffer.IdTypeOffer == $scope.enumTypeOffers.PrixConstruit && (
                    value.IdMarket != old.IdMarket ||
                    value.ObservationPeriodBuiltPriceStartDate != old.ObservationPeriodBuiltPriceStartDate ||
                    value.ObservationPeriodBuiltPriceEndDate != old.ObservationPeriodBuiltPriceEndDate)) {
                    $scope.showPopUpEditBuiltPriceOffer(param);
                }
            });
        };

        $scope.noWatchBuiltPriceData = function () {
            if ($scope.watchEditBuiltPrice !== undefined && $scope.watchEditBuiltPrice !== null)
            $scope.watchEditBuiltPrice();
            $scope.watchEditBuiltPrice = null;
        };

        $scope.disableWatcherBuiltPriceData = function () {
            $scope.noWatchBuiltPriceData();
        };

        $scope.cancelModifBuiltPriceData = function (param) {
            if ($scope.watchEditBuiltPrice !== undefined && $scope.watchEditBuiltPrice !== null){
                $scope.noWatchBuiltPriceData();
            }
            $scope.currentOffer = param.oldValue;
            $scope.VerifChangementBuiltPriceData();
            $scope.setSelectedList();
        };

        //************************************************************************************************* */

        $scope.ValidTargetContractBasesFix = function () {

            SaveOffer(function () {
                contractBaseService.ValidTargetContractBasesFix($scope.currentOffer.OfferExpiriesVm, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                        else
                            $scope.errorMessage = status;
                    }
                    else {
                        contractsService.ValidTargetContracts($scope.currentOffer.IdOffer, function (data, status) {
                            if (status !== null) {
                                if (status === 400)
                                    $scope.errorMessage = data[0].errors[0].Message;
                                else
                                    $scope.errorMessage = status;
                            }
                        });
                    }
                });
            });
        };

        $scope.setSelectedList = function () {
            if ($scope.currentOffer.VarietiesVm === undefined || $scope.currentOffer.VarietiesVm === null || !$scope.currentOffer.IdProduct)
                $scope.currentOffer.VarietiesVm = [];
            else {
                $scope.lstvarieteTmp = angular.copy($filter('filter')($scope.lstProducts, { IdProduct: $scope.currentOffer.IdProduct }, true)[0].Varieties);
                $.each($scope.currentOffer.VarietiesVm, function (index, item) {
                    if ($scope.lstvarieteTmp.indexOf($filter('filter')($scope.lstvarieteTmp, { "IdVariety": Number(item.IdVariety) }, true)[0]) !== -1) {
                        $scope.lstvarieteTmp.splice($scope.lstvarieteTmp.indexOf($filter('filter')($scope.lstvarieteTmp, { "IdVariety": Number(item.IdVariety) }, true)[0]), 1);
                    }
                });
            }
            if ($scope.currentOffer.GroupContacts === undefined || $scope.currentOffer.GroupContacts === null) {
                $scope.currentOffer.GroupContacts = [];
            } else {
                $scope.$watch("lstGroupContacts", function (value) {
                    if (value === undefined) return;
                    $.each($scope.currentOffer.GroupContacts, function (index, item) {
                        if ($scope.lstGroupContacts.indexOf($filter('filter')($scope.lstGroupContacts, { "IdGroupContact": Number(item.IdGroupContact) }, true)[0]) !== -1) {
                            $scope.lstGroupContacts.splice($scope.lstGroupContacts.indexOf($filter('filter')($scope.lstGroupContacts, { "IdGroupContact": Number(item.IdGroupContact) }, true)[0]), 1);
                        }
                    });
                });
            }
            if ($scope.currentOffer.Templates === undefined || $scope.currentOffer.Templates === null) {
                $scope.currentOffer.Templates = [];
            } else {
                $scope.$watch("lstTemplates", function (value) {
                    if (value === undefined) return;
                    $.each($scope.currentOffer.Templates, function (index, item) {
                        if ($scope.lstTemplates.indexOf($filter('filter')($scope.lstTemplates, { "IdTemplate": Number(item.IdTemplate) }, true)[0]) !== -1) {
                            $scope.lstTemplates.splice($scope.lstTemplates.indexOf($filter('filter')($scope.lstTemplates, { "IdTemplate": Number(item.IdTemplate) }, true)[0]), 1);
                        }
                    });
                });
            }

            if ($scope.currentOffer.OfferExpiriesVm === undefined || $scope.currentOffer.OfferExpiriesVm === null)
                $scope.currentOffer.OfferExpiriesVm = [];
        };

        $scope.$watch("lstTypeOffer", function (value) {
            if (value === undefined) return;
            $scope.currentOffer.IdTypeOffer = value[0].IdTypeOffer;
        });

        $scope.FilterProducts = function (idCompagny, resetIdProduct) {
            if(resetIdProduct) {
                $scope.currentOffer.IdProduct = null;
                $scope.currentOffer.VarietiesVm = [];
            }
            $scope.lstvarieteTmp = [];
            $scope.lstProducts = [];
            $scope.lstProductsFiltre = [];
            if (idCompagny != null) {
                $scope.lstAllProducts.forEach(function (product) {
                    if (product.Compagnies.length > 0) {
                        if ($filter('filter')(product.Compagnies, { IdCompagny: idCompagny })[0] !== undefined)
                            $scope.lstProducts.push(product);
                    }
                });
            }
            else {
                $scope.lstAllProducts.forEach(function (product) {
                    if (product.Compagnies.length > 0)
                        $scope.lstProducts.push(product);
                });
            }
        };
          
        $scope.greaterThan = function(index){
            var idCropRef = $scope.currentOffer.IdCrop ;
            if (index >  0) {
                idCropRef = $scope.currentOffer.IdsSubCrops[index-1] ;
            }
            
            var minLabel = idCropRef ? $scope.lstCrops.filter(function (crop) {
                return crop.IdCrop == idCropRef;
            })[0].Label : 0;
            
            return function(item){
              return item.Label > minLabel;
            };
        };

        $scope.LstMultiCrop = function(index, idCropSelected) {
            $scope.errorMessage = "";
            if($scope.currentOffer.IdCrop === undefined || $scope.currentOffer.IdCrop === null || $scope.currentOffer.IdCrop === 0 || (idCropSelected && idCropSelected !== $scope.currentOffer.IdCrop ) ){
                $scope.currentOffer.IdsSubCrops.splice(0, $scope.currentOffer.IdsSubCrops.length);
            }
            if($scope.currentOffer.IdsSubCrops[index] === null)
                $scope.currentOffer.IdsSubCrops.splice(index, ($scope.currentOffer.IdsSubCrops.length - index));

            if ($scope.refIdsSubCrops.length > 0 && ($scope.refIdsSubCrops[index] != $scope.currentOffer.IdsSubCrops[index])) {
                for(var i=index+1;i<$scope.currentOffer.IdsSubCrops.length;i++) {
                    $scope.currentOffer.IdsSubCrops.splice(i, ($scope.currentOffer.IdsSubCrops.length - i));
                }
            }
            $scope.refIdsSubCrops = angular.copy($scope.currentOffer.IdsSubCrops) ;
        };

        $scope.GetVarieties = function () {
            if ($scope.currentOffer.IdProduct == null) {
                $scope.lstvarieteTmp = [];
                return;
            }
            $scope.lstVarieties = [];
            $scope.lstVarietiesByProduct = $filter('filter')($scope.lstProducts, { IdProduct: $scope.currentOffer.IdProduct }, true)[0].Varieties;
            if ($scope.currentOffer.IdCompagny != null) {
                $scope.lstVarietiesByProduct.forEach(function (variety) {
                    if (variety.Compagnies.length > 0) {
                        if ($filter('filter')(variety.Compagnies, { IdCompagny: $scope.currentOffer.IdCompagny })[0] !== undefined)
                            $scope.lstVarieties.push(variety);
                    }
                });
            }
            else
                $scope.lstVarietiesByProduct.forEach(function (variety) {
                    if (variety.Compagnies.length > 0)
                        $scope.lstVarieties.push(variety);
                });

            $scope.lstvarieteTmp = angular.copy($scope.lstVarieties);
            $scope.currentOffer.VarietiesVm = [];
        };

        $scope.GetExpiries = function (isInit) {
            if (!isInit)
                $scope.lstOfferExpiry = [];

            if (!$scope.lstMarkets) {
                loadMarkets(loadExpiries);
            } else {
                loadExpiries();
            }
        };

        $scope.ChangeTypeCalculOption = function () {
            $scope.lstOfferExpiry.forEach(function (expiry) {
                expiry.Strikes = [];
                $scope.strikeAutoVm = [];
            });
        };

        $scope.ChangeQuantityLimit = function () {
            $scope.HasQuantityLimit = $scope.currentOffer.QuantityLimit > 0 ? true : false;
        };

        $scope.SumTonnageLimit = function (lstGroup) {
            $scope.TotalQuantityLimit = 0;
            lstGroup.forEach(function (group) {
                if (group.QuantityLimit !== undefined && group.QuantityLimit !== "" && angular.isNumber(parseFloat(group.QuantityLimit)) && group.QuantityLimit !== null)
                    $scope.TotalQuantityLimit = parseFloat($scope.TotalQuantityLimit + parseFloat(group.QuantityLimit));
            });
        };

        $scope.Check = function (step) {

            if (step)
                $scope.step = step;

            switch ($scope.step) {
                case 1:
                    if (CheckStep($scope.step)) {
                        if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.EngagementSurface) {
                            $scope.NextStep(3);
                            $scope.GetDataStep3();
                        }
                        else {
                            $scope.NextStep();
                            $scope.GetDataStep2();
                        }
                    }
                    return;
                case 2:
                    if (CheckStep($scope.step) && $scope.contractBuiltPrice){
                        $scope.NextStep(4);
                    } else if ($scope.lstOfferExpiry && $scope.lstOfferExpiry.length > 0) {
                        $scope.CheckExpiries().then(function (results) {
                            if (CheckStep($scope.step)) {
                                if ($scope.blankContract) {
                                    $scope.NextStep(4);
                                }
                                else {
                                    $scope.NextStep();
                                    $scope.GetDataStep3();
                                }
                            }
                        });
                    } else {
                        if (CheckStep($scope.step)) {
                            $scope.NextStep();
                            $scope.GetDataStep3();
                        }
                    }
                    return;
                case 3:
                    if (CheckStep($scope.step)) {
                        $scope.NextStep();
                    }
                    return;
                case 4:
                    if (CheckStep($scope.step)) {
                        $scope.NextStep();
                        $scope.GetDataStep5();
                    }
                    return;
                case 5:
                    $scope.CheckSameTypeTemplate().then(function (results) {
                        if (CheckStep($scope.step)) {
                            if (!$scope.ModeDupliquer) {
                                $scope.currentOffer.Validate = true;
                            }

                            if ($rootScope.paramNameCoop == enumFactory.Coops.Ducroquet && !$scope.ModeEdition && !ducroquetValidTypeCoverPopUp)
                                $scope.showPopUpTypeCover($scope.Check);
                            else
                                $scope.CheckAndSaveOffer();
                        }
                    });
                    return;
                default:
            }
        };

        //enregistré et quitter
        $scope.TmpSave = function (step) {

            if (step)
                $scope.step = step;

            if ($rootScope.paramNameCoop == enumFactory.Coops.Ducroquet && !$scope.ModeEdition && !ducroquetValidTypeCoverPopUp)
                $scope.showPopUpTypeCover($scope.TmpSave);
            else {
                $scope.errorMessage = "";
                if ($scope.ModeEdition || $scope.ModeDupliquer) {
                    $scope.CheckTemplate();

                    if ($scope.errorMessage !== "")
                        return false;
                }
                if ($scope.step === 2 && $scope.lstOfferExpiry && $scope.lstOfferExpiry.length > 0) {
                    $scope.CheckExpiries().then(function (results) {
                        if (CheckStep($scope.step))
                            $scope.CheckAndSaveOffer();
                    });
                }
                else {
                    $scope.CheckSameTypeTemplate().then(function () {
                        if (CheckStep($scope.step))
                            $scope.CheckAndSaveOffer();
                    });
                }
            }
        };

        $scope.confirmPopUpTypeCover = function (callback) {
            ducroquetValidTypeCoverPopUp = true;
            callback();
        };

        function checkDistinct(array) {
            for (var i = 0; i < array.length; i++) { 
                if (array.indexOf(array[i]) !== i) { 
                    return false; 
                } 
            }

            if(array.includes($scope.currentOffer.IdCrop))
                return false;

            return true;
        }

        function checkIfOfferSubCropsExist(array) {
            var cmpt = 0;
            for (var i = 0; i < array.length; i++) {
                if(array[i] === 0 || array[i] === undefined || array[i] === null) {
                    cmpt++;
                }
            }
            if(cmpt === array.length)
                return false;

            return true;
        }

        function CheckStep(step) {
            switch (step) {
                case 1:
                    if ($scope.currentOffer.IdTypeOffer === undefined || $scope.currentOffer.IdTypeOffer === null || $scope.currentOffer.IdTypeOffer === 0) {
                        $scope.errorMessage = "Veuillez sélectionner un type d'offre";
                        return false;
                    }
                    if ($rootScope.paramBUseOperation && ($scope.currentOffer.IdOperation === undefined || $scope.currentOffer.IdOperation === null || $scope.currentOffer.IdOperation === 0)) {
                        $scope.errorMessage = "Veuillez sélectionner un type d'opération";
                        return false;
                    }
                    if ($rootScope.paramBUseOperation && $scope.lstTypePrices && $scope.lstTypePrices.length > 0 && !$scope.currentOffer.IdTypePrice) {
                        $scope.errorMessage = "Veuillez sélectionner un type de prix";
                        return false;
                    }
                    if ($scope.currentOffer.IdCrop === undefined || $scope.currentOffer.IdCrop === null || $scope.currentOffer.IdCrop === 0) {
                        $scope.errorMessage = "Veuillez sélectionner une campagne";
                        return false;
                    }
                    if ($scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.ContratVierge && ($scope.currentOffer.IdProduct === undefined || $scope.currentOffer.IdProduct === null || $scope.currentOffer.IdProduct === 0)) {
                        $scope.errorMessage = "Veuillez sélectionner un produit";
                        return false;
                    }
                    if ($scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.Engagement && $scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.EngagementSurface && $scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.ContratVierge && $scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.PrixConstruit && ($scope.currentOffer.IdTypeQuote === undefined || $scope.currentOffer.IdTypeQuote === null || $scope.currentOffer.IdTypeQuote === 0)) {
                        $scope.errorMessage = "Veuillez sélectionner un type de cotation";
                        return false;
                    }
                    if ($scope.IsUneal && $scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.ContratVierge && $scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.PrixConstruit && ($scope.currentOffer.IdCriteriaOffer === undefined || $scope.currentOffer.IdCriteriaOffer === null || $scope.currentOffer.IdCriteriaOffer === 0)) {
                        $scope.errorMessage = "Veuillez sélectionner un critère de qualité";
                        return false;
                    }
                    if ($scope.currentOffer.Label === undefined || $scope.currentOffer.Label === null || $scope.currentOffer.Label === '') {
                        $scope.errorMessage = "Veuillez renseigner un nom d'offre";
                        return false;
                    }
                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Engagement || $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.EngagementSurface || $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.ContratVierge || $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.PrixConstruit)
                        $scope.currentOffer.IdTypeQuote = null;

                    if (!$rootScope.paramBUseOperation)
                        $scope.currentOffer.IdOperation = null;

                    if($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.MultiCampagne) {
                        if(!checkDistinct($scope.currentOffer.IdsSubCrops)) {
                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_MultiCrop_ErrorMessage_step1.Content;
                            return false;
                        }
    
                        if(!checkIfOfferSubCropsExist($scope.currentOffer.IdsSubCrops)) {
                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_MultiCrop_ErrorMessage2_step1.Content;
                            return false;
                        }
                    }

                    $scope.errorMessage = "";
                    return true;

                case 2:

                    if ($scope.currentOffer.IdTypeOffer == $scope.enumTypeOffers.Engagement) {
                        $scope.errorMessage = "";
                        return true;
                    }

                    if (!$scope.blankContract) {
                        if ($scope.fixe && !$scope.contractBuiltPrice) {
                            if ($scope.currentOffer.Price === undefined || $scope.currentOffer.Price === null || $scope.currentOffer.Price === 0.0 || $scope.currentOffer.Price === "" || !angular.isNumber(parseFloat($scope.currentOffer.Price))) {
                                $scope.errorMessage = "Veuillez renseigner un prix valide";
                                return false;
                            }
                        } else {
                            if ($scope.currentOffer.IdMarket === "" || $scope.currentOffer.IdMarket === 0 || $scope.currentOffer.IdMarket === undefined || $scope.currentOffer.IdMarket === null) {
                                $scope.errorMessage = "Veuillez sélectionner un marché";
                                return false;
                            }
                            if (($scope.currentOffer.IdExpiry === "" || $scope.currentOffer.IdExpiry === 0 || $scope.currentOffer.IdExpiry === undefined || $scope.currentOffer.IdExpiry === null) && !$scope.contractBuiltPrice && !$scope.multiCampagne) {
                                $scope.errorMessage = "Veuillez sélectionner une écheance";
                                return false;
                            }
                        }
                    }

                    if(!$scope.contractBuiltPrice && !$scope.multiCampagne){
                        if (!$scope.lstOfferExpiry || $scope.lstOfferExpiry.length === 0) {
                            $scope.errorMessage = "Veuillez renseigner vos échéances";
                            return false;
                        } else
                            if (!$scope.validExpiries)
                                return false;
                    }

                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Option) {
                        var checkStrike = true;
                        if ($scope.currentOffer.IdTypeCalculOption == enumFactory.TypeCalculOption.Manual) {
                            $scope.lstOfferExpiry.forEach(function (expiry) {
                                if (checkStrike) {
                                    if (expiry.Strikes == null || expiry.Strikes.length < 1) {
                                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Option_ErrorExpiryStrike.Content + expiry.Label;
                                        checkStrike = false;
                                    }

                                    expiry.Strikes.forEach(function (strike) {
                                        if (!strike.ExerciceDate ||
                                            strike.Prime === '' ||
                                            isNaN(strike.Prime) ||
                                            strike.Variation === '' ||
                                            isNaN(strike.Variation)
                                        ) {
                                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Option_ErrorStrike.Content + expiry.Label;
                                            checkStrike = false;
                                        }
                                    });

                                }
                            });
                            return checkStrike;
                        }
                        else if ($scope.currentOffer.IdTypeCalculOption == enumFactory.TypeCalculOption.Automatic && !$scope.paramBActiveLinkWithCeremis) {
                            checkStrike = true;
                            $scope.lstOfferExpiry.forEach(function (expiry) {
                                if (checkStrike && expiry.Strikes.length === 0) {
                                    $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Option_ErrorExpiryStrike.Content + expiry.Label;
                                    checkStrike = false;
                                }
                            });
                            return checkStrike;
                        }
                    }
                    if(!$scope.contractBuiltPrice && !$scope.multiCampagne){
                        if ($scope.currentOffer.BasisMajorationEnabled == true) {
                            if (!$scope.currentOffer.BasisMajorationMonth) {
                                $scope.errorMessage = "Veuillez saisir un mois de référence";
                                return false;
                            }
                            if (!$scope.currentOffer.BasisMajorationValue) {
                                $scope.errorMessage = "Veuillez saisir une valeur de majoration";
                                return false;
                            }
                        }
                    }

                    $scope.errorMessage = "";
                    return true;
                case 3:
                    if (!$scope.currentOffer.StartVisibility || !$scope.currentOffer.EndVisibility) {
                        $scope.errorMessage = "Veuillez renseigner des horaires de visibilité de l'offre";
                        return false;
                    } else {
                        try {
                            start = new Date("1975/01/01 " + $scope.currentOffer.StartVisibility).toISOString();
                            end = new Date("1975/01/01 " + $scope.currentOffer.EndVisibility).toISOString();
                        } catch (e) {

                            $scope.errorMessage = "Le format de date n'est pas valide";
                            return false;
                        }
                    }
                    if (($scope.currentOffer.TypeFixationTypeOrders.SoldFuture || $scope.currentOffer.TypeFixationTypeOrders.SoldGlobalPrice || $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Option) && (!$scope.currentOffer.StartPosition || !$scope.currentOffer.EndPosition)) {
                        $scope.errorMessage = "Veuillez renseigner des horaires pour la fixation du prix";
                        return false;
                    }
                    else if ($scope.currentOffer.TypeFixationTypeOrders.SoldFuture || $scope.currentOffer.TypeFixationTypeOrders.SoldGlobalPrice) {
                        try {
                            startpriseposition = new Date("1975/01/01 " + $scope.currentOffer.StartPosition).toISOString();
                            endpriseposition = new Date("1975/01/01 " + $scope.currentOffer.EndPosition).toISOString();
                        } catch (e) {
                            $scope.errorMessage = "Le format de date n'est pas valide";
                            return false;
                        }
                        if (start > startpriseposition || end < endpriseposition) {
                            $scope.errorMessage = "Le créneau horaire de la fixation du prix est en dehors du créneau horaire de la visibilité de l'offre";
                            return false;
                        }
                    }
                    if (($scope.currentOffer.TypeFixationTypeOrders.TargetPriceFuture || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceGlobalPrice) && (!$scope.currentOffer.StartTargetPrice || !$scope.currentOffer.EndTargetPrice)) {
                        $scope.errorMessage = $scope.Admin_CreateOffer_Error_Times_TargetPrice;
                        return false;
                    }
                    else if ($scope.currentOffer.TypeFixationTypeOrders.TargetPriceFuture || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceGlobalPrice) {
                        try {
                            startpriseobjectif = new Date("1975/01/01 " + $scope.currentOffer.StartTargetPrice).toISOString();
                            endpriseobjectif = new Date("1975/01/01 " + $scope.currentOffer.EndTargetPrice).toISOString();
                        } catch (e) {
                            $scope.errorMessage = "Le format de date n'est pas valide";
                            return false;
                        }
                        if (start > startpriseobjectif || end < endpriseobjectif) {
                            $scope.errorMessage = $scope.Admin_CreateOffer_Error_Times_TargetPrice2;
                            return false;
                        }
                    }

                    if ($scope.currentOffer.TypeFixationTypeOrders.SoldBase && (!$scope.currentOffer.StartPositionBase || !$scope.currentOffer.EndPositionBase)) {
                        $scope.errorMessage = "Veuillez renseigner des horaires pour la fixation de base";
                        return false;
                    }
                    else if ($scope.currentOffer.TypeFixationTypeOrders.SoldBase) {
                        try {
                            startprisepositionBase = new Date("1975/01/01 " + $scope.currentOffer.StartPositionBase).toISOString();
                            endprisepositionBase = new Date("1975/01/01 " + $scope.currentOffer.EndPositionBase).toISOString();
                        } catch (e) {
                            $scope.errorMessage = "Le format de date n'est pas valide";
                            return false;
                        }
                        if (start > startprisepositionBase || end < endprisepositionBase) {
                            $scope.errorMessage = "Le créneau horaire de la fixation de la base est en dehors du créneau horaire de la visibilité de l'offre";
                            return false;
                        }
                    }
                    if ($scope.currentOffer.TypeFixationTypeOrders.TargetPriceBase && (!$scope.currentOffer.StartTargetPriceBase || !$scope.currentOffer.EndTargetPriceBase)) {
                        $scope.errorMessage = "Veuillez renseigner des horaires pour la définition d'un " + enumFactory.TypeOrders.Prixobjectif.Label;
                        return false;
                    }
                    else if ($scope.currentOffer.TypeFixationTypeOrders.TargetPriceBase) {
                        try {
                            startpriseobjectifBase = new Date("1975/01/01 " + $scope.currentOffer.StartTargetPriceBase).toISOString();
                            endpriseobjectifBase = new Date("1975/01/01 " + $scope.currentOffer.EndTargetPriceBase).toISOString();
                        } catch (e) {
                            $scope.errorMessage = "Le format de date n'est pas valide";
                            return false;
                        }
                        if (start > startpriseobjectifBase || end < endpriseobjectifBase) {
                            $scope.errorMessage = "Le créneau horaire de la définition du " + enumFactory.TypeOrders.Prixobjectif.Label + " de base est en dehors du créneau horaire de la visibilité de l'offre";
                            return false;
                        }
                    }

                    if ($scope.currentOffer.TypeFixationTypeOrders.SoldExerciceOption && (!$scope.currentOffer.StartPositionExerciceOption || !$scope.currentOffer.EndPositionExerciceOption)) {
                        $scope.errorMessage = $scope.Admin_CreateOffer_Error_MissingFixingTime_ExerciceOption;
                        return false;
                    }
                    else if ($scope.currentOffer.TypeFixationTypeOrders.SoldExerciceOption) {
                        try {
                            startprisepositionExerciceOption = new Date("1975/01/01 " + $scope.currentOffer.StartPositionExerciceOption).toISOString();
                            endprisepositionExerciceOption = new Date("1975/01/01 " + $scope.currentOffer.EndPositionExerciceOption).toISOString();
                        } catch (e) {
                            $scope.errorMessage = $scope.Admin_CreateOffer_Error_InvalidDateFormat_ExerciceOption;
                            return false;
                        }
                        if (start > startprisepositionExerciceOption || end < endprisepositionExerciceOption) {
                            $scope.errorMessage = $scope.Admin_CreateOffer_Error_InvalidFixingTime_ExerciceOption;
                            return false;
                        }
                    }

                    if ($scope.currentOffer.TypeFixationTypeOrders.TargetExerciceOption && (!$scope.currentOffer.StartPositionTargetExerciceOption || !$scope.currentOffer.EndPositionTargetExerciceOption)) {
                        $scope.errorMessage = $scope.Admin_CreateOffer_Error_MissingFixingTime_TargetExerciceOption;
                        return false;
                    }
                    else if ($scope.currentOffer.TypeFixationTypeOrders.TargetExerciceOption) {
                        try {
                            startPositionTargetExerciceOption = new Date("1975/01/01 " + $scope.currentOffer.StartPositionTargetExerciceOption).toISOString();
                            endPositionTargetExerciceOption = new Date("1975/01/01 " + $scope.currentOffer.EndPositionTargetExerciceOption).toISOString();
                        } catch (e) {
                            $scope.errorMessage = $scope.Admin_CreateOffer_Error_InvalidDateFormat_TargetExerciceOption;
                            return false;
                        }
                        if (start > startPositionTargetExerciceOption || end < endPositionTargetExerciceOption) {
                            $scope.errorMessage = $scope.Admin_CreateOffer_Error_InvalidFixingTime_TargetExerciceOption;
                            return false;
                        }
                    }


                    if (($scope.currentOffer.TypeFixationTypeOrders.TargetPriceBase || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceFuture || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceGlobalPrice) && (!$scope.currentOffer.IdValidityDuration || $scope.currentOffer.IdValidityDuration === 0)) {
                        $scope.errorMessage = $scope.Admin_CreateOffer_Error_SelectDurationValidity_TargetPrice;
                        return false;
                    }

                    if (($scope.currentOffer.TypeFixationTypeOrders.TargetPriceBase || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceFuture || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceGlobalPrice) && ($scope.currentOffer.IdValidityDuration !== enumFactory.ValidityDurations.EndDateOffer)) {
                        $scope.currentOffer.AllowDateEndTargetPrice = null;
                    }

                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme && ($scope.currentOffer.TypeFixationTypeOrders.SoldBase || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceBase || $scope.currentOffer.TypeFixationTypeOrders.SoldFuture || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceFuture) && !$scope.currentOffer.IdTypeFixationBase) {
                        $scope.errorMessage = "Veuillez sélectionner une contrainte de fixation de base.";
                        return false;
                    }

                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme && !$scope.currentOffer.IdTypeOfferCommitment) {
                        $scope.errorMessage = "Veuillez sélectionner une contrainte d'engagement.";
                        return false;
                    }

                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme && ($scope.currentOffer.IdTypeOfferCommitment === $scope.enumTypeOfferCommitments.EngagementObligatoire || $scope.currentOffer.IdTypeOfferCommitment === $scope.enumTypeOfferCommitments.EngagementFacultatif) && !$scope.currentOffer.IdTypeCommitmentForSubscribe) {
                        $scope.errorMessage = "Veuillez sélectionner le type d'engagement obligatoire pour la prise de position.";
                        return false;
                    }

                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Engagement && !$scope.currentOffer.IdTypeCommitment) {
                        $scope.errorMessage = $scope.Admin_CreateOffer_Error_SelectTypeCommitment;
                        return false;
                    }

                    if ((!$scope.currentOffer.TypeFixationTypeOrders.TargetPriceGlobalPrice && !$scope.currentOffer.TypeFixationTypeOrders.TargetPriceFuture) || $scope.moduleAutomaticCover) {
                        $scope.currentOffer.AutomaticSwitchExpiry = false;
                    }

                    if ($scope.currentOffer.TypeFixationTypeOrders.FixationGlobalPrice) {
                        if (!$scope.currentOffer.OfferCurrencyUnits.IdCurrencyFinal || !$scope.currentOffer.OfferCurrencyUnits.IdCurrencyForComponents) {
                            $scope.errorMessage = "Veuillez sélectionner une devise pour le prix de l'offre.";
                            return false;
                        }
                        if (!$scope.currentOffer.OfferCurrencyUnits.IdUnitFinal || !$scope.currentOffer.OfferCurrencyUnits.IdUnitForComponents) {
                            $scope.errorMessage = "Veuillez sélectionner une unité pour le prix de l'offre.";
                            return false;
                        }
                        if (!$scope.currentOffer.OfferCurrencyUnits.DecimalFinal) {
                            $scope.errorMessage = "Veuillez sélectionner un nombre décimal valide pour le prix de l'offre.";
                            return false;
                        }
                    }

                    if ($scope.currentOffer.TypeFixationTypeOrders.FixationBase || $scope.currentOffer.TypeFixationTypeOrders.FixationFuture) {

                        if (!$scope.currentOffer.OfferCurrencyUnits.IdCurrencyForComponents || !$scope.currentOffer.OfferCurrencyUnits.IdCurrencyForComponents) {
                            $scope.errorMessage = "Veuillez sélectionner une devise pour les composantes.";
                            return false;
                        }
                        if (!$scope.currentOffer.OfferCurrencyUnits.IdUnitForComponents || !$scope.currentOffer.OfferCurrencyUnits.IdUnitForComponents) {
                            $scope.errorMessage = "Veuillez sélectionner une unité pour les composantes.";
                            return false;
                        }
                        if (!$scope.currentOffer.OfferCurrencyUnits.DecimalForComponents) {
                            $scope.errorMessage = "Veuillez sélectionner un nombre décimal valide pour les composantes.";
                            return false;
                        }
                    }

                    if ($scope.parambChooseEntryModeActive && ($scope.currentOffer.TypeFixationTypeOrders.FixationGlobalPrice || $scope.currentOffer.TypeFixationTypeOrders.FixationBase || $scope.currentOffer.TypeFixationTypeOrders.FixationFuture) && ($scope.currentOffer.LotEntryMode === undefined || $scope.currentOffer.LotEntryMode === null || $scope.currentOffer.LotEntryMode === "")) {
                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EntryMode_Error.Content;
                        return false;
                    }

                    if ($scope.IsUneal && $scope.currentOffer.TypeFixationTypeOrders.TargetPriceGlobalPrice && $scope.currentOffer.TargetPriceWithBaseAcquisition && !$scope.currentOffer.TypeFixationTypeOrders.SoldBase) {
                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_TargetPriceWithBaseAcquisition_ErrorSoldBaseNotSelected.Content;
                        return false;
                    }

                    $scope.errorMessage = "";
                    return true;
                case 4:
                    var tab1;
                    var tab2;
                    var date1;
                    var date2;
                    var date3;
                    var date4;

                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.PrixConstruit) {
                        if ($scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate === "" || $scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate === "" || $scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate === undefined || $scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate === undefined || $scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate === null || $scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate === null) {
                            $scope.errorMessage = "Veuillez renseigner la période de souscription de l'offre";
                            return false;
                        }
                        else {
                            try {
                                tab1 = $scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate.split('/');
                                tab2 = $scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate.split('/');
                                if (tab1.length > 1)
                                    date1 = new Date(tab1[2] + "-" + tab1[1] + "-" + tab1[0]).toISOString();
                                else {
                                    date1 = new Date($scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate).toISOString();
                                }
                                if (tab2.length > 1)
                                    date2 = new Date(tab2[2] + "-" + tab2[1] + "-" + tab2[0]).toISOString();
                                else {
                                    date1 = new Date($scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate).toISOString();
                                }
                            }
                            catch (e) {

                                $scope.errorMessage = "Le format de date pour la période de souscription n'est pas valide";
                                return false;
                            }
                        }

                        if ($scope.currentOffer.ObservationPeriodBuiltPriceStartDate === "" || $scope.currentOffer.ObservationPeriodBuiltPriceEndDate === "" || $scope.currentOffer.ObservationPeriodBuiltPriceStartDate === undefined || $scope.currentOffer.ObservationPeriodBuiltPriceEndDate === undefined || $scope.currentOffer.ObservationPeriodBuiltPriceStartDate === null || $scope.currentOffer.ObservationPeriodBuiltPriceEndDate === null) {
                            $scope.errorMessage = "Veuillez renseigner la période d'observation de l'offre";
                            return false;
                        }
                        else {
                            try {
                                tab1 = $scope.currentOffer.ObservationPeriodBuiltPriceStartDate.split('/');
                                tab2 = $scope.currentOffer.ObservationPeriodBuiltPriceEndDate.split('/');
                                if (tab1.length > 1)
                                    date3 = new Date(tab1[2] + "-" + tab1[1] + "-" + tab1[0]).toISOString();
                                else {
                                    date3 = new Date($scope.currentOffer.ObservationPeriodBuiltPriceStartDate).toISOString();
                                }
                                if (tab2.length > 1)
                                    date4 = new Date(tab2[2] + "-" + tab2[1] + "-" + tab2[0]).toISOString();
                                else {
                                    date3 = new Date($scope.currentOffer.ObservationPeriodBuiltPriceEndDate).toISOString();
                                }
                            }
                            catch (e) {

                                $scope.errorMessage = "Le format de date pour la période d'observation n'est pas valide";
                                return false;
                            }
                        }
                        if (date1 > date2) {

                            $scope.errorMessage = "La date de début de la période de souscription de l'offre est ultérieure à la date de fin de la période de souscription";
                            return false;
                        }
                        if (date3 > date4) {

                            $scope.errorMessage = "La date de début de la période d'observation de l'offre est ultérieure à la date de fin de la période d'observation";
                            return false;
                        }
                        if (date2 >= date3) {

                            $scope.errorMessage = "La date de fin de la période de souscription de l'offre est ultérieure ou égale à la date de début de la période d'observation";
                            return false;
                        }
                    }
                    else {
                        if ($scope.currentOffer.StartValidity === "" || $scope.currentOffer.EndValidity === "" || $scope.currentOffer.StartValidity === undefined || $scope.currentOffer.EndValidity === undefined || $scope.currentOffer.StartValidity === null || $scope.currentOffer.EndValidity === null) {
                            $scope.errorMessage = "Veuillez renseigner la date de validitée de l'offre";
                            return false;
                        }
                        else {

                            try {
                                tab1 = $scope.currentOffer.StartValidity.split('/');
                                tab2 = $scope.currentOffer.EndValidity.split('/');
                                if (tab1.length > 1)
                                    date1 = new Date(tab1[2] + "-" + tab1[1] + "-" + tab1[0]).toISOString();
                                else {
                                    date1 = new Date($scope.currentOffer.StartValidity).toISOString();
                                }
                                if (tab2.length > 1)
                                    date2 = new Date(tab2[2] + "-" + tab2[1] + "-" + tab2[0]).toISOString();
                                else {
                                    date1 = new Date($scope.currentOffer.EndValidity).toISOString();
                                }
                            }
                            catch (e) {

                                $scope.errorMessage = "Le format de date n'est pas valide";
                                return false;
                            }
                            if (date1 > date2) {

                                $scope.errorMessage = "La date de début de validité de l'offre est ultérieur à la date de fin de validité";
                                return false;
                            }
                        }
                    }

                    if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Engagement || $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.EngagementSurface) {
                        var regexDate = /^(\d{2}\/){2}\d{4}$/;

                        if ($rootScope.paramBUseDeliveriesDates){
                            if (!$scope.currentOffer.DeliveryStartDate || !regexDate.test($scope.currentOffer.DeliveryStartDate)) {
                                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Expiry_DeliveryStartDate_Error.Content;
                                return false;
                            }
                            if (!$scope.currentOffer.DeliveryEndDate || !regexDate.test($scope.currentOffer.DeliveryEndDate)) {
                                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Expiry_DeliveryEndDate_Error.Content;
                                return false;
                            }
                            if ($scope.currentOffer.DeliveryEndDate && $scope.currentOffer.DeliveryStartDate && dateService.compareDate($scope.currentOffer.DeliveryEndDate, $scope.currentOffer.DeliveryStartDate)) {
                                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Expiry_DateError_EndDateBeforeStartDate.Content;
                                return false;
                            }
                        }

                        if ($rootScope.paramBUsePaymentDate && (!$scope.currentOffer.PaymentDate || !regexDate.test($scope.currentOffer.PaymentDate))) {
                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Expiry_PaymentDate_Error.Content;
                            return false;
                        }
                    }

                    if ($scope.currentOffer.CommitmentMin !== undefined && $scope.currentOffer.CommitmentMin !== "" && $scope.currentOffer.CommitmentMin !== null && isNaN($scope.currentOffer.CommitmentMin)) {
                        $scope.errorMessage = "Veuillez renseigner un engagement minimum valide";
                        return false;
                    }
                    if ($scope.currentOffer.CommitmentMax !== undefined && $scope.currentOffer.CommitmentMax !== "" && $scope.currentOffer.CommitmentMax !== null && isNaN($scope.currentOffer.CommitmentMax)) {
                        $scope.errorMessage = "Veuillez renseigner un engagement maximum valide";
                        return false;
                    }

                    if ($scope.currentOffer.Quotity === undefined && $scope.currentOffer.Quotity === "" && $scope.currentOffer.Quotity === null && isNaN($scope.currentOffer.Quotity)) {
                        $scope.errorMessage = "Veuillez renseigner un pas valide";
                        return false;
                    }

                    $scope.errorMessage = "";
                    return true;
                case 5:
                    if (!$scope.blankContract && ($scope.currentOffer.GroupContacts === undefined || $scope.currentOffer.GroupContacts === null || $scope.currentOffer.GroupContacts === "" || $scope.currentOffer.GroupContacts.length === 0 ||
                        $scope.currentOffer.Templates === undefined || $scope.currentOffer.Templates === null || $scope.currentOffer.Templates === "" || $scope.currentOffer.Templates.length === 0)) {
                        $scope.errorMessage = "Veuillez renseigner l'ensemble des éléments.";
                        return false;
                    } else if ($scope.blankContract && ($scope.currentOffer.Templates === undefined || $scope.currentOffer.Templates === null || $scope.currentOffer.Templates === "" || $scope.currentOffer.Templates.length === 0)) {
                        $scope.errorMessage = "Veuillez renseigner l'ensemble des éléments.";
                    }

                    if (!$scope.validTypeTemplateNotSame)
                        return false;

                    $scope.CheckTemplate();

                    if ($scope.HasQuantityLimit && $scope.currentOffer.IsTonnageLimit) {

                        $scope.currentOffer.OfferGroupContactTonnageVm.forEach(function (OfferGroupContactTonnage) {
                            if (OfferGroupContactTonnage.QuantityLimit == null || OfferGroupContactTonnage.QuantityLimit == "" || OfferGroupContactTonnage.QuantityLimit == undefined || !angular.isNumber(parseFloat(OfferGroupContactTonnage.QuantityLimit))) {
                                $scope.errorMessage += $scope.Label_Admin_Offer_ErrorMessage_Step5_TonnageGroup + OfferGroupContactTonnage.Label + ".<br/>";
                                return false;
                            }
                        });

                        if ($scope.TotalQuantityLimit == 0) {
                            $scope.errorMessage += $scope.Label_Admin_Offer_ErrorMessage_Step5_TonnageByGroup + "<br/>";
                            return false;
                        }

                        if ($scope.TotalQuantityLimit < $scope.currentOffer.QuantityLimit) {
                            $scope.errorMessage += $scope.Label_Admin_Offer_ErrorMessage_Step5_Sum_LowerTonnage + "<br/>";
                            return false;
                        }

                        if ($scope.TotalQuantityLimit > $scope.currentOffer.QuantityLimit) {
                            $scope.errorMessage += $scope.Label_Admin_Offer_ErrorMessage_Step5_Sum_HigherTonnage + "<br/>";
                            return false;
                        }
                    }

                    if ($scope.errorMessage !== "")
                        return false;
                    else
                        return true;
                    break;
                default:
                    return false;
            }
        }

        $scope.CheckTemplate = function () {
            $scope.errorMessage = "";

            var idTypeTemplateToCheck = 0;
            var contractLabel = "";
            var typeOrderLabel = null;

            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Engagement) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.Engagement;
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_ContractCommitement.Content + "\"";
                checkTemplateForType(idTypeTemplateToCheck, contractLabel);
            }

            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.EngagementSurface) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.EngagementSurface;
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_ContractCommitmentSurface.Content + "\"";
                checkTemplateForType(idTypeTemplateToCheck, contractLabel);
            }

            if ($scope.blankContract) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.BlankContract;
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_BlankContract.Content + "\"";
                var anyBlankContractTemplate = $filter('filter')($scope.currentOffer.Templates, { IdTypeTemplate: idTypeTemplateToCheck }, true).length > 0;
                if (!anyBlankContractTemplate)
                    $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_MustSelectContractType.Content + contractLabel + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForTypeOrder.Content;
            }

            if ($scope.multiCampagne) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.MultiCrop;
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_ContractMultiCrop.Content + "\"";
                var anyContractMultiCropTemplate = $filter('filter')($scope.currentOffer.Templates, { IdTypeTemplate: idTypeTemplateToCheck }, true).length > 0;
                if (!anyContractMultiCropTemplate)
                    $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_MustSelectContractType.Content + contractLabel + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForTypeOrder.Content;
            }

            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.PrixConstruit) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.ContratBuiltPrice;
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_ContratBuiltPrice.Content + "\"";
                checkTemplateForType(idTypeTemplateToCheck, contractLabel);

                idTypeTemplateToCheck = enumFactory.TypeTemplates.ExitAnticipatedBuiltPrice;
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_ExitAnticipatedBuiltPrice.Content + "\"";
                checkTemplateForType(idTypeTemplateToCheck, contractLabel);
            }

            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Option) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.Option;
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_ContractOption.Content + "\"";
                checkTemplateForType(idTypeTemplateToCheck, contractLabel);
            }

            if ($scope.currentOffer.TypeFixationTypeOrders.SoldExerciceOption) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.ExerciseOption; // Template exercise d'option obligatoire
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_ExerciseOption.Content + "\"";
                checkTemplateForType(idTypeTemplateToCheck, contractLabel);
            }

            if ($scope.currentOffer.TypeFixationTypeOrders.TargetExerciceOption) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.TargetExerciseOption; // Template objectif exercise d'option obligatoire
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_TargetExerciseOption.Content + "\"";
                checkTemplateForType(idTypeTemplateToCheck, contractLabel);
            }

            if ($scope.currentOffer.TypeFixationTypeOrders.SoldFuture || $scope.currentOffer.TypeFixationTypeOrders.SoldGlobalPrice && !$scope.multiCampagne) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.Vente;
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_ContractSell.Content + "\"";
                typeOrderLabel = enumFactory.TypeOrders.Vente.Label;
                checkTemplateForType(idTypeTemplateToCheck, contractLabel);
            }

            if ($scope.currentOffer.TypeFixationTypeOrders.TargetPriceFuture || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceGlobalPrice) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.Objectif;
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_ContractObjectifPrice.Content + "\"";
                typeOrderLabel = enumFactory.TypeOrders.Prixobjectif.Label;
                checkTemplateForType(idTypeTemplateToCheck, contractLabel);
            }

            if ($scope.currentOffer.TypeFixationTypeOrders.SoldBase) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.VenteBase;
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_ContractBaseSell.Content + "\"";
                typeOrderLabel = enumFactory.TypeOrders.Vente.Label;
                checkTemplateForType(idTypeTemplateToCheck, contractLabel);
            }

            if ($scope.currentOffer.TypeFixationTypeOrders.TargetPriceBase) {
                idTypeTemplateToCheck = enumFactory.TypeTemplates.ObjectifBase;
                contractLabel = "\"" + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_ContractBaseObjectifPrice.Content + "\"";
                typeOrderLabel = enumFactory.TypeOrders.Prixobjectif.Label;
                checkTemplateForType(idTypeTemplateToCheck, contractLabel);
            }

            checkCrossTypeTemplate();
        };

        var checkTemplateForType = function (idTypeTemplateToCheck, contractLabel) {

            //Check des template par type
            if (!$rootScope.ModuleMultipleCompagnies.Active) { // Pas de multisociété
                if (!$scope.currentOffer.Templates.some(function (template) { return template.IdTypeTemplate === idTypeTemplateToCheck; })) {
                    $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_MustSelectContractType.Content + contractLabel + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForTypeOrder.Content;
                    $scope.errorMessage += "<br/>";
                    return;
                }

                if (!$scope.currentOffer.Templates.some(function (template) { return template.IdTypeTemplate === idTypeTemplateToCheck && template.IdLanguage == $scope.defaultLanguage.IdLanguage; })) {
                    $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_MustSelectContractType.Content + contractLabel +
                        $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_InLanguage.Content + "\"" + $scope.defaultLanguage.Label + "\"";
                    $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForTypeOrder.Content;
                    $scope.errorMessage += "<br/>";
                    return;
                }
            } else {
                if ($scope.currentOffer.IdCompagny) { // une société choisie
                    var offerSelectedCompagny = $filter('filter')($scope.lstCompagnies, { IdCompagny: Number($scope.currentOffer.IdCompagny) }, true)[0];

                    if (!$scope.currentOffer.Templates.some(function (template) { return template.IdTypeTemplate === idTypeTemplateToCheck && template.IdCompagny === Number($scope.currentOffer.IdCompagny); })) {
                        $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_MustSelectContractType.Content + contractLabel +
                            $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForSociety.Content + "\"" + offerSelectedCompagny.Label + "\"";
                        $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForTypeOrder.Content;
                        $scope.errorMessage += "<br/>";
                        return;
                    }

                    if (!$scope.currentOffer.Templates.some(function (template) { return template.IdTypeTemplate === idTypeTemplateToCheck && template.IdCompagny === Number($scope.currentOffer.IdCompagny) && template.IdLanguage == $scope.defaultLanguage.IdLanguage; })) {
                        $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_MustSelectContractType.Content + contractLabel +
                            $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForSociety.Content + "\"" + offerSelectedCompagny.Label + "\"" +
                            $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_InLanguage.Content + "\"" + $scope.defaultLanguage.Label + "\"";
                        $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForTypeOrder.Content;
                        $scope.errorMessage += "<br/>";
                    }
                }
                else { // toutes les sociétés
                    if ($scope.currentOffer.Templates.filter(function (template) { return template.IdTypeTemplate === idTypeTemplateToCheck; }).length < $scope.lstCompagnies.length) {
                        $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_MustSelectContractType.Content + contractLabel + $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForAllSociety.Content;
                        $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForTypeOrder.Content;
                        $scope.errorMessage += "<br/>";
                        return;
                    }

                    $scope.lstCompagnies.forEach(function (compangny) {
                        if (!$scope.currentOffer.Templates.some(function (template) { return template.IdTypeTemplate === idTypeTemplateToCheck && template.IdCompagny === compangny.IdCompagny; })) {
                            $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_MustSelectContractType.Content + contractLabel +
                                $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForSociety.Content + "\"" + compangny.Label + "\"";
                            $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForTypeOrder.Content;
                            $scope.errorMessage += "<br/>";
                        }

                        if (!$scope.currentOffer.Templates.some(function (template) { return template.IdTypeTemplate === idTypeTemplateToCheck && template.IdCompagny === compangny.IdCompagny && template.IdLanguage == $scope.defaultLanguage.IdLanguage; })) {

                            $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_MustSelectContractType.Content + contractLabel +
                                $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForSociety.Content + "\"" + compangny.Label + "\"" +
                                $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_InLanguage.Content + "\"" + $scope.defaultLanguage.Label + "\"";
                            $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_ForTypeOrder.Content;
                            $scope.errorMessage += "<br/>";
                        }
                    });
                }
            }
        };

        var checkCrossTypeTemplate = function () {

            angular.forEach($scope.currentOffer.Templates, function (itemTemplate) {

                if ((itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.Engagement && $scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.Engagement) ||
                    (itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.EngagementSurface && $scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.EngagementSurface) ||
                    (itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.ContratBuiltPrice && $scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.PrixConstruit) ||
                    (itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.ExitAnticipatedBuiltPrice && $scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.PrixConstruit) ||
                    (itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.Option && $scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.Option) ||
                    (itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.Vente && (!$scope.currentOffer.TypeFixationTypeOrders.SoldFuture && !$scope.currentOffer.TypeFixationTypeOrders.SoldGlobalPrice)) ||
                    (itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.Objectif && (!$scope.currentOffer.TypeFixationTypeOrders.TargetPriceFuture && !$scope.currentOffer.TypeFixationTypeOrders.TargetPriceGlobalPrice)) ||
                    (itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.VenteBase && !$scope.currentOffer.TypeFixationTypeOrders.SoldBase) ||
                    (itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.ObjectifBase && !$scope.currentOffer.TypeFixationTypeOrders.TargetPriceBase) ||
                    (itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.MultiCrop && !$scope.multiCampagne) ||
                    (itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.ExerciseOption && !$scope.currentOffer.TypeFixationTypeOrders.SoldExerciceOption) ||
                    (itemTemplate.IdTypeTemplate === enumFactory.TypeTemplates.TargetExerciseOption && !$scope.currentOffer.TypeFixationTypeOrders.TargetExerciceOption)
                ) {
                    $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.CreateOffer_Template_Error_NotAuthorized.Content.replace('[template]', '"' + itemTemplate.Label + '"');
                    $scope.errorMessage += "<br />";
                }
            });

        };

        $scope.CheckAndSaveOffer = function () {
            if ($scope.ModeDupliquer) {
                if ($scope.watchedit !== undefined && $scope.watchedit !== null) {
                    $scope.noWatch();
                }
                if ($scope.watchTypeFixation !== undefined && $scope.watchTypeFixation !== null) {
                    $scope.noWatchTypeFixation();
                }
                if ($scope.watchFixationBaseConstraint !== undefined && $scope.watchFixationBaseConstraint !== null) {
                    $scope.nowatchFixationBaseConstraint();
                }
                
                if ($scope.watchEditBuiltPrice !== undefined && $scope.watchEditBuiltPrice !== null) {
                    $scope.noWatchBuiltPriceData();
                }
                $scope.errorMessage = "";
                $scope.currentOffer.IdOffer = 0;
                $scope.currentOffer.Validate = true;
            }

            if ($scope.currentOffer.StartValidity && $scope.currentOffer.EndValidity) {
                var tabStartValidity = $scope.currentOffer.StartValidity.split('/');
                var tabEndValidity = $scope.currentOffer.EndValidity.split('/');

                if (tabStartValidity.length > 1)
                    $scope.currentOffer.StartValidity = new Date(tabStartValidity[2] + "-" + tabStartValidity[1] + "-" + tabStartValidity[0]).toISOString();
                else {
                    $scope.currentOffer.StartValidity = new Date($scope.currentOffer.StartValidity).toISOString();
                }
                if (tabEndValidity.length > 1)
                    $scope.currentOffer.EndValidity = new Date(tabEndValidity[2] + "-" + tabEndValidity[1] + "-" + tabEndValidity[0]).toISOString();
                else {
                    $scope.currentOffer.EndValidity = new Date($scope.currentOffer.EndValidity).toISOString();
                }
            }

            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.PrixConstruit) {
                if ($scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate && $scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate) {
                    var tabSubscriptionPeriodBuiltPriceStartDate = $scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate.split('/');
                    var tabSubscriptionPeriodBuiltPriceEndDate = $scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate.split('/');

                    if (tabSubscriptionPeriodBuiltPriceStartDate.length > 1)
                        $scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate = new Date(tabSubscriptionPeriodBuiltPriceStartDate[2] + "-" + tabSubscriptionPeriodBuiltPriceStartDate[1] + "-" + tabSubscriptionPeriodBuiltPriceStartDate[0]).toISOString();
                    else {
                        $scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate = new Date($scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate).toISOString();
                    }
                    if (tabSubscriptionPeriodBuiltPriceEndDate.length > 1)
                        $scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate = new Date(tabSubscriptionPeriodBuiltPriceEndDate[2] + "-" + tabSubscriptionPeriodBuiltPriceEndDate[1] + "-" + tabSubscriptionPeriodBuiltPriceEndDate[0]).toISOString();
                    else {
                        $scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate = new Date($scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate).toISOString();
                    }
                }

                if ($scope.currentOffer.ObservationPeriodBuiltPriceStartDate && $scope.currentOffer.ObservationPeriodBuiltPriceEndDate) {
                    var tabObservationPeriodBuiltPriceStartDate = $scope.currentOffer.ObservationPeriodBuiltPriceStartDate.split('/');
                    var tabObservationPeriodBuiltPriceEndDate = $scope.currentOffer.ObservationPeriodBuiltPriceEndDate.split('/');

                    if (tabObservationPeriodBuiltPriceStartDate.length > 1)
                        $scope.currentOffer.ObservationPeriodBuiltPriceStartDate = new Date(tabObservationPeriodBuiltPriceStartDate[2] + "-" + tabObservationPeriodBuiltPriceStartDate[1] + "-" + tabObservationPeriodBuiltPriceStartDate[0]).toISOString();
                    else {
                        $scope.currentOffer.ObservationPeriodBuiltPriceStartDate = new Date($scope.currentOffer.ObservationPeriodBuiltPriceStartDate).toISOString();
                    }
                    if (tabObservationPeriodBuiltPriceEndDate.length > 1)
                        $scope.currentOffer.ObservationPeriodBuiltPriceEndDate = new Date(tabObservationPeriodBuiltPriceEndDate[2] + "-" + tabObservationPeriodBuiltPriceEndDate[1] + "-" + tabObservationPeriodBuiltPriceEndDate[0]).toISOString();
                    else {
                        $scope.currentOffer.ObservationPeriodBuiltPriceEndDate = new Date($scope.currentOffer.ObservationPeriodBuiltPriceEndDate).toISOString();
                    }
                }
            }

            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Engagement || $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.EngagementSurface) {
                if ($rootScope.paramBUseDeliveriesDates && $scope.currentOffer.DeliveryStartDate)
                    $scope.currentOffer.DeliveryStartDate = dateService.ConvertDateToDateAPI($scope.currentOffer.DeliveryStartDate);
                if ($rootScope.paramBUseDeliveriesDates && $scope.currentOffer.DeliveryEndDate)
                    $scope.currentOffer.DeliveryEndDate = dateService.ConvertDateToDateAPI($scope.currentOffer.DeliveryEndDate);
                if ($rootScope.paramBUsePaymentDate && $scope.currentOffer.PaymentDate)
                    $scope.currentOffer.PaymentDate = dateService.ConvertDateToDateAPI($scope.currentOffer.PaymentDate);
            }

            $scope.currentOffer.Step = $scope.step;

            if ($scope.watchEditBuiltPrice !== undefined && $scope.watchEditBuiltPrice !== null) {
                $scope.noWatchBuiltPriceData();
            }
            if ($scope.watchedit !== undefined && $scope.watchedit !== null) {
                $scope.noWatch();
            }

            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Engagement || $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.EngagementSurface) {
                $scope.currentOffer.IdMarket = null;
                $scope.currentOffer.OfferExpiriesVm = [];
                $scope.lstOfferExpiry = null;
            }

            if ($scope.lstOfferExpiry) {
                var offerExpiries = angular.copy($scope.lstOfferExpiry);
                $scope.currentOffer.OfferExpiriesVm = offerExpiries.filter(function (element) {
                    element.DateClosed = dateService.ConvertDateToDateAPI(element.DateClosed);
                    if (element.DateStart)
                        element.DateStart = dateService.ConvertDateToDateAPI(element.DateStart);

                    if (element.OptionLimitExerciceDate)
                        element.OptionLimitExerciceDate = dateService.ConvertDateToDateAPI(element.OptionLimitExerciceDate);

                    if ($scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.Option && !$scope.blankContract) {
                        if ($rootScope.paramBUseDeliveriesDates){
                            element.DeliveryStartDate = dateService.ConvertDateToDateAPI(element.DeliveryStartDate);
                            element.DeliveryEndDate = dateService.ConvertDateToDateAPI(element.DeliveryEndDate);
                        }
                        if ($rootScope.paramBUsePaymentDate)
                            element.PaymentDate = dateService.ConvertDateToDateAPI(element.PaymentDate);
                    }

                    return element;
                });
            }

            //Controle répartition des tonnages de l'étape 5 
            if ($scope.TotalQuantityLimit != parseFloat($scope.currentOffer.QuantityLimit) && $scope.currentOffer.IsTonnageLimit) {
                $scope.errorMessage = $scope.Label_Admin_Offer_ErrorMessage_Step4_DistributionTonnage;
                return;
            }
            // On renseigne le IdTypeCommitmentForSubscribe que si l'admin à choisi le type d'engagement 'Engagement obligatoire avant prise de position'
            if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme && $scope.currentOffer.IdTypeOfferCommitment !== $scope.enumTypeOfferCommitments.EngagementObligatoire && $scope.currentOffer.IdTypeOfferCommitment !== $scope.enumTypeOfferCommitments.EngagementFacultatif)
                $scope.currentOffer.IdTypeCommitmentForSubscribe = null;

            $scope.listContractBasesToValide = null;
            $scope.listContractsToValide = null;
            if (!$scope.watchedit) {
                if ($scope.ModeEdition && $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme && ($scope.currentOffer.TypeFixationTypeOrders.TargetPriceBase || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceFuture || $scope.currentOffer.TypeFixationTypeOrders.TargetPriceGlobalPrice)) {
                    contractBaseService.GetTargetContractBasesFixToValidate($scope.currentOffer.OfferExpiriesVm, function (dataContractBase, status) {
                        if (status !== null) {
                            if (status === 400)
                                $scope.errorMessage = data[0].errors[0].Message;
                            else
                                $scope.errorMessage = status;
                        }
                        else {
                            if (dataContractBase && dataContractBase.length > 0)
                                $scope.listContractBasesToValide = dataContractBase;

                            if (!$scope.currentOffer.Price)
                                $scope.currentOffer.Price = "";

                            contractsService.GetTargetContractsToValidate($scope.currentOffer.Price, $scope.currentOffer.OfferExpiriesVm, function (dataContract, statusContract) {
                                if (statusContract !== null) {
                                    if (statusContract === 400)
                                        $scope.errorMessage = dataContract[0].errors[0].Message;
                                    else
                                        $scope.errorMessage = statusContract;
                                }
                                else {
                                    if (dataContract && dataContract.length > 0)
                                        $scope.listContractsToValide = dataContract;

                                    if (($scope.listContractsToValide && $scope.listContractsToValide.length > 0) || ($scope.listContractBasesToValide && $scope.listContractBasesToValide.length > 0))
                                        $scope.showPopUpListContractsFixToValidate();
                                    else
                                        SaveOffer();
                                }
                            });
                        }

                    });
                }
                else {
                    SaveOffer();
                }
            }
        };

        $scope.toogleAllElementsLeft = function () {
            $scope.master = !$scope.master;
            $.each($scope.lstvarieteTmp, function (index, item) {
                item.checked = $scope.master;
            });
        };

        $scope.toogleAllElementsRight = function () {
            $scope.master2 = !$scope.master2;
            $.each($scope.currentOffer.VarietiesVm, function (index, item) {
                item.checked = $scope.master2;
            });
        };

        //ajout des variétés a l'offre
        $scope.ListToSelected = function () {
            $scope.master = false;
            $scope.master2 = false;

            // On envoi tous les éléments sélectionnés dans la liste de droite
            $.each($scope.lstvarieteTmp, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.currentOffer.VarietiesVm.push(item);
                }
            });

            // On supprime de la liste de gauche, tous les éléments envoyés
            $.each($scope.currentOffer.VarietiesVm, function (index, item) {
                var indexItem = $scope.lstvarieteTmp.indexOf(item);
                if (indexItem !== -1)
                    $scope.lstvarieteTmp.splice(indexItem, 1);
            });
        };

        //suppression des variétés a l'offre
        $scope.SelectedToList = function () {
            $scope.master = false;
            $scope.master2 = false;

            // On envoi tous les éléments sélectionnés dans la liste de gauche
            $.each($scope.currentOffer.VarietiesVm, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.lstvarieteTmp.push(item);
                }
            });

            // On supprime de la liste de droite, tous les éléments envoyés
            $.each($scope.lstvarieteTmp, function (index, item) {
                var indexItem = $scope.currentOffer.VarietiesVm.indexOf(item);
                if (indexItem !== -1)
                    $scope.currentOffer.VarietiesVm.splice(indexItem, 1);
            });
        };

        $scope.toogleAllElementsLeftGroup = function () {
            $scope.master3 = !$scope.master3;
            $.each($scope.lstGroupContacts, function (index, item) {
                item.checked = $scope.master3;
            });
        };

        $scope.toogleAllElementsRightGroup = function () {
            $scope.master4 = !$scope.master4;
            $.each($scope.currentOffer.GroupContacts, function (index, item) {
                item.checked = $scope.master4;
            });
        };

        $scope.ListToSelectedGroup = function () {
            $scope.master3 = false;
            $scope.master4 = false;

            $.each($scope.lstGroupContacts, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.currentOffer.GroupContacts.push(item);
                }
            });

            $.each($scope.currentOffer.GroupContacts, function (index, item) {
                var indexItem = $scope.lstGroupContacts.indexOf(item);
                if (indexItem !== -1)
                    $scope.lstGroupContacts.splice(indexItem, 1);
            });
            $scope.UpdateOfferGroupContact();
        };

        $scope.SelectedToListGroup = function () {
            $scope.master3 = false;
            $scope.master4 = false;

            $.each($scope.currentOffer.GroupContacts, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.lstGroupContacts.push(item);
                }
            });

            $.each($scope.lstGroupContacts, function (index, item) {
                var indexItem = $scope.currentOffer.GroupContacts.indexOf(item);
                if (indexItem !== -1)
                    $scope.currentOffer.GroupContacts.splice(indexItem, 1);
            });
            $scope.UpdateOfferGroupContact();
        };

        $scope.toogleAllElementsLeftContract = function () {
            $scope.master5 = !$scope.master5;
            $.each($scope.lstTemplates, function (index, item) {
                item.checked = $scope.master5;
            });
        };

        $scope.toogleAllElementsRightContract = function () {
            $scope.master6 = !$scope.master6;
            $.each($scope.currentOffer.Templates, function (index, item) {
                item.checked = $scope.master6;
            });
        };


        $scope.ListToSelectedContract = function () {
            $scope.errorMessage = "";
            $scope.master5 = false;
            $scope.master6 = false;

            $.each($scope.lstTemplates, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.currentOffer.Templates.push(item);
                }
            });

            $.each($scope.currentOffer.Templates, function (index, item) {
                var indexItem = $scope.lstTemplates.indexOf(item);
                if (indexItem !== -1)
                    $scope.lstTemplates.splice(indexItem, 1);
            });
        };

        $scope.SelectedToListContract = function () {
            $scope.errorMessage = "";
            $scope.master5 = false;
            $scope.master6 = false;

            $.each($scope.currentOffer.Templates, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.lstTemplates.push(item);
                }
            });

            $.each($scope.lstTemplates, function (index, item) {
                var indexItem = $scope.currentOffer.Templates.indexOf(item);
                if (indexItem !== -1)
                    $scope.currentOffer.Templates.splice(indexItem, 1);
            });
        };

        $scope.ClearStep = function (step) {
            //nbStep = nombre des etapes a vidé
            for (var i = step + 1; i <= nbStep; i++) {
                Clear(i);
            }
            $scope.currentOffer.Validate = false;
        };

        function Clear(step) {
            switch (step) {
                case 2:
                    $scope.currentOffer.Price = "";
                    $scope.currentOffer.IdMarket = "";
                    $scope.currentOffer.IdExpiry = "";
                    $scope.currentOffer.Base = "";
                    $scope.currentOffer.IdDestination = "";
                    $scope.currentOffer.IdTree = "";
                    $scope.lstOfferExpiry = [];
                    $scope.currentOffer.Prime = "";
                    $scope.currentOffer.Margin = "";
                    $scope.currentOffer.FinancialCosts = "";
                    $scope.currentOffer.LogisticCosts = "";
                    $scope.currentOffer.Depot = "";
                    $scope.currentOffer.Others = "";
                    break;
                case 3:
                    $scope.currentOffer.TargetPrice = false;
                    $scope.currentOffer.StartVisibility = "";
                    $scope.currentOffer.EndVisibility = "";
                    $scope.currentOffer.StartPosition = "";
                    $scope.currentOffer.EndPosition = "";
                    $scope.currentOffer.StartTargetPrice = "";
                    $scope.currentOffer.EndTargetPrice = "";
                    $scope.currentOffer.IdValidityDuration = "";
                    $scope.currentOffer.AllowDateEndTargetPrice = null;
                    $scope.currentOffer.isVisibleH24 = false;
                    $scope.currentOffer.isPositionH24 = false;
                    $scope.currentOffer.isTargetPriceH24 = false;
                    $scope.currentOffer.isPositionExerciceOptionH24 = false;
                    $scope.currentOffer.isTargetExerciceOptionH24 = false;
                    $scope.currentOffer.IdTypeOfferCommitment = null;
                    $scope.currentOffer.IdTypeCommitmentForSubscribe = null;
                    $scope.currentOffer.TypeFixationTypeOrders = {
                        SoldBase: false,
                        TargetPriceBase: false,
                        SoldFuture: false,
                        TargetPriceFuture: false,
                        SoldGlobalPrice: false,
                        TargetPriceGlobalPrice: false
                    };
                    break;
                case 4:
                    $scope.currentOffer.StartValidity = "";
                    $scope.currentOffer.EndValidity = "";
                    $scope.currentOffer.DeliveryStartDate = "";
                    $scope.currentOffer.DeliveryEndDate = "";
                    $scope.currentOffer.PaymentDate = "";
                    $scope.currentOffer.QuantityLimit = "";
                    $scope.currentOffer.CommitmentMin = "";
                    $scope.currentOffer.CommitmentMax = "";
                    $scope.currentOffer.Quotity = "";
                    $scope.currentOffer.SubscriptionPeriodBuiltPriceStartDate = "";
                    $scope.currentOffer.SubscriptionPeriodBuiltPriceEndDate = "";
                    $scope.currentOffer.ObservationPeriodBuiltPriceStartDate = "";
                    $scope.currentOffer.ObservationPeriodBuiltPriceEndDate = "";

                    break;
                case 5:

                    $scope.currentOffer.Templates = [];
                    $scope.currentOffer.GroupContacts = [];
                    break;
                default:
                    break;
            }
        }

        $scope.CheckDateObjectif = function () {
            if (($scope.currentOffer.IdValidityDuration && $scope.currentOffer.IdValidityDuration === enumFactory.ValidityDurations.EndDateOffer && !$scope.currentOffer.AllowDateEndTargetPrice) || $scope.currentOffer.IdValidityDuration !== enumFactory.ValidityDurations.EndDateOffer)
                $scope.currentOffer.AllowDateEndTargetPrice = false;
        };

        $scope.AddExpiry = function () {
            $scope.errorMessage = "";
            var expiryVm = {
            };
            if ($scope.currentOffer.IdExpiry && $scope.lstExpiry && $scope.lstExpiry.length > 0 && !$scope.fixe && !$scope.blankContract) {
                var expriryWSD = $filter('filter')($scope.lstExpiry, { IdExpiry: $scope.currentOffer.IdExpiry }, true)[0];
                var marketVm = $filter('filter')($scope.lstMarkets, { IdMarket: $scope.currentOffer.IdMarket }, true)[0];
                expiryVm = {
                    IdExpiry: 0,
                    IdOffer: $scope.currentOffer.IdOffer,
                    IdExpiryWSD: expriryWSD.IdExpiry,
                    IdMarket: $scope.currentOffer.IdMarket,
                    LabelledWSD: expriryWSD.ExpiryNameFR,
                    DateClosed: $filter('date')(expriryWSD.CloseDate, 'dd/MM/yyyy'),
                    DateStart: null,
                    Label: expriryWSD.ExpiryNameFR,
                    LabelDefault: expriryWSD.ExpiryNameFR,
                    Base: null,
                    IsNew: true,
                    TypeLocat: marketVm.TypeLocat,
                    Strikes: [],
                    ListStrikeDelta: [],
                    IsVisible: true,
                    Prime: null,
                    Margin: null,
                    FinancialCosts: null,
                    LogisticCosts: null,
                    Depot: null,
                    Others: null

                };
            } else {
                if ($scope.fixe || $scope.blankContract) {
                    expiryVm = {
                        IdExpiry: 0,
                        IdOffer: $scope.currentOffer.IdOffer,
                        IdExpiryWSD: null,
                        IdMarket: null,
                        LabelledWSD: '',
                        DateClosed: null,
                        DateStart: null,
                        Label: "",
                        LabelDefault: "",
                        Base: null,
                        IsNew: true,
                        DateMin: new Date(),
                        IsVisible: true,
                        Strikes: [],
                        ListStrikeDelta: [],
                        Prime: null,
                        Margin: null,
                        FinancialCosts: null,
                        LogisticCosts: null,
                        Depot: null,
                        Others: null
                    };
                }
            }
            if (expiryVm) {
                $scope.lstOfferExpiry.push(expiryVm);
            }
        };

        $scope.DeleteExpiry = function (index) {
            $scope.errorMessage = "";
            if ($scope.lstOfferExpiry && $scope.lstOfferExpiry.length > 0)
                $scope.lstOfferExpiry.splice(index, 1);
        };

        $scope.GetTotalBase = function (offerExpiry) {
            var offerExpiryBaseValueToRound = Number(offerExpiry.Prime) + Number(offerExpiry.Margin) + Number(offerExpiry.FinancialCosts) + Number(offerExpiry.LogisticCosts) + Number(offerExpiry.Depot) + Number(offerExpiry.Others);
            offerExpiry.Base = offerExpiryBaseValueToRound.toFixed(2);
        };

        $scope.CheckExpiries = function () {
            
            $scope.errorMessage = "";
            var error = "";
            var promises = [];
            var regexDate = /^(\d{2}\/){2}\d{4}$/;
            $scope.lstOfferExpiry.forEach(function (item, index) {
                var deferred = $q.defer();

                if (!item.Label)
                    error += "Veuillez renseigner un libellé pour l'échéance " + (!item.Label ? (index + 1) : item.Label) + "<br />";

                if ((!item.DateClosed && !item.Base) || (item.DateClosed && item.Base && !Number(item.Base) && !regexDate.test(item.DateClosed)))
                    error += "Veuillez renseigner une date de fin d'échéance et une base valide pour l'échéance " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                else {
                    if (!item.DateClosed || (item.DateClosed && !regexDate.test(item.DateClosed)))
                        error += "Veuillez renseigner une date de fin d'échéance valide pour l'échéance " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                    if (item.DateStart && !regexDate.test(item.DateStart))
                        error += "Veuillez renseigner une date de début d'échéance valide pour l'échéance " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                    if (item.DateStart && dateService.compareDate(item.DateClosed, item.DateStart))
                        error += "La date de début doit être antérieur à la date de fin d'échéance pour l'échéance " + item.Label + "<br />";
                    
                    if (!$scope.IsUneal && $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme){
                        if (!item.Base)
                            item.Base = 0;
                        if ((item.Base !== 0 && item.Base !== '0') && !Number(item.Base))
                            error += "Veuillez renseigner une base valide pour l'échéance " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                    }

                    if ($scope.IsUneal && $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Ferme) {
                        if (item.Prime !== 0 && item.Prime !== '0' && item.Prime && !Number(item.Prime))
                            error += $scope.Label_Admin_Offer_PrimeExpiry + " " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                        if (item.Margin !== 0 && item.Margin !== '0' && item.Margin && !Number(item.Margin))
                            error += $scope.Label_Admin_Offer_MarginExpiry + " " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                        if (item.FinancialCosts !== 0 && item.FinancialCosts !== '0' && item.FinancialCosts && !Number(item.FinancialCosts))
                            error += $scope.Label_Admin_Offer_FinancialCostsExpiry + " " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                        if (item.LogisticCosts !== 0 && item.LogisticCosts !== '0' && item.LogisticCosts && !Number(item.LogisticCosts))
                            error += $scope.Label_Admin_Offer_LogisticCostsExpiry + " " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                        if (item.Depot !== 0 && item.Depot !== '0' && item.Depot && !Number(item.Depot))
                            error += $scope.Label_Admin_Offer_DepotExpiry + " " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                        if (item.Others !== 0 && item.Others !== '0' && item.Others && !Number(item.Others))
                            error += $scope.Label_Admin_Offer_OthersExpiry + " " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                    }
                }

                item.Prime = $scope.bActiveDecomposeBase && (item.Prime == null || item.Prime == undefined || item.Prime == "") ? null : item.Prime;
                item.Margin = $scope.bActiveDecomposeBase && (item.Margin == null || item.Margin == undefined || item.Margin == "") ? null : item.Margin;
                item.FinancialCosts = $scope.bActiveDecomposeBase && (item.FinancialCosts == null || item.FinancialCosts == undefined || item.FinancialCosts == "") ? null : item.FinancialCosts;
                item.LogisticCosts = $scope.bActiveDecomposeBase && (item.LogisticCosts == null || item.LogisticCosts == undefined || item.LogisticCosts == "") ? null : item.LogisticCosts;
                item.Depot = $scope.bActiveDecomposeBase && (item.Depot == null || item.Depot == undefined || item.Depot == "") ? null : item.Depot;
                item.Others = $scope.bActiveDecomposeBase && (item.Others == null || item.Others == undefined || item.Others == "") ? null : item.Others;


                if ($scope.currentOffer.IdTypeOffer !== $scope.enumTypeOffers.Option && !$scope.blankContract) {
                    if ($rootScope.paramBUseDeliveriesDates){
                        if (!item.DeliveryStartDate || !regexDate.test(item.DeliveryStartDate))
                            error += $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Expiry_DeliveryStartDate_Error.Content + " pour l'échéance " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                        if (!item.DeliveryEndDate || !regexDate.test(item.DeliveryEndDate))
                            error += $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Expiry_DeliveryEndDate_Error.Content + " pour l'échéance " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                        if (item.DeliveryEndDate && item.DeliveryStartDate && dateService.compareDate(item.DeliveryEndDate, item.DeliveryStartDate))
                            error += $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Expiry_DateError_EndDateBeforeStartDate.Content + " pour l'échéance " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                    }

                    if ($rootScope.paramBUsePaymentDate && (!item.PaymentDate || !regexDate.test(item.PaymentDate)))
                        error += $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Expiry_PaymentDate_Error.Content + " pour l'échéance " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                }

                if ($scope.paramBActiveLinkWithCeremis && item.IsVisible && $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Option) {
                    if (!item.OptionSubscribeCost || isNaN(item.OptionSubscribeCost))
                        error += $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_ExpiryOption_SubscribeCost_Error.Content + " pour l'échéance " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                    if (!item.OptionLimitExerciceDate || !regexDate.test(item.OptionLimitExerciceDate) ||
                        (
                            item.IsVisible &&
                            dateService.compareDate1SuperiorDate2(dateService.ConvertDateToDateAPI(item.DateClosed), new Date()) &&
                            !dateService.compareDate1SuperiorDate2(dateService.ConvertDateToDateAPI(item.OptionLimitExerciceDate), new Date())
                        ))
                            error += $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_ExpiryOption_LimitExerciceDate_Error.Content + " pour l'échéance " + (!item.Label ? (index + 1) : item.Label) + "<br />";
                    if ($scope.paramBActivateModeDeltaForStrike && (!item.ListStrikeDelta || item.ListStrikeDelta.length === 0))
                        error += $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_ExpiryOption_NoStrikeSelected_Error.Content + (!item.Label ? (index + 1) : item.Label) + "<br />";
                }

                if (error) {
                    $scope.errorMessage = $sce.trustAsHtml(error);
                    deferred.resolve(false);
                }
                else {
                    deferred.resolve(true);
                }
                promises.push(deferred.promise);
            });

            var result = $q.defer();
            $q.all(promises).then(function (values) {
                $scope.validExpiries = values.filter(function (element) {
                    return element === false;
                }).length === 0 ? true : false;
                result.resolve();
            });
            return result.promise;
        };

        $scope.CheckSameTypeTemplate = function () {

            var promises = [];

            if ($scope.blankContract) {
                var deferred = $q.defer();
                deferred.resolve(true);
                promises.push(deferred.promise);
            }
            else {
                $scope.currentOffer.Templates.forEach(function (template) {
                    var deferred = $q.defer();
                    var sameTypeTemplates = $scope.currentOffer.Templates.filter(function (element) {
                        if (!$rootScope.ModuleMultipleCompagnies.Active || ($rootScope.ModuleMultipleCompagnies.Active && $scope.currentOffer.IdCompagny))
                            return element.IdTypeTemplate === template.IdTypeTemplate && element.IdLanguage == template.IdLanguage;

                        return element.IdTypeTemplate == template.IdTypeTemplate && element.IdCompagny == template.IdCompagny && element.IdLanguage == template.IdLanguage;
                    });
                    if (sameTypeTemplates.length > 1 && $scope.errorMessage === "") {
                        var error = "Vous ne pouvez pas lier les templates '";
                        sameTypeTemplates.forEach(function (item) {
                            error += item.Label;
                            if (sameTypeTemplates.indexOf(item) === sameTypeTemplates.length - 2)
                                error += "' et '";
                            else
                                error += "', '";
                        });
                        $scope.errorMessage = error.substring(0, error.length - 3);
                        if (!$rootScope.ModuleMultipleCompagnies.Active || ($rootScope.ModuleMultipleCompagnies.Active && $scope.currentOffer.IdCompagny))
                            $scope.errorMessage += " car ils ont le même type de template.";
                        else
                            $scope.errorMessage += " car ils ont le même type de template et la même société";

                        deferred.resolve(false);
                    } else
                        deferred.resolve(true);

                    promises.push(deferred.promise);
                });
            }

            var result = $q.defer();
            $q.all(promises).then(function (values) {
                $scope.validTypeTemplateNotSame = values.filter(function (element) { return element === false; }).length === 0 ? true : false;
                result.resolve();
            });
            return result.promise;
        };

        $scope.orderByExpiries = function (expiry) {
            var regexDate = /^(\d{2}\/){2}\d{4}$/;
            if (expiry.DateClosed && regexDate.test(expiry.DateClosed))
                return new Date(expiry.DateClosed);
        };

        $scope.setVisibilityOfferH24 = function () {
            $scope.currentOffer.isVisibleH24 = !$scope.currentOffer.isVisibleH24;
            $scope.currentOffer.StartVisibility = "00:00:00";
            $scope.currentOffer.EndVisibility = $scope.currentOffer.isVisibleH24 ? "23:59:59" : "00:00:00";
        };

        $scope.setPositionOfferH24 = function () {
            $scope.currentOffer.isPositionH24 = !$scope.currentOffer.isPositionH24;
            $scope.currentOffer.StartPosition = "00:00:00";
            $scope.currentOffer.EndPosition = $scope.currentOffer.isPositionH24 ? "23:59:59" : "00:00:00";
        };

        $scope.setTargetPriceOfferH24 = function () {
            $scope.currentOffer.isTargetPriceH24 = !$scope.currentOffer.isTargetPriceH24;
            $scope.currentOffer.StartTargetPrice = "00:00:00";
            $scope.currentOffer.EndTargetPrice = $scope.currentOffer.isTargetPriceH24 ? "23:59:59" : "00:00:00";
        };

        $scope.setPositionOfferBaseH24 = function () {
            $scope.currentOffer.isPositionBaseH24 = !$scope.currentOffer.isPositionBaseH24;
            $scope.currentOffer.StartPositionBase = "00:00:00";
            $scope.currentOffer.EndPositionBase = $scope.currentOffer.isPositionBaseH24 ? "23:59:59" : "00:00:00";
        };

        $scope.setTargetPriceOfferBaseH24 = function () {
            $scope.currentOffer.isTargetPriceBaseH24 = !$scope.currentOffer.isTargetPriceBaseH24;
            $scope.currentOffer.StartTargetPriceBase = "00:00:00";
            $scope.currentOffer.EndTargetPriceBase = $scope.currentOffer.isTargetPriceBaseH24 ? "23:59:59" : "00:00:00";
        };

        $scope.setPositionExerciceOptionH24 = function () {
            $scope.currentOffer.isPositionExerciceOptionH24 = !$scope.currentOffer.isPositionExerciceOptionH24;
            $scope.currentOffer.StartPositionExerciceOption = "00:00:00";
            $scope.currentOffer.EndPositionExerciceOption = $scope.currentOffer.isPositionExerciceOptionH24 ? "23:59:59" : "00:00:00";
        };

        $scope.setTargetExerciceOptionH24 = function () {
            $scope.currentOffer.isTargetExerciceOptionH24 = !$scope.currentOffer.isTargetExerciceOptionH24;
            $scope.currentOffer.StartPositionTargetExerciceOption = "00:00:00";
            $scope.currentOffer.EndPositionTargetExerciceOption = $scope.currentOffer.isTargetExerciceOptionH24 ? "23:59:59" : "00:00:00";
        };

        $scope.setAutomaticSwitchExpiry = function () {
            $scope.currentOffer.AutomaticSwitchExpiry = !$scope.currentOffer.AutomaticSwitchExpiry;
        };

        $scope.addStrike = function (expiry) {
            var strike = {
                ExerciceDate: null,
                Prime: "",
                Cost: "",
                Variation: ""
            };
            if (!expiry.Strikes)
                expiry.Strikes = [];
            expiry.Strikes.push(strike);
        };

        $scope.deleteStrike = function (offerExpiry, index) {
            $scope.errorMessage = "";
            var strikeToDelete = offerExpiry.Strikes[index];

            if (strikeToDelete.IdStrike > 0) {

                offerService.CanDeleteStrike(strikeToDelete.IdStrike, function (canDelete) {
                    if (canDelete != "true") {
                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_Delete_Strike.Content;
                        return;
                    }
                    else
                        offerExpiry.Strikes.splice(index, 1);
                });

            }
            else
                offerExpiry.Strikes.splice(index, 1);
        };

        function loadStrikeAutoVm() {
            $scope.lstOfferExpiry.forEach(function (expiry, index) {
                $scope.strikeAutoVm[index] = {};
                $scope.strikeAutoVm[index].IdExpiryWsd = expiry.IdExpiryWSD;
                $scope.strikeAutoVm[index].DateClosedExpiry = dateService.ConvertDateToDateAPI(expiry.DateClosed);
                $scope.strikeAutoVm[index].LabelExpiry = expiry.Label;
                $scope.strikeAutoVm[index].IdTypeOption = $scope.currentOffer.IdTypeOption;
                $scope.strikeAutoVm[index].GapsStrikeAutoVm = [];
                var isFirst = true;
                expiry.Strikes.forEach(function (strike, indexStrike) {
                    $scope.strikeAutoVm[index].GapsStrikeAutoVm[indexStrike] = {};
                    if (isFirst) {
                        $scope.strikeAutoVm[index].Cost = strike.Cost;
                        $scope.strikeAutoVm[index].Margin = strike.Margin;
                        isFirst = false;
                    }
                    $scope.strikeAutoVm[index].GapsStrikeAutoVm[indexStrike].ExerciceDate = strike.ExerciceDate;
                    $scope.strikeAutoVm[index].GapsStrikeAutoVm[indexStrike].Gap = strike.Gap;
                });
            });
        }

        $scope.ShowAutomaticStrike = function (openPopUp, offerExpiry, index) {
            if (!$scope.strikeAutoVm[index]) {
                $scope.strikeAutoVm[index] = {};
                $scope.strikeAutoVm[index].Cost = null;
                $scope.strikeAutoVm[index].Margin = null;
                $scope.strikeAutoVm[index].IdExpiryWsd = offerExpiry.IdExpiryWSD;
                $scope.strikeAutoVm[index].DateClosedExpiry = dateService.ConvertDateToDateAPI(offerExpiry.DateClosed);
                $scope.strikeAutoVm[index].LabelExpiry = offerExpiry.Label;
                $scope.strikeAutoVm[index].IdTypeOption = $scope.currentOffer.IdTypeOption;
                $scope.strikeAutoVm[index].GapsStrikeAutoVm = [];
            }

            $scope.indexStrikeAutoToInsert = index;

            $scope.strikeAutoErrorMessage = '';
            openPopUp();
        };

        $scope.addStrikeGap = function () {
            var strikeGap = {
                ExerciceDate: null,
                Gap: null
            };
            $scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm.push(strikeGap);
        };

        $scope.DeleteStrikeAuto = function (index) {
            $scope.errorMessage = "";
            if ($scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm && $scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm.length > 0)
                $scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm.splice(index, 1);
        };

        $scope.ChkStrikeAuto = function () {
            $scope.strikeAutoErrorMessage = '';
            if ((!$scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].Margin || !Number($scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].Margin)) && $scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].Margin !== '0') {
                $scope.strikeAutoErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_CreateOffer_PopUpStrikeAuto_Margin.Content;
                return false;
            }

            if ((!$scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].Cost || !Number($scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].Cost)) && $scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].Cost !== '0') {
                $scope.strikeAutoErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_CreateOffer_PopUpStrikeAuto_Cost.Content;
                return false;
            }

            if (!$scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm || $scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm.length === 0) {
                $scope.strikeAutoErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_CreateOffer_PopUpStrikeAuto_NoGaps.Content;
                return false;
            }

            for (var index = 0; index < $scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm.length; index++) {
                var numLine = index + 1;
                if (!$scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm[index].ExerciceDate) {
                    $scope.strikeAutoErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_CreateOffer_PopUpStrikeAuto_ExcerciceDate.Content + ' ' + numLine;
                    return false;
                }

                if ((!$scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm[index].Gap || !Number($scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm[index].Gap)) && $scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm[index].Gap !== '0') {
                    $scope.strikeAutoErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_CreateOffer_PopUpStrikeAuto_Gap.Content + ' ' + numLine;
                    return false;
                }
            }

            return true;
        };

        $scope.cancelPopUpStrikeAutoButton = function () {
            $scope.strikeAutoErrorMessage = '';
            $scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].Margin = null;
            $scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].Cost = null;
            $scope.strikeAutoVm[$scope.indexStrikeAutoToInsert].GapsStrikeAutoVm = [];
            $scope.lstOfferExpiry[$scope.indexStrikeAutoToInsert].Strikes = [];
            $scope.cancelPopUpStrikeAuto();
        };

        $scope.ValidStrikeAuto = function () {
            $scope.strikeAutoErrorMessage = '';
            offerService.GenerateStrikeAuto($scope.strikeAutoVm[$scope.indexStrikeAutoToInsert], function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.lstOfferExpiry[$scope.indexStrikeAutoToInsert].Strikes = [];
                        $scope.lstOfferExpiry[$scope.indexStrikeAutoToInsert].Strikes = data;
                    }
                }
            });
        };

        function removeExpiryClosed() {
            var dateDuJour = new Date();
            var lstOfferDuplicateTmp = angular.copy($scope.lstOfferExpiry);
            $.each(lstOfferDuplicateTmp, function (index, item) {
                var dateClosedSplitted = item.DateClosed.split('/');
                var dateClosed = new Date(dateClosedSplitted[2], dateClosedSplitted[1] - 1, dateClosedSplitted[0]);
                if (dateClosed < dateDuJour) {
                    $scope.lstOfferExpiry.splice(index, 1);
                }
            });
        }

        function loadMarkets(callbackFn) {
            // Vu qu'on ne sélectionne pas de quotation pour une offre prix construit, on affiche la liste des indexés temps réel à l'étape 2
            if ($scope.currentOffer.IdTypeQuote === enumFactory.TypeQuotes.IndexeTempsReel ) {
                wsdApiService.GetMarketsFutureAndCash(function (data, status) {
                    if (status === null) {
                        $scope.lstMarkets = data;
                        if (callbackFn)
                            callbackFn();
                    } else
                        $scope.errorMessage = status;
                });
            }
            else if ($scope.currentOffer.IdTypeQuote !== enumFactory.TypeQuotes.Fixe || $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.PrixConstruit) {
                wsdApiService.GetMarketsWSDToEuronext(function (data, status) {
                    if (status === null) {
                        $scope.lstMarkets = data;
                        if (callbackFn)
                            callbackFn();
                    }
                    else
                        $scope.errorMessage = status;
                });
            }
        }

        $scope.GetCrop = function (idCrop) {
            $scope.currentOffer.Crop = null;

            if (idCrop != null)
                if ($filter('filter')($scope.lstCrops, { IdCrop: idCrop })[0] !== undefined)
                    $scope.currentOffer.Crop = $filter('filter')($scope.lstCrops, { IdCrop: idCrop }, true)[0];
        };

        function loadAndFilterOffersCeremis() {
            if ($scope.paramBActiveLinkWithCeremis && ($scope.currentOffer.IdTypeQuote === enumFactory.TypeQuotes.IndexeTempsReel || $scope.currentOffer.IdTypeQuote === enumFactory.TypeQuotes.IndexeCoursCompensation || $scope.currentOffer.IdTypeQuote === enumFactory.TypeQuotes.Indexe3FoisParJour)) {
                $scope.GetCrop($scope.currentOffer.IdCrop);

                ceremisApiService.GetOffersCeremis($scope.currentOffer.Crop.IdExt, function (data, status) {
                    if (status === null) {
                        $scope.lstOffersCeremis = data;

                        $scope.lstmarketTmp = [];

                        $.each($scope.lstMarkets, function (index, item) {
                            if ($scope.lstOffersCeremis.indexOf($filter('filter')($scope.lstOffersCeremis, { "IdMarketWSD": Number(item.IdMarket) }, true)[0]) !== -1) {
                                $scope.lstmarketTmp.push(item);
                            }
                        });

                        $scope.lstMarkets = $scope.lstmarketTmp;
                    } else
                        $scope.errorMessage = status;
                });
            }
        }

        function loadExpiries() {
            var market = $filter('filter')($scope.lstMarkets, { IdMarket: $scope.currentOffer.IdMarket })[0];
            if ($scope.currentOffer.IdMarket) {
                //cas pour les offres ferme indéxées 
                if ($scope.currentOffer.IdTypeQuote) {
                    wsdApiService.GetExpiriesWSDByIdMarketWithQuotes($scope.currentOffer.IdMarket, $scope.currentOffer.IdTypeQuote, market.TypeLocat, function (data, status) {
                        if (status === null) {
                            $scope.lstExpiry = data.Expiries;

                            if ($scope.paramBActiveLinkWithCeremis) {
                                $scope.lstExpiryTmp = [];

                                $.each($scope.lstExpiry, function (index, item) {
                                    if ($scope.lstOffersCeremis.indexOf($filter('filter')($scope.lstOffersCeremis, { "IdExpiryWSD": Number(item.IdExpiry) }, true)[0]) !== -1) {
                                        $scope.lstExpiryTmp.push(item);
                                    }
                                });
                                $scope.lstExpiry = $scope.lstExpiryTmp;
                            }
                        } else if (status == 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                        else {
                            $scope.errorMessage = status;
                        }
                    });
                }
                //cas pour les offres d'engagement ,  engagement de surface et prix construit
                else if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Engagement || $scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.EngagementSurface) {
                    wsdApiService.GetExpiriesWSDByIdMarket($scope.currentOffer.IdMarket, function (data, status) {
                        if (status === null) {
                            $scope.lstExpiry = data.Expiries;
                        } else if (status == 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                        else {
                            $scope.errorMessage = status;
                        }
                    });
                }

                //cas pour les offre d'option
                if ($scope.currentOffer.IdTypeOffer === $scope.enumTypeOffers.Option) {
                    $scope.currentOffer.typeCalculOption = 1;
                }
            }
        }

        $scope.onFileSelect = function ($files) {
            var upload = function (i, $file) {
                $scope.fileName = '';
                $scope.percent = '';
                $scope.linkFile = '';
                $scope.errorMessage = "";
                $upload.upload({
                    url: configurationFactory.Url['Api'] + 'Upload/Upload', // webapi url
                    method: "POST",
                    params: { idAttachment: -1 },
                    file: $file
                }).progress(function (evt) {
                    // get upload percentage
                    $scope.percent = parseInt(100.0 * evt.loaded / evt.total) + '%';
                }).success(function (data) {
                    // file is uploaded successfully
                    $scope.currentOffer.IdAttachment = data.IdAttachment;
                    $scope.fileName = data.PublicLabel;
                }).error(function (data) {
                    $scope.errorMessage = data;
                });
            };

            for (var i = 0; i < $files.length; i++) {
                var $file = $files[i];
                upload(i, $file);
            }
        };

        $scope.resetFile = function () {
            $scope.errorMessage = "";
            if ($scope.currentOffer.IdOffer !== undefined || $scope.currentOffer.IdOffer !== null || $scope.currentOffer.IdOffer !== -1) {
                offerService.DeleteAttachement($scope.currentOffer.IdOffer, $scope.currentOffer.IdAttachment, function (data, status) {
                    if (status === null) {
                        $scope.fileName = '';
                        $scope.percent = '';
                        $scope.linkFile = '';
                        $scope.currentOffer.IdAttachment = null;
                    } else if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else {
                        $scope.errorMessage = status;
                    }
                });
            }
        };

        $scope.ShowStrikes = function (openPopUp, offerExpiry, index) {
            $scope.showStrikesErrorMessage = "";
            $scope.strikesInfos = [];
            $scope.showStrikesTitleByExpiryWsd = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_StrikesVisibility.Content + offerExpiry.Label;

            ceremisApiService.GetCeremisStrikes(offerExpiry.IdExpiryWSD, $scope.currentOffer.IdTypeOption, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.showStrikesErrorMessage = data[0].errors[0].Message;
                    else
                        $scope.showStrikesErrorMessage = status;
                }
                else {
                    if (data && data.length > 0 && data !== "null") {
                        $scope.strikesInfos = data[0].ListStrikeInfoVm;

                        if ($scope.paramBActivateModeDeltaForStrike)
                            manageCheckBoxStrikeForDelta(offerExpiry, index);
                    }
                    else
                        $scope.showStrikesErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_NoStrikeAvailable.Content + offerExpiry.Label;
                }
                openPopUp();
            });
        };

        function manageCheckBoxStrikeForDelta(offerExpiry, index) {
            $scope.currentIndexForStrikeDelta = index;
            $scope.strikeDeltaPopUp = [];
            var strikeToTheMoney =  $scope.strikesInfos.filter(function (element) { return element.IsStrikeToTheMoney === true; })[0];
            if (strikeToTheMoney)
            {
                $scope.currentStrikeToTheMoneyForStrikeDelta = strikeToTheMoney.Strike;
                $scope.strikesInfos.forEach(function (strikeinfoVm) {
                    var deltaCurrentStrikeAndStrikeToTheMoney = strikeinfoVm.Strike - strikeToTheMoney.Strike;
                    strikeinfoVm.deltaValue = deltaCurrentStrikeAndStrikeToTheMoney;
                    var indexInOfferExpiryListStrikeDelta = offerExpiry.ListStrikeDelta ? offerExpiry.ListStrikeDelta.indexOf(deltaCurrentStrikeAndStrikeToTheMoney) : -1;
                    if (strikeinfoVm.Strike == strikeToTheMoney.Strike || indexInOfferExpiryListStrikeDelta >= 0) {
                        $scope.SelectStrikeForDelta(deltaCurrentStrikeAndStrikeToTheMoney);
                    }
                });
            }
            else
                $scope.showStrikesErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_NoStrikeToTheMoneyAvailable.Content + offerExpiry.Label;
        }
  
        $scope.SelectStrikeForDelta = function (deltaValue) {
            var index = $scope.strikeDeltaPopUp.indexOf(deltaValue);
            if (index >= 0) {
                $scope.strikeDeltaPopUp.splice(index, 1);
            } else {
                $scope.strikeDeltaPopUp.push(deltaValue);
            }
        };

        $scope.confirmPopUpStrikes = function () {
            $scope.lstOfferExpiry[$scope.currentIndexForStrikeDelta].ListStrikeDelta = [];
            $scope.strikeDeltaPopUp.forEach(function (strikeDelta) {
                $scope.lstOfferExpiry[$scope.currentIndexForStrikeDelta].ListStrikeDelta.push(strikeDelta);
            });
            $scope.closePopUpStrikes();
        };

        $scope.init();
    }]);
