
//////////////////////////////////////////////////////////////
//                    DESTECTION USER AGENT                 //
//////////////////////////////////////////////////////////////
$(document).ready(function () {
    var doc = document.documentElement;
    doc.setAttribute('data-useragent', navigator.userAgent);
   
});

//////////////////////////////////////////////////////////////
//                       MENU PRINCIPAL                     //
//////////////////////////////////////////////////////////////

function setLink(i) {
    var first = $('#elemNav-' + i + '>a').attr('href');
    var href = $('#ssNav-' + i + ' li:nth-child(2) a').attr("href");

    if (href && !first) {
        $('#elemNav-' + i + '>a').attr('href', href);
        $('#ssNav-' + i + ' li:first-child a').attr('href', href);
        window.location = href;
    }
}

function menu(style) {

    var $nav = $('#primary_nav');
    var $subNav = $('#primary_nav li .sub_nav');
    var $elemNav = $('#primary_nav ul.menu > li');
    var $elemSsNav = $('#primary_nav li ul');
    var $mainContainer = $('#wrap');
    var windowHeight = $(window).height();
    var deviceAgent = navigator.userAgent.toLowerCase();

    var isTouchDevice = Modernizr.touch &&
    (deviceAgent.match(/(iphone|ipod|ipad)/) ||
    deviceAgent.match(/(android)/) ||
    deviceAgent.match(/(iemobile)/) ||
    deviceAgent.match(/iphone/i) ||
    deviceAgent.match(/ipad/i) ||
    deviceAgent.match(/ipod/i) ||
    deviceAgent.match(/blackberry/i) ||
    deviceAgent.match(/bada/i));

    $("#primary_nav, #main_header").show();

    if (style === 'vertical') {
        horizontalSite = false;
        var $bt = $nav.find('.deploy');
        var $deployNav = $('.ico-deploy');
        var $heightArray = new Array();
        var menuWidthSsNav = 205;
        var menuWidthDefault = 45;
        var menuWidth = 250;
        var containerMarge = menuWidth - menuWidthDefault;
        var $navPosTop = $nav.css('top') ? parseInt($nav.css('top').replace('px', '')) : null;

        initVertical();

        //////////////////////////////////////////////////////////////
        //              GESTION DU DEPLOIEMENT DU MENU              //
        //////////////////////////////////////////////////////////////

        // Deploy navigation
        $bt.unbind('click');
        $bt.click(function () {
            if ($nav.width() == menuWidth) {
                undeployNav();
            } else {
                deployNav();
            };
        });

        // Action pour déployer la sous-navigation
        $deployNav.unbind('click');
        $deployNav.click(function () {
            var id = parseInt(this.parentElement.id.replace('elemNav-', ''));
            if ($('#ssNav-' + id).is(':visible')) {
                hideSsNav(id);
            } else {
                showSsNav(id);
            }

        });


        //////////////////////////////////////////////////////////////
        //              GESTION DU SCROLL SUR LE MENU               //
        //////////////////////////////////////////////////////////////
        navHeight = $nav.outerHeight();

        $(window).resize(function () {
            menuScroll();
        });
        $(window).scroll(function () {
            menuScroll();
        });
    }
    else if (style === 'horizontal') {
        horizontalSite = true;
        var $elemNavWidth = $elemNav.outerWidth();
        var widthMenu = $elemNavWidth * $elemNav.length;
        var maxWidth = $(window).width() - parseInt($('#primary_nav ul.menu').css('margin-left').replace('px', ''));
        var deviceWidth = maxWidth - $('.subMenuPrimary_button').outerWidth();
        //console.log(maxWidth,deviceWidth);
        if ($nav.attr('data-menu') === undefined && $elemNav.length !== 0) {

            initHorizontal(deviceWidth);

            $(window).bind('resize', function (e) {
                window.resizeEvtMenu = null;
                $(window).resize(function () {
                    clearTimeout(window.resizeEvtMenu);
                    window.resizeEvtMenu = setTimeout(function () {
                        deviceWidth = ($(window).width() - parseInt($('#primary_nav ul.menu').css('margin-left').replace('px', ''))) - $('.subMenuPrimary_button').outerWidth();
                        initHorizontal(deviceWidth);
                    }, 250);
                });
            });

            $nav.attr('data-menu', 'false');
        }

    }
    else if (style === 'updated') {
        horizontalSite = false;
        var $bt = $nav.find('.deploy');
        var $deployNav = $('.subnav_deploy');
        var $heightArray = new Array();
        var menuWidthSsNav = 205;
        var menuWidthDefault = 60;
        var menuWidth = 250;
        var containerMarge = menuWidth - menuWidthDefault;
        var $navPosTop = $nav.css('top') ? parseInt($nav.css('top').replace('px', '')) : null;

        initVertical();

        //////////////////////////////////////////////////////////////
        //              GESTION DU DEPLOIEMENT DU MENU              //
        //////////////////////////////////////////////////////////////

        // Deploy navigation
        $bt.unbind('click');
        $bt.click(function () {
            if ($nav.width() == menuWidth) {
                undeployNav('updated');
                $(".hamburger-toggle").removeClass('is-opened');
            } else {
                deployNav('updated');
                $(".hamburger-toggle").addClass('is-opened');
            };
        });

        // Action pour déployer la sous-navigation
        $deployNav.unbind('click');
        $deployNav.click(function () {
            $(this).toggleClass("is-open");
            var id = parseInt(this.parentElement.id.replace('elemNav-', ''));
            if ($('#ssNav-' + id).is(':visible')) {
                hideSsNav(id);
            } else {
                showSsNav(id);
            }

        });


        //////////////////////////////////////////////////////////////
        //              GESTION DU SCROLL SUR LE MENU               //
        //////////////////////////////////////////////////////////////
        navHeight = $nav.outerHeight();

        $(window).resize(function () {
            menuScroll();
        });
        $(window).scroll(function () {
            menuScroll();
        });
    }
    function menuScroll() {
        $nav.css({
            'position': 'relative',
        });
        scrollTop = $(this).scrollTop();
        navHeight = $nav.outerHeight();
        containerHeight = $('#wrap').outerHeight();
        windowHeight = $(window).height();

        $nav.css({
            'position': '',
        });

        if (navHeight < windowHeight) {

            $nav.css({
                'position': 'fixed',
                'top': $navPosTop,
                'bottom': 0,
                'z-index': ''
            });

        } else if (navHeight + $navPosTop > containerHeight) {

            $nav.css({
                'position': 'relative',
                'z-index': 190,
                'bottom': 'inherit',
                'top': 'inherit'
            });
            if (scrollTop === 0) {
                $nav.css({
                    'top': $navPosTop,
                    'z-index': ''
                });
            }

        } else {
            var ratio = (navHeight + $navPosTop) - windowHeight;
            var navInv = (windowHeight - navHeight);

            $nav.css({
                'position': 'relative',
                'top': $navPosTop,
                'bottom': 'inherit',
                'z-index': 190
            });

            if (scrollTop === 0) {
                $nav.css({
                    'position': 'relative',
                    'top': $navPosTop,
                    'z-index': ''
                });
            }

            if (scrollTop >= ratio) {
                $nav.css({
                    'position': 'fixed',
                    'top': 'inherit',
                    'bottom': 0,
                    'z-index': 190
                });
            }
        }
    }

    //////////////////////////////////////////////////////////////
    //               GESTION DU ROLLOVER DU MENU                //
    //////////////////////////////////////////////////////////////

    $elemNav.bind({
        mouseenter: function () {
            if ($nav.width() == menuWidthDefault) { // On vérifie que le menu n'est pas déployé
                var id = parseInt(this.id.replace('elemNav-', ''));

                if (!isTouchDevice) {
                    showNav(id);
                }
            }
        },
        mouseleave: function () {
            if ($nav.width() == menuWidthDefault) { // On vérifie que le menu n'est pas déployé
                var id = parseInt(this.id.replace('elemNav-', ''));

                hideNav(id);
            }
        }
    });


    // Initialisation
    function initVertical() {

        //Insertion des ID
        $elemNav.each(function (i) {
            this.id = 'elemNav-' + i;
        });

        $deployNav.each(function (i) {
            this.id = 'deployNav-' + i;
        });

        $elemSsNav.each(function (i) {
            this.id = 'ssNav-' + i;
            i++;
        });

        calcHeight();

        $elemSsNav.each(function (i) {

            var elem = $(this).find('.title');

            // On teste l'existence du titre du menu
            if (elem.length === 0) {

                var titleNav = $('#elemNav-' + i + ' .name').html();
                var titleLink = $('#elemNav-' + i + ' a').attr('href');
                var target = $('#elemNav-' + i + ' a').attr('target');
                if (titleLink) {
                    $(this).css({ 'overflow': 'hidden', 'width': 0, 'display': 'block' })
                           .prepend('<li class="title"><a ' + (target ? 'target="' + target + '"' : '') + ' href="' + titleLink + '">' + titleNav + '</a></li>');
                } else {
                    $(this).css({ 'overflow': 'hidden', 'width': 0, 'display': 'block' })
                           .prepend('<li class="title"><a onClick="setLink(' + i + ');">' + titleNav + '</a></li>');
                }
            }

            i++;
        });

        // On ajoute la classe par défaut
        $nav.addClass('undeployed');

        // Masque la sous-navigation
        //$subNav.hide();

        // Masque la navigation par défaut
        undeployNav();
        
        menuScroll();
    }

    function initHorizontal(deviceWidth) {

        if (widthMenu > deviceWidth) {
            $nav.find('.menu').hide();

            if ($('.subMenuPrimary_button').length === 0) {
                $nav.append('<span class="subMenuPrimary_button">Menu<span></span></span>');
            }
            $nav.find('.menu').addClass('responsivePrimaryNav');
            $('#wrap').addClass('isresponsivePrimaryNav');

            /*$('.responsivePrimaryNav').css({
                'height': $(window).height() - ($nav.outerHeight() + $('#main_header').outerHeight())
            });*/

            $('.subMenuPrimary_button').css({
                'position': 'absolute'
            });


            $('.subMenuPrimary_button').unbind('click');
            $('.subMenuPrimary_button').bind('click', function () {
                showMenuHor();
            });

            $('.responsivePrimaryNav').find('a').unbind('click');
            $('.responsivePrimaryNav').find('a').bind('click', function (e) {
                showSubMenuHor(e, $(this));
            });

        } else {
            // $('.responsivePrimaryNav').css({
            //     'height': 'auto'
            // });
            $nav.find('.menu').removeClass('responsivePrimaryNav');
            $('#wrap').removeClass('isresponsivePrimaryNav');

            $nav.find('.sub_nav').css({ 'display': '' });
            $nav.find('.menu').show();
            $('.subMenuPrimary_button').remove();
        }
    }

    function calcHeight() {
        //Récupération et insertion des différentes height
        $heightArray = [];
        for (var i = 0; i < $elemSsNav.length; i++) {
            $('#ssNav-' + i).find('li.title').hide();
            var $heightElem = $('#ssNav-' + i).outerHeight(true);
            $heightArray.push($heightElem);
            $('#ssNav-' + i).find('li.title').show();
        }

    }

    // Déploiement du menu
    function deployNav(style) {
        if (!style)
            style = '';
        //On affiche la version
        $nav.find(".version").show();

        $subNav.find('li.title').hide();
        $subNav.removeAttr('style');
        $nav.removeClass('undeployed');
        $nav.addClass('deployed');

        $nav.animate({ width: menuWidth }, 350, 'easeOutQuad'
			, function () {
			    if ($nav.hasClass('deployed')) {
			        $('.name').css('display', 'inline-block')
                              .animate({ 'opacity': 1 }, 100);

			        $deployNav.css('display', 'inherit')
                              .animate({ 'opacity': 1 }, 100);
			    }
            });
        $mainContainer.animate({ 'padding-left': containerMarge }, 350, 'easeOutQuad');

        
        if($("#container.iframe-skin3")[0]){
            $("#container.iframe-skin3")[0].classList.add("menuDeployed");
        }

        if (style == 'updated') {
            $("#main_header").css('width', 'calc(100% - ' + menuWidth + 'px)');
            $(".hamburger-toggle").removeClass('is-opened');
        }
    }

    function showMenuHor() {

        if ($nav.find('.menu').is(':visible')) {
            $nav.find('.menu').hide();
            $('.subMenuPrimary_button').removeClass('active');
        } else {
            $nav.find('.menu').slideDown();
            $('.subMenuPrimary_button').addClass('active');
        }
    }

    function showSubMenuHor(e, el) {
        el = el.parent('li');

        if (el.find('.sub_nav li').length !== 0 && $nav.find('.menu').hasClass('responsivePrimaryNav')) {
            e.preventDefault();
            if (el.find('.sub_nav').is(':visible')) {
                el.find('.sub_nav').css({ 'display': '' });
                $(this).removeClass('active');
            } else {
                el.find('.sub_nav').slideDown();
                $(this).addClass('active');
            }
        }

    }

    function undeployNav(style) {
        if (!style)
            style = '';
        //on cache la version
        $nav.find(".version").hide();

        $subNav.find('li.title').css({ 'overflow': 'hidden', 'width': 0, 'display': 'block' });
        // On réinitialise à 0 les styles
        $subNav.removeAttr('style');

        // On masque la ou les sous-navigations restés ouvertes
        $($elemSsNav).hide();
        $('.name').animate({ 'opacity': 0 }, 200)
   				  .css('display', 'none');
        $deployNav.animate({ 'opacity': 0 }, 200)
   				  .css('display', 'none');
        $mainContainer.animate({ 'padding-left': 0 }, 200, 'easeInOutCubic');
      
        if($("#container.iframe-skin3.menuDeployed")[0]){
            $("#container.iframe-skin3.menuDeployed")[0].classList.remove("menuDeployed");
        }

        $nav.animate({ width: menuWidthDefault }, 200, 'easeInOutCubic')
			.removeClass('deployed')
			.addClass('undeployed');
        if (style == 'updated') {
            $deployNav.removeClass('current');
            $("#main_header").css('width', 'calc(100% - ' + menuWidthDefault + 'px)');
        }
    }

    // Rollover menu (menu non-déployé)
    function showNav(id) {
        // On réinitialise à 0 les styles
        $('#ssNav-' + id).removeAttr('style');

        $('#ssNav-' + id + ' li').css({ 'overflow': 'hidden', 'width': menuWidthSsNav, 'display': 'block' });

        var elemPos = ($('#ssNav-' + id).offset().top) - $(window).scrollTop();
        var elemHeight = $('#ssNav-' + id).outerHeight();
        var windowHeight = $(window).height();
        var ratio = windowHeight - elemPos;

        $('#ssNav-' + id).animate({ 'width': menuWidthSsNav }, 0, 'easeOutExpo');
        $('#elemNav-' + id).addClass('elemHover');

        if (ratio < elemHeight) {

            $('#ssNav-' + id).css({
                'top': 'inherit',
                'bottom': 0
            });
        }
    }

    function hideNav(id) {

        $('#elemNav-' + id).removeClass('elemHover').removeAttr('style');
        $('#ssNav-' + id).css({ 'overflow': 'hidden', 'width': 0, 'display': 'block' });
        $('#ssNav-' + id + ' li').removeAttr('style');
        $('#ssNav-' + id).css({ 'display': 'none' });
        //$('#ssNav-' + id + ' li.title').remove();
    }

    // Visibilité ssNav (menu déployé)
    function showSsNav(id) {
        calcHeight();
        $('.sub_nav').find('li.title').hide();
        // On réinitialise à 0 les styles
        $('#ssNav-' + id).removeAttr('style');

        $('#ssNav-' + id).css('height', 0).show();
        $('#ssNav-' + id).animate({ 'height': $heightArray[id] }, 200);
        $('#elemNav-' + id + ' .ico-deploy').addClass('current');
        // On réinitialise à 0 les styles
        //$subNav.removeAttr('style');
    }

    function hideSsNav(id) {

        $('#ssNav-' + id).animate({ 'height': 0 }, 200, function () {
            $('#ssNav-' + id).hide().removeAttr("style");
        });

        $('#elemNav-' + id + ' .ico-deploy').removeClass('current');
    }

}

//////////////////////////////////////////////////////////////
//           GESTION DU DRAG&DROP => DASHBOARD              //
//////////////////////////////////////////////////////////////
function initDragDrop() {

    var container = $('#draggable').width();
    var marginBloc = $('#draggable section').css('margin-left').replace('px', '');

    var tableSizes = new Array();

    /* GESTION DU DRAG&DROP */
    $('#draggable').sortable({
        handle: 'header',
        helper: "clone",
        connectWith: '#draggable',
        placeholder: 'portlet-placeholder',
        update: function (event, ui) {
            var listIds = $(this).sortable('toArray').toString();
        },
        activate: function (event, ui) {
            // Permet de définir les dimensions du placeholder
            var itemSize = ui.item.attr('data-size');

            if (itemSize == 0) {
                $('.portlet-placeholder').addClass('cols_4');
            } else {
                $('.portlet-placeholder').removeClass('cols_4').addClass('cols_8');
            }
        }
    });

    /* INIT DE LA WIDTH + ID ELEM */
    $('#draggable section').each(function (i) {
        // On attribue un id à chaque élément
        $(this).attr('id', i);

        var itemSize = parseInt($(this).attr('data-size'));

        // On attribue une width aux éléments selon leur taille par défaut
        if (itemSize == 0) {
            $(this).addClass('cols_4');

        } else {
            $(this).removeClass('cols_4').addClass('cols_8')
            .find('.tools .size').addClass('ico-resize-min').removeClass('ico-resize');

        }
    });
}
//////////////////////////////////////////////////////////////
//        GESTION DES COLONNES FIXES DES TABLEAUX           //
//////////////////////////////////////////////////////////////
function userMenu(link, nav) {
    var $link = $(link);
    var $nav = $(nav);
    var $main = $('body');
    var $mainNav = $('#primary_nav');
    var $mainHeader = $('#main_header');

    if ($nav.length > 0) {
        $nav.hide();

        $link.unbind();
        $link.bind('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            if ($nav.is(':visible')) {
                $nav.slideUp();
            } else {
                $nav.slideDown();

                $(document).one('click', function closeTooltip(e) {

                    if ($nav.has(e.target).length === 0) {
                        $nav.slideUp();
                    } else if ($nav.is(':visible')) {
                        $(document).one('click', closeTooltip);
                    }
                });
            }

        });

    }
}

function moveVehicle() {
    setTimeout(
        function () {
            var currentDistance = $("[class^='breadcrumb'] .current").position().left + ($("[class^='breadcrumb'] .current").width() / 2) - $('.icofont-vehicle-trucktor').width() + 10;
            $('.icofont-vehicle-trucktor').css("left", "" + currentDistance + "px");
        }
    , 200);
}



//////////////////////////////////////////////////////////////
//        Carousel page dashboard                           //
//////////////////////////////////////////////////////////////
function dashboardCarousel() {
    var largeCarousel = $(".bloc_carousel").hasClass("cols_8");
    var owl = $('.owl-carousel');
    

    owl.owlCarousel({
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 8000,
        autoplaySpeed: 1000,
        center: true,
        items: 1.4,
        stagePadding: largeCarousel ? 200 : 50,
        loop: true,
        margin: 15,
        animateOut: 'slide-up',
        animateIn: 'slide-down',
        nav: false,
        dots: true,
        mouseDrag: true,
        touchDrag: true,
        //navText: [
        //    '<i class="icofont-rounded-left"></i>',
        //    '<i class="icofont-rounded-right"></i>'
        //],
        
    });

    if ($('.owl-dot').length > 5) {
        owl.addClass('customDotsPosition');

        var position = ($('.owl-dot.active').position().left - $('.owl-carousel').width() / 2 + 11) * -1;
        $('.owl-dots').css('left', position);

        owl.on('changed.owl.carousel', function (event) {
            dotsPosition();
        })
    }
}

function dotsPosition() {
    var position = ($('.owl-dot.active').position().left - $('.owl-carousel').width() / 2 + 11) * -1;
    $('.owl-dots').css('left', position);
}

function refreshCarousel() {
    setTimeout(function () {
        var largeCarousel = $(".bloc_carousel").hasClass("cols_8");

        var options = $('.owl-carousel').data('owl.carousel').options;
        options.stagePadding = largeCarousel ? 200 : 50;

        $('.owl-carousel').trigger('refresh.owl.carousel');
    }, 200)
};


//////////////////////////////////////////////////////////////
//        GESTION DES COLONNES FIXES DES TABLEAUX           // - 
//////////////////////////////////////////////////////////////

//function columnFixer(colFix1,colFix2) {
//	var nbTable = $('table').length;
//	var nbColumn = $('th').length;
//	var nbLine = $('tr').length;

//	var $thFixed1 = $('th:eq(' + (parseInt(colFix1) - 1) + ')');
//	var $thFixed2 = $('th:eq(' + (parseInt(colFix2) - 1) + ')');
//	var $tdFixed1 = $('td:eq(' + (parseInt(colFix1) - 1) + ')');
//	var $tdFixed2 = $('td:eq(' + (parseInt(colFix2) - 1) + ')');

//	var $thFixed1Width = $thFixed1.outerWidth(true);
//	var $thFixed2Width = $thFixed2.outerWidth(true);
//	var $thFixed1Height = $thFixed1.outerHeight(true);
//	var $thFixed2Height = $thFixed2.outerHeight(true);

//	var tableWidth = $('table').outerWidth(true);




//	// J'encadre le tableau d'un conteneur
//	$('table').wrap('<div class="tableContainer"></div>');

//	// Je créé les conteneurs de la colonne 1 et 2
//	$('.tableContainer').parent().append('<div class="firstColumnContainer"><table><thead></thead><tbody></tbody></table></div>');
//	$('.tableContainer').parent().append('<div class="secondColumnContainer"><table><thead></thead><tbody></tbody></table></div>');

//	// Je duplique les colonnes dans leur conteneur correspondant
//	var $thFixed1Content = $thFixed1.html();
//	var $thFixed2Content = $thFixed2.html();
//	var $tdFixed1Content = $tdFixed1.html();
//	var $tdFixed2Content = $tdFixed2.html();

//	//console.log($thFixed1Content);
//	//console.log($thFixed2Content);
//	//console.log($tdFixed1Content);
//	//console.log($tdFixed2Content);

//	$('<tr></tr>').appendTo('.firstColumnContainer thead');
//	$('.firstColumnContainer').css({ 'width': $thFixed1Width, 'height': $thFixed1Height });
//	$thFixed1.clone().appendTo('.firstColumnContainer thead tr');
//	//$tdFixed1.clone().appendTo('.firstColumnContainer tbody');

//	$('<tr></tr>').appendTo('.secondColumnContainer thead');
//	$('.secondColumnContainer').css({ 'width': $thFixed2Width, 'height': $thFixed2Height });
//	$thFixed2.clone().appendTo('.secondColumnContainer thead tr');
//	//$tdFixed2.clone().appendTo('.secondColumnContainer tbody');

//	$('.tableContainer tbody tr').each(function (i) {
//		$('<tr></tr>').appendTo('.firstColumnContainer tbody');
//		$('<tr></tr>').appendTo('.secondColumnContainer tbody');

//		$(this).find($tdFixed1).clone().appendTo('.firstColumnContainer tbody tr:last-child');
//		$(this).find($tdFixed2).clone().appendTo('.secondColumnContainer tbody tr:last-child');
//	});
//}