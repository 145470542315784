AgrimarketControllers.controller('contentsController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'contentService', 'currentUserFactory', 'configurationFactory', '$log', '$timeout', 'enumFactory',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, contentService, currentUserFactory, configurationFactory, $log, $timeout, enumFactory) {

        $scope.errorMessage = "";
        $scope.successMessage = "";
        $scope.profileOption = [];
        $scope.profileOptionGroupUser = [];
        $scope.loading = false;
        $scope.isGroupUser = false;
        $scope.isUserRole = false;

        var GetProfiles = function () {
            contentService.GetProfiles(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    if (data !== undefined && data !== null) {
                        $scope.profileOption = data.ProfilesList;
                        $scope.profileOptionGroupUser = data.ProfilesGroupUser;

                    } else {
                        $scope.errorMessage = "Aucun profil n'a été trouvé en base de données";
                    }
                }
            });
        };

        var GetContentGroupLabel = function (item) {
            var groupContactLabelFind;
            if ($scope.profileOptionGroupUser.length > 0)
                $scope.profileOptionGroupUser.forEach(function (groupContact) {
                    if (groupContact.Value == item)
                        groupContactLabelFind = groupContact.Name;
                });
            return groupContactLabelFind;
        };

        $scope.profilSelected = 0;
        $scope.profilDico = "Gnrl_" + 0;
        var initMenu = function () {
            getListIcoName();
            $scope.menu = {};
            $scope.menu.IdMenu = -1;
            $scope.menu.IdProfile = -1;
            $scope.menu.IcoName = "";
            $scope.menu.Pages = [];
            $scope.action = "Ajouter";
            $scope.Title = "Ajouter un menu";
            $scope.LabelButton = "Ajouter";
            initMenuMessage();
            $scope.indexSelected = null;
        };

        var initMenuMessage = function () {
            $scope.errorMenuMessage = "";
            $scope.successMenuMessage = "";
            $scope.errorMenuMessageHtml = "";
            $scope.errorMessage = "";
        };

        var init = function () {
            initMenu();
            GetProfiles();
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.profilSelected = 0;
            $scope.profilDico = "Gnrl_" + 0;
            if ($rootScope.resetMenu === undefined) { // si on c'est la première fois qu'on arrive sur la page
                $rootScope.contents = [];
                contentService.GetContents(function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        if (data !== undefined && data !== null) {
                            $rootScope.contents = data;
                            reloadContent();
                            $rootScope.showDetails = [];

                            contentService.LoadAllGroupContact(function (dataGp, statusGp) {
                                if (statusGp !== null) {
                                    if (statusGp === 400)
                                        $scope.errorMessage = $sce.trustAsHtml(dataGp[0].errors[0].Message);
                                } else {
                                    var groupContactToSearch = dataGp;
                                    $rootScope.contents.forEach(function (item, index) {
                                        var isGroupcontactLabel = false;
                                        //Menu Item
                                        if (item.IdGroupContacts && item.IdRoles) {
                                            if ((item.IdGroupContacts.length === 0 && item.IdRoles.length === 0) || item.IdGroupContacts.length > 1 || item.IdRoles.length > 1) {
                                                $rootScope.contents[index].ProfilLabel = "Tous";
                                            } else {
                                                groupContactToSearch.forEach(function (itemGp) {
                                                    if (itemGp.IdGroupContact == item.IdGroupContacts[0]) {
                                                        $rootScope.contents[index].ProfilLabel = itemGp.FullName;
                                                        isGroupcontactLabel = true;
                                                    }
                                                });
                                                if (!isGroupcontactLabel) {
                                                    if (item.IdRoles[0] == enumFactory.Roles.User) {
                                                        $rootScope.contents[index].ProfilLabel = "Agriculteur";
                                                    } else {
                                                        $rootScope.contents[index].ProfilLabel = "TC";
                                                    }
                                                }
                                            }
                                        }
                                        //Page Item
                                        if (item.Pages && item.Pages.length > 0){
                                            item.Pages.forEach(function (itemPage, indexPage) {
                                                isGroupcontactLabel = false;
                                                if (itemPage.IdRoles && itemPage.IdGroupContacts) {
                                                    if ((itemPage.IdGroupContacts.length === 0 && itemPage.IdRoles.length === 0) || itemPage.IdGroupContacts.length > 1 || itemPage.IdRoles.length > 1)
                                                        $rootScope.contents[index].Pages[indexPage].ProfilLabel = "Tous";
                                                    else {
                                                        groupContactToSearch.forEach(function (itemPageGp) {
                                                            if (itemPageGp.IdGroupContact == itemPage.IdGroupContacts[0]) {
                                                                $rootScope.contents[index].Pages[indexPage].ProfilLabel = itemPageGp.FullName;
                                                                isGroupcontactLabel = true;
                                                            }
                                                        });
                                                        if (!isGroupcontactLabel) {
                                                            if (itemPage.IdRoles[0] == enumFactory.Roles.User)
                                                                $rootScope.contents[index].Pages[indexPage].ProfilLabel = "Agriculteur";
                                                            else {
                                                                $rootScope.contents[index].Pages[indexPage].ProfilLabel = "TC";
                                                            }
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                        
                                        $rootScope.showDetails[index] = false;
                                    });
                                }
                            });
                        }
                    }
                });
            } else {
                $rootScope.resetMenu = undefined;
            }
        };

        var reloadContent = function () {
            if ($rootScope.contents !== undefined && $rootScope.contents !== null) {
                $rootScope.contents = $filter('orderBy')($rootScope.contents, "Position");

                $rootScope.contents.forEach(function (item) {
                    item.Pages = $filter('orderBy')(item.Pages, "Position");
                });
            }
        };

        $scope.ChangeHomeDefault = function (indexMenu, indexPage) {
            $rootScope.contents[indexMenu].Pages.forEach(function (page, index) {
                if (index == indexPage) {
                    page.HomeDefault = true;
                } else {
                    page.HomeDefault = false;
                }
            });
        };

        $scope.ChangePosition = function (positionarray) {
            if (positionarray.End == positionarray.Start)
                return false;

            var result = angular.copy($rootScope.contents);
            var detail = [];

            positionarray.End.forEach(function (item, indexM) {
                var menuPos = JSON.parse(item);
                result[menuPos.menuId].Position = indexM + 1;
                detail[indexM] = $rootScope.showDetails[menuPos.menuId];

                result[menuPos.menuId].Pages = [];

                if (menuPos.children !== undefined && menuPos.children !== null) {
                    menuPos.children.forEach(function (pagePos, indexP) {
                        var separator = pagePos.pageId.indexOf('_');
                        var indexPage = parseInt(pagePos.pageId.substring(separator + 1));
                        var indexMenu = parseInt(pagePos.pageId.substring(0, separator));
                        var page = angular.copy($rootScope.contents[indexMenu].Pages[indexPage]);
                        page.Position = indexP + 1;

                        //on doit decocher la page par defaut si celle-ci change de menu afin d'avoir toujours une seule  page par defaut par menu
                        if (indexMenu != menuPos.menuId) {
                            page.HomeDefault = false;
                        }

                        result[menuPos.menuId].Pages.push(page);
                    });
                }
            });

            $rootScope.contents = result;
            $rootScope.showDetails = detail;
            reloadContent();
            $scope.$apply();

            return true;
        };

        $scope.SavePosition = function () {
            $scope.loading = true;
            initMenuMessage();
            $scope.errorMessage = "";
            $scope.successMessage = "";
            contentService.SavePosition($rootScope.contents, function (data, status) {
                if (status !== null) {
                    $scope.loading = false;
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.loading = false;
                    $scope.successMessage = "Modifications enregistrées";
                }
            });
        };

        $scope.ChangeIcoName = function (index, icoNameOv) {
            $scope.menu.IcoName = icoNameOv;
            $rootScope.listIcoName.forEach(function (item, indexIco) {
                if (index == indexIco) {
                    item.IsChecked = true;
                } else {
                    item.IsChecked = false;
                }
            });
        };

        $scope.UpdateRoleAndGroupUser = function () {
            $scope.menu.IdRoles = [];
            $scope.menu.IdGroupContacts = [];
            $scope.menu.ProfilLabel = $filter('filter')($scope.profileOption, { Value: $scope.menu.IdProfile }, true)[0].Name;
            if ($scope.menu.IdProfile === "Gnrl_" + 0) {
                $scope.menu.IdRoles.push(0);
                $scope.menu.IdGroupContacts.push(0);
            } else {
                if ($scope.menu.IdProfile == "User_" + enumFactory.Roles.User)
                    $scope.menu.IdRoles.push(enumFactory.Roles.User);
                else {
                    if ($scope.menu.IdProfile == "User_" + enumFactory.Roles.TC)
                        $scope.menu.IdRoles.push(enumFactory.Roles.TC);
                    else
                        $scope.menu.IdGroupContacts.push(parseInt($scope.menu.IdProfile.split("_")[1]));
                }
            }
        };

        var getListIcoName = function () {
            $rootScope.listIcoName = [];
            $rootScope.listIcoName.push({ Num: 1, IcoName: "ico-dashboard", IcoNameOver: "ico-dashboard", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 2, IcoName: "ico-gear", IcoNameOver: "ico-gear", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 3, IcoName: "ico-labels", IcoNameOver: "ico-offers", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 4, IcoName: "ico-calculator", IcoNameOver: "ico-simulation", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 5, IcoName: "ico-help", IcoNameOver: "ico-help", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 6, IcoName: "ico-edition", IcoNameOver: "ico-edition-mode", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 7, IcoName: "ico-contracts", IcoNameOver: "ico-contracts", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 8, IcoName: "ico-content-edit", IcoNameOver: "ico-edition-contenu", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 9, IcoName: "ico-commitments", IcoNameOver: "icon-commitments", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 10, IcoName: "ico-simple-group-2", IcoNameOver: "ico-benchmark", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 11, IcoName: "ico-alert", IcoNameOver: "ico-alerts", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 12, IcoName: "ico-card", IcoNameOver: "ico-agri", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 13, IcoName: "ico-quit", IcoNameOver: "ico-deconnection", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 14, IcoName: "ico-person", IcoNameOver: "ico-admin", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 15, IcoName: "ico-check-2", IcoNameOver: "ico-check", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 16, IcoName: "ico-commodities", IcoNameOver: "ico-commodities", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 17, IcoName: "ico-customers", IcoNameOver: "ico-customers", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 18, IcoName: "ico-talk", IcoNameOver: "ico-dialog", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 19, IcoName: "ico-favicon", IcoNameOver: "ico-favorites", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 20, IcoName: "ico-graf-3", IcoNameOver: "ico-graf", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 21, IcoName: "ico-graf", IcoNameOver: "ico-graf2", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 22, IcoName: "ico-info", IcoNameOver: "ico-info", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 23, IcoName: "ico-alert-3", IcoNameOver: "ico-warning", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 24, IcoName: "ico-packet", IcoNameOver: "ico-appros", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 25, IcoName: "ico-graf-2", IcoNameOver: "ico-quotes", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 26, IcoName: "ico-euro", IcoNameOver: "ico-comptes", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 27, IcoName: "ico-grape-leaf", IcoNameOver: "ico-grape-leaf", IsChecked: false });
            $rootScope.listIcoName.push({ Num: 28, IcoName: "ico-weather", IcoNameOver: "ico-weather", IsChecked: false });

            if ($rootScope.paramIsSynoaCoop) {
                $rootScope.listIcoName.push({ Num: 29, IcoName: "ico-synoa", IcoNameOver: "ico-synoa", IsChecked: false });
            }
        };

        $scope.cancelMenu = function () {
            initMenu();
            $scope.errorMessage = "";
            $scope.successMessage = "";
        };

        $scope.checkMenu = function () {

            $scope.errorMenuMessage = "";
            $scope.successMenuMessage = "";
            $scope.errorMenuMessageHtml = "";

            if ($scope.menu.IdProfile === undefined || $scope.menu.IdProfile === "" || $scope.menu.IdProfile === null || $scope.menu.IdProfile == -1)
                $scope.errorMenuMessage += "Veuillez sélectionner un profil. <br/>";

            if ($scope.menu.Label === undefined || $scope.menu.Label === "" || $scope.menu.Label === null)
                $scope.errorMenuMessage += "Veuillez renseigner un nom. <br/>";
            else if ($scope.menu.Label.length > 50)
                $scope.errorMenuMessage += "Veuillez renseigner un nom de 50 caractères maximum. <br/>";

            if ($scope.menu.IcoName === undefined || $scope.menu.IcoName === "" || $scope.menu.IcoName === null)
                $scope.errorMenuMessage += "Veuillez sélectionner un pictogramme. <br/>";

            if ($scope.errorMenuMessage !== "") {
                $scope.errorMenuMessageHtml = $sce.trustAsHtml($scope.errorMenuMessage);
                return false;
            }
            $scope.saveMenu();
            return true;
        };

        $scope.editElement = function (index) {
            var element = $rootScope.contents[index];
            if (element.IdMenu)
                $scope.editMenu(index);
            else
                $scope.EditPage(-1, index);
        };

        $scope.editMenu = function (index) {
            initMenuMessage();
            $scope.menu = {};
            $scope.action = "Modifier";
            $scope.Title = "Modifier un menu";
            $scope.LabelButton = "Modifier";
            $scope.indexSelected = index;
            $scope.menu = angular.copy($rootScope.contents[index]);
            
            var itemFilter = $filter('filter')($rootScope.listIcoName, { "IcoName": $scope.menu.IcoName }, true)[0];
            $scope.ChangeIcoName(itemFilter.Num - 1, $scope.menu.IcoName);

            if (($scope.menu.IdRoles.length > 1 || $scope.menu.IdGroupContacts.length > 1) || ($scope.menu.IdRoles.length === 0 && $scope.menu.IdGroupContacts.length === 0))
                $scope.menu.IdProfile = "Gnrl_" + 0;
            else {
                if ($scope.menu.IdRoles.length == 1) {
                    $scope.menu.IdProfile = "User_" + $scope.menu.IdRoles[0];
                } else {
                    if ($scope.menu.IdGroupContacts.length == 1) {
                        $scope.menu.IdProfile = "GroupUser_" + $scope.menu.IdGroupContacts[0];
                    }
                }
            }
        };

        $scope.saveMenu = function () {

            $scope.UpdateRoleAndGroupUser();

            if ($scope.action === "Ajouter" && $rootScope.contents !== null && $rootScope.contents !== undefined && $rootScope.contents.length >= 8)
                $scope.errorMenuMessageHtml = $sce.trustAsHtml("Il est impossible d'ajouter plus de 8 menus.");
            else {
                var action = $scope.action;

                if (action === "Ajouter") {
                    $scope.menu.Position = $rootScope.contents.length + 1;
                    $rootScope.contents.push($scope.menu);
                    initMenu();
                    $scope.successMenuMessage = "Votre menu a bien été pris en compte.";
                } else {
                    $rootScope.contents[$scope.indexSelected] = angular.copy($scope.menu);
                    initMenu();
                    $scope.successMenuMessage = "Vos modifications ont bien été prises en compte.";
                }

                reloadContent();
            }
        };

        $scope.DeleteContent = function (index, openPopUp, modalTitle, type, indexParent) {
            initMenu();
            $scope.modalTitle = modalTitle;
            $scope.indexSelected = index;
            $scope.indexParent = indexParent;
            $scope.typeDeleted = type;

            if ($scope.typeDeleted === "Menu") {
                $scope.textPopUpDelete = "Êtes-vous sûr de vouloir supprimer ce menu ?";
                $scope.menuDelete = angular.copy($rootScope.contents[index]);
            }
            else {
                $scope.textPopUpDelete = "Êtes-vous sûr de vouloir supprimer cette page ?";
            }
            openPopUp();
        };

        $scope.ConfirmPosition = function (openPopUp, modalTitle) {
            var showPopUp = false;
            $rootScope.contents.forEach(function (item) {
                if (item.Pages && item.Pages.length > 0) {
                    if (item.IdRoles.length == 1) { //Si on a qu'un seul rôle dans la liste on regarde si les pages ont le même rôle sinon on affichera une pop-up
                        var idRoleMenu = item.IdRoles[0];
                        item.Pages.forEach(function (itemPage) {
                            if (itemPage.IdRoles.length > 0 && $.inArray(idRoleMenu, itemPage.IdRoles) == -1) {//Si la page à un role qui n'est pas celui du menu 
                                showPopUp = true;
                            }
                        });
                    }//group utilisateur
                    else if (item.IdGroupContacts.length == 1) { //Si on a qu'un seul GroupContact dans la liste on regarde si les pages ont le même GroupContact sinon on affichera une pop-up
                        var idGroupContactMenu = item.IdGroupContacts[0];
                        item.Pages.forEach(function (itemPage) {
                            if (itemPage.IdGroupContacts.length > 0 && $.inArray(idGroupContactMenu, itemPage.IdGroupContacts) == -1) {//Si la page à un groupContact qui n'est pas celui du menu 
                                showPopUp = true;
                            }
                        });
                    }
                }
            });
            if (!showPopUp) {
                $scope.SavePosition();
            }
            else {
                initMenu();
                $scope.modalTitle = modalTitle;
                $scope.textPopUpConfirm = "Certaines pages ont un profil différent du menu dans lequel elles se situent. Êtes-vous sûr de vouloir sauvegarder ces positions ?";
                openPopUp();
            }
        };

        $scope.DeleteConfContent = function () {
            if ($scope.typeDeleted === "Menu") {
                if ($scope.menuDelete.Pages !== null && $scope.menuDelete.Pages !== undefined && $scope.menuDelete.Pages.length > 0) {
                    $scope.errorMessage = $sce.trustAsHtml("Impossible de supprimer ce menu car il est lié à une ou plusieurs pages.");
                }
                else {
                    $rootScope.contents.splice($scope.indexSelected, 1);
                    $rootScope.contents.forEach(function (item, index) {
                        $rootScope.contents[index].Position = index + 1;
                    });
                    reloadContent();
                }
            }
            else {
                $rootScope.contents[$scope.indexParent].Pages.splice($scope.indexSelected, 1);
                $rootScope.contents[$scope.indexParent].Pages.forEach(function (item, index) {
                    $rootScope.contents[$scope.indexParent].Pages[index].Position = index + 1;
                });
                reloadContent();
            }
        };

        $scope.SubId = function (item) {
            $scope.isUserRole = false;
            $scope.isGroupUser = false;

            if (item.split("_")[0] === "GroupUser")
                $scope.isGroupUser = true;
            else if (item.split("_")[0] == "User")
                $scope.isUserRole = true;

            $scope.profilSelected = parseInt(item.split("_")[1]);
        };

        $scope.AddPage = function () {
            $location.path('/admin/contents/content/savePage/-1/-1');
        };
        $scope.EditPage = function (indexMenu, indexPage) {
            $location.path('/admin/contents/content/savePage/' + indexMenu + '/' + indexPage);
        };

        if (!$rootScope.ModuleNews.Active)
            $location.path('/admin/parameters/global');

        init();

    }]);
