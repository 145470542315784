AgrimarketControllers.controller('savePageController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'enumFactory', 'contentService', 'configurationFactory',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, enumFactory, contentService, configurationFactory) {

        $scope.indexPage = $routeParams.indexPage;
        $scope.indexMenuOrigine = $routeParams.indexMenu == -1 ? undefined : +$routeParams.indexMenu;
        $scope.roles = enumFactory.Roles;
        $scope.profils = enumFactory.Profiles;
        $scope.enumTypePage = enumFactory.TypePages;
        $scope.enumTypePageAccess = enumFactory.TypePageAccess;
        $rootScope.resetMenu = false;
        $scope.profileOptionPage = [];
        $scope.typePages = [];
        $scope.typePageAccess = [];
        $scope.menus = angular.copy($rootScope.contents);
        if ($scope.menus) {
            $scope.menus.forEach(function (m, index){
                m.IndexMenuOrigin = index;
            });
        }
        $scope.menus = $filter('filter')($scope.menus, function(m){ return m.IdMenu; }, true);

        var GetDatasForPageContent = function () {
            contentService.GetDatasForPageContent(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data !== undefined && data !== null) {
                        $scope.profileOptionPage = data.profiles.ProfilesList;
                        $scope.profileOptionFilter = angular.copy($scope.profileOptionPage);
                        $scope.typePages = data.typePages;
                        $scope.typePageAccess = data.typePagesAccess;
                        $scope.urlTags = data.urlTags;
                    }
                }
            });
        };

        $rootScope.onRefresh = function () {
            Init();
        };

        var InitMessage = function () {
            $scope.pageErrorMessage = "";
        };

        $scope.ChangeIcoName = function (index, icoNameOv) {
            $scope.page.IcoName = icoNameOv;
            $rootScope.listIcoName.forEach(function (item, indexIco) {
                item.IsChecked = index == indexIco;
            });
        };

        var Init = function () {
            InitMessage();
            if ($scope.indexPage === undefined || $scope.indexPage === null || $scope.indexPage === "-1") { //nouvelle page
                $scope.blocTitle = "Création d'une page de contenu";
                $scope.page = { Publied: "true", IdTypePage: $scope.enumTypePage.Content };
            } else { //modifier une page
                $scope.blocTitle = "Modification d'une page de contenu";
                $scope.page = $scope.indexMenuOrigine || $scope.indexMenuOrigine === 0 ? angular.copy($rootScope.contents[$scope.indexMenuOrigine].Pages[$scope.indexPage]) : angular.copy($rootScope.contents[$scope.indexPage]);
                $scope.page.Publied = $scope.page.Publied ? "true" : "false";
                $scope.page.IndexMenu = $scope.indexMenuOrigine;
                if (($scope.page.IdRoles.length > 1 || $scope.page.IdGroupContacts.length > 1) || ($scope.page.IdRoles.length === 0 && $scope.page.IdGroupContacts.length === 0)) {
                    $scope.page.IdProfile = "Gnrl_" + 0;
                } else {
                    //Profil user
                    if ($scope.page.IdRoles.length == 1)
                        $scope.page.IdProfile = "User_" + $scope.page.IdRoles[0];
                    else {
                        //group User
                        if ($scope.page.IdGroupContacts.length == 1)
                            $scope.page.IdProfile = "GroupUser_" + $scope.page.IdGroupContacts[0];
                    }
                }
                var itemFilter = $filter('filter')($rootScope.listIcoName, { "IcoName": $scope.page.IcoName }, true)[0];
                if (itemFilter) {
                    $scope.ChangeIcoName(itemFilter.Num - 1, $scope.page.IcoName);
                }
            }
            GetDatasForPageContent();
            for (i = 0; i < $rootScope.showDetails.length; ++i) $rootScope.showDetails[i] = false;
        };

        $scope.AddTag = function () {
            $scope.page.UrlLink += $scope.tagselected;
        };

        $scope.UploadImage = function (data) {
            CKEDITOR.instances.Content.insertHtml("<img src='" + $filter('uploadLinkInline')(data.PrivateLabel) + "'>");
        };

        $scope.UploadAttachments = function (data) {         
            CKEDITOR.instances.Content.insertHtml("{{Attachment|" + data.IdAttachment + "|" + $scope.fileName + "}}");
        };

        $scope.SavePage = function () {
            InitMessage();
            if (!$scope.page.IdProfile)
                $scope.pageErrorMessage += "Veuillez renseigner le profil concerné.<br/>";
            else {
                $scope.page.IdRoles = [];
                $scope.page.IdGroupContacts = [];
                $scope.page.ProfilLabel = $filter('filter')($scope.profileOptionPage, { Value: $scope.page.IdProfile }, true)[0].Name;
                
                if ($scope.page.IdProfile === "Gnrl_" + 0) {
                    $scope.page.IdRoles.push(0);
                    $scope.page.IdGroupContacts.push(0);
                } else {
                    if ($scope.page.IdProfile === "User_" + $scope.roles.User)
                        $scope.page.IdRoles.push($scope.roles.User);
                    else {
                        if ($scope.page.IdProfile === "User_" + $scope.roles.TC)
                            $scope.page.IdRoles.push($scope.roles.TC);
                        else
                            $scope.page.IdGroupContacts.push(parseInt($scope.page.IdProfile.split("_")[1]));
                    }
                }
            }
            
            if (!$scope.page.Label)
                $scope.pageErrorMessage += "Veuillez renseigner un titre.<br/>";

            if ($scope.page.IdTypePage == $scope.enumTypePage.DirectUrl && $scope.page.AvailableOnWebSite && !$scope.page.IdTypePageAccess)
                $scope.pageErrorMessage += "Veuillez renseigner un type d'accès.<br/>";

            if ($scope.page.IdTypePage == $scope.enumTypePage.DirectUrl && (!$scope.page.UrlLink || !$scope.page.UrlLink.trim()))
                $scope.pageErrorMessage += "Veuillez renseigner un lien URL.<br/>";
            
            if ($scope.page.IndexMenu === null || $scope.page.IndexMenu === undefined || +$scope.page.IndexMenu === -1) { // Si page sans menu
                $scope.page.IdMenu = null;

                if (!$scope.page.IcoName)
                    $scope.pageErrorMessage += "Veuillez sélectionner un pictogramme.<br/>";
            }
            else
                $scope.page.AvailableOnApp = false;

            if ($scope.page.IdTypePage == $scope.enumTypePage.Content)
                $scope.page.IdTypePageAccess = null;
            else if ($scope.page.IdTypePage == $scope.enumTypePage.DirectUrl && $scope.page.IdTypePageAccess == $scope.enumTypePageAccess.Page)
                $scope.page.TargetLink = "_blank";

            if ($scope.pageErrorMessage !== "") {
                $scope.pageErrorMessage = $sce.trustAsHtml($scope.pageErrorMessage);
                return false;
            }

            $scope.page.Publied = $scope.page.Publied == "true";

            if ($scope.indexPage === undefined || $scope.indexPage === null || $scope.indexPage === "-1") { // nouvelle page
                if ($scope.page.IndexMenu || $scope.page.IndexMenu === 0) {
                    $scope.page.Position = $rootScope.contents[$scope.page.IndexMenu].Pages.length + 1;
                    $rootScope.contents[$scope.page.IndexMenu].Pages.push($scope.page);
                    $rootScope.showDetails[$scope.page.IndexMenu] = true;
                }
                else {
                    $scope.page.Position = $rootScope.contents.length + 1;
                    $rootScope.contents.push($scope.page);
                }
            }
            else { // edition de page
                if ($scope.indexMenuOrigine === $scope.page.IndexMenu) { // si on a pas changé le menu de la page
                    if ($scope.page.IndexMenu || $scope.page.IndexMenu === 0) {
                        $rootScope.contents[$scope.indexMenuOrigine].Pages[$scope.indexPage] = $scope.page;
                        $rootScope.showDetails[$scope.indexMenuOrigine] = true;
                    }
                    else
                        $rootScope.contents[$scope.indexPage] = $scope.page;
                }
                else { // si le menu de la page a changé
                    if ($scope.page.IndexMenu || $scope.page.IndexMenu === 0) {
                        $scope.page.Position = $rootScope.contents[$scope.page.IndexMenu].Pages.length + 1;
                        $rootScope.contents[$scope.page.IndexMenu].Pages.push($scope.page);
                        var newContentIndex = !$scope.indexMenuOrigine && $scope.indexPage < $scope.page.IndexMenu ? $scope.page.IndexMenu-1 : $scope.page.IndexMenu;
                        $rootScope.showDetails[newContentIndex] = true;
                    }
                    else {
                        $scope.page.Position = $rootScope.contents.length + 1;
                        $rootScope.contents.push($scope.page);
                    }

                    if ($scope.indexMenuOrigine)
                        $rootScope.contents[$scope.indexMenuOrigine].Pages.splice($scope.indexPage, 1);
                    else
                        $rootScope.contents.splice($scope.indexPage, 1);
                }
            }

            $scope.Cancel();
            return true;
        };

        $scope.$watch('page.IndexMenu + profileOptionPage', function () {
            if (!$scope.profileOptionFilter) return;

            var filterGeneral = angular.copy($scope.profileOptionFilter);

            if ($scope.page.IndexMenu === null || $scope.page.IndexMenu === undefined || +$scope.page.IndexMenu === -1){ // sans menu
                if ($scope.profileOptionPage.length !== filterGeneral.length)
                    $scope.profileOptionPage = filterGeneral;
                if ($scope.indexPage === undefined || $scope.indexPage === null || $scope.indexPage === "-1" || $filter('filter')($scope.profileOptionPage, {Value: $scope.page.IdProfile}, true)[0] === undefined)
                    $scope.page.IdProfile = "Gnrl_" + 0;
            }
            else {
                if (($rootScope.contents[$scope.page.IndexMenu].IdRoles.length > 0 && $rootScope.contents[$scope.page.IndexMenu].IdGroupContacts.length > 0) || ($rootScope.contents[$scope.page.IndexMenu].IdRoles.length === 0 && $rootScope.contents[$scope.page.IndexMenu].IdGroupContacts.length === 0) || $rootScope.contents[$scope.page.IndexMenu].IdRoles.length > 1) {
                    $scope.profileOptionPage = filterGeneral;
                    if ($scope.indexPage === undefined || $scope.indexPage === null || $scope.indexPage === "-1" || $filter('filter')($scope.profileOptionPage, {Value: $scope.page.IdProfile}, true)[0] === undefined)
                        $scope.page.IdProfile = "Gnrl_" + 0;
                }
                else {
                    if ($rootScope.contents[$scope.page.IndexMenu].IdRoles.length > 0) {
                        
                        if ($rootScope.contents[$scope.page.IndexMenu].IdRoles[0] === $scope.roles.User) {
                            $scope.profileOptionPage = angular.copy(filterGeneral.filter(function (element) { return element.Value !== "User_" + $scope.roles.TC && element.Value !== "Gnrl_" + 0; }));
                        }
                        else {
                            $scope.profileOptionPage = angular.copy(filterGeneral.filter(function (element) {
                                return element.Value === "User_" + $scope.roles.TC;
                            }));
                        }
                        if ($scope.indexPage === undefined || $scope.indexPage === null || $scope.indexPage === "-1" || $filter('filter')($scope.profileOptionPage, { Value: $scope.page.IdProfile }, true)[0] === undefined)
                            $scope.page.IdProfile = "User_" + $rootScope.contents[$scope.page.IndexMenu].IdRoles[0];
                    } else {
                        $scope.page.IdProfile = "GroupUser_" + $rootScope.contents[$scope.page.IndexMenu].IdGroupContacts[0];
                        $scope.profileOptionPage = angular.copy(filterGeneral.filter(function (element) { return element.Value === "GroupUser_" + $rootScope.contents[$scope.page.IndexMenu].IdGroupContacts[0]; }));
                    }
                }
            }
        });

        $scope.Cancel = function () {
            $location.path('/admin/contents/content');
        };

        Init();
    }]);
