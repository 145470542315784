AgrimarketServices.service('conditionsService', ['$http', 'configurationFactory', 'editableObjectsFactory', '$rootScope', '$filter',
    function ($http, configurationFactory, editableObjectsFactory, $rootScope, $filter) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        var _getUserGroupConditionsOfTree = function (idOffer, idExpiry, idVariety, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Tree/GetUserGroupConditionsOfTree',
                params: { idOffer: idOffer, idExpiry: idExpiry, idVariety: idVariety }
            };
            SendRequest(config, callback);
        };

        var _getUserGroupConditionsChild = function (idTreeCondition, idOffer, idExpiry, idVariety, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Tree/GetUserGroupConditionsChild',
                params: { idTreeCondition: idTreeCondition, idOffer: idOffer, idExpiry: idExpiry, idVariety: idVariety }
            };
            SendRequest(config, callback);
        };

        var _recursifCheckAndSetGroupConditions = function (lstGroupConditions, objError, tabSelectedValues) {
            if (lstGroupConditions && lstGroupConditions.length > 0) {
                lstGroupConditions.every(function (element) {
                    // Gestion des groupes en saisie libre
                    if (element.IdTypeFreeInput){
                        if (!element.TextFreeInput) {
                            objError.text = editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseSelectAllConditions.Content;
                            return false;
                        } else if (element.IdTypeFreeInput === $rootScope.enumFactory.TypeFreeInputs.Numeric && isNaN(element.TextFreeInput.replace(',','.'))) {
                            objError.text = editableObjectsFactory.EditableObjects.Error_OfferSubscribe_GroupFreeInput_ValueMustBeNumeric.Content.replace("[group]",element.Label);
                            return false;
                        }
                        else {
                            tabSelectedValues.push({ IdTreeGroupCondition: element.IdTreeGroupCondition, Visible: true, Value: 0, GrpLabel: element.Label, Label: element.TextFreeInput, LabelToDisplay: element.TextFreeInput, IdGroupCondition: element.IdGroupCondition, AutoComplete: false });
                            return true;
                        }
                    }
                    // Gestion des groupes en liste déroulante
                        if (!element.selectedValue || element.selectedValue.Value <= 0) {
                            objError.text = editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseSelectAllConditions.Content;
                            return false;
                        } else {
                            var selectedOption = element.LstOptions.filter(function (option) { return option.IdTreeCondition === Number(element.selectedValue); })[0];
                            var selectedOptionAutoComplete = element.LstOptions.filter(function (option) { return option.Label === element.selectedValue; })[0];
                            if(selectedOption != null && selectedOption != undefined)
                                tabSelectedValues.push({ IdTreeGroupCondition: element.IdTreeGroupCondition, IdTreeCondition: Number(element.selectedValue), Visible: selectedOption.Visible, Value: selectedOption.Value, GrpLabel: element.Label, Label: selectedOption.Label, LabelToDisplay: selectedOption.LabelToDisplay, IdGroupCondition: element.IdGroupCondition, AutoComplete: element.AutoComplete });
                            else if(selectedOptionAutoComplete != null && selectedOptionAutoComplete != undefined)
                                tabSelectedValues.push({ IdTreeGroupCondition: element.IdTreeGroupCondition, IdTreeCondition: selectedOptionAutoComplete.IdTreeCondition, Visible: selectedOptionAutoComplete.Visible, Value: selectedOptionAutoComplete.Value, GrpLabel: element.Label, Label: selectedOptionAutoComplete.Label, LabelToDisplay: selectedOptionAutoComplete.LabelToDisplay, IdGroupCondition: element.IdGroupCondition, AutoComplete: element.AutoComplete });
                            _recursifCheckAndSetGroupConditions(element.LstConditionParticularsChilds, objError, tabSelectedValues);
                            return true;
                        }
                });
            }
        };

        var _conditionParticularsOnChange = function (idOffer, groupCondPart, bestPath, idExpiry, idVariety) {
            groupCondPart.LstConditionParticularsChilds = [];
            if (groupCondPart.selectedValue > 0) {
                _getUserGroupConditionsChild(groupCondPart.selectedValue, idOffer, idExpiry, idVariety, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            return data[0].errors[0].Message;
                    } else {
                        groupCondPart.LstConditionParticularsChilds = data;
                        groupCondPart.LstConditionParticularsChilds.forEach(function (group) {
                            group.LstOptions.forEach(function (condition) {
                                condition.Label =  condition.Label;
                                condition.LabelToDisplay = condition.Visible ? condition.Label + ' (' + condition.Value + ' ' + $rootScope.globalLabels.Currency + ')' : condition.Label;
                            });
                            if (bestPath && bestPath.Conditions) {// Si on a le meilleur chemin de condPart, alors on les pré-selectionnes
                                var infoBestCondPartInGroup = $filter('filter')(bestPath.Conditions, { IdTreeGroupCondition: group.IdTreeGroupCondition }, true)[0];
                                if (infoBestCondPartInGroup) // On pré-selectionne la condPart, et on va chercher ses fils, si il sagit d'un Autocomplete, on set avec le label car ce n'est pas un select, c'est un input le remplissage est différent
                                    if (!group.IdTypeFreeInput) // si il sagit d'un Autocomplete, on set avec le label car ce n'est pas un select, c'est un input le remplissage est différent
                                        group.selectedValue = !group.AutoComplete ? infoBestCondPartInGroup.IdTreeCondition : infoBestCondPartInGroup.Label;
                                    else
                                        group.TextFreeInput = infoBestCondPartInGroup.Label;
                            }
                            else {
                                if (!group.Hidden)
                                    group.LstOptions.unshift({
                                        "IdTreeCondition": 0,
                                        "Label": "Sélectionnez..",
                                        "LabelToDisplay": "Sélectionnez..",
                                        "Value": 0,
                                        "Visible": false
                                    });
                                group.selectedValue = group.LstOptions[0].IdTreeCondition;
                            }
                            if (group.selectedValue)
                                _conditionParticularsOnChange(idOffer, group, bestPath, idExpiry, idVariety);
                        });
                    }
                });
            }
        };

        this.GetTreesForSelect = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Tree/GetTreesForSelect',
            };
            SendRequest(config, callback);
        };

        this.GetTrees = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Tree/GetTrees',
            };
            SendRequest(config, callback);
        };

        this.SaveTree = function (tree, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Tree/SaveTree',
                data: tree
            };
            SendRequest(config, callback);
        };

        this.DeleteTree = function (idTree, callback) {
            var config = {
                method: 'PUT',
                url: configurationFactory.Url['Api'] + 'Tree/DeleteTree',
                params: { idTree: idTree }
            };
            SendRequest(config, callback);
        };

        this.GetGroupConditions = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'GroupCondition/GetGroupConditions'
            };
            SendRequest(config, callback);
        };

        this.SaveGroupConditions = function (groups, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'GroupCondition/SaveGroupConditions',
                data: groups
            };
            SendRequest(config, callback);
        };

        this.CheckAndSetGroupConditions = function (lstGroupConditions, tabSelectedValues) {
            var objError = { text: "" };

            if (lstGroupConditions && lstGroupConditions.length > 0)
                _recursifCheckAndSetGroupConditions(lstGroupConditions, objError, tabSelectedValues);

            return objError.text;
        };

        this.GetCondParts = function (idOffer, idTree, idContractCommitment, contractualisationWithCommitment, bestPath, idExpiry, idVariety, callback) {
            var res = {
                errorMessage: "",
                conditionParticulars: null
            };
            if (idTree > 0 && (!idContractCommitment || (contractualisationWithCommitment && !contractualisationWithCommitment.HasContractConditions))) {
                _getUserGroupConditionsOfTree(idOffer, idExpiry, idVariety, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            res.errorMessage = data[0].errors[0].Message;
                        }
                    } else {
                        res.conditionParticulars = data;
                        res.conditionParticulars.forEach(function (group) {
                            group.LstOptions.forEach(function (condition) {
                                condition.Label = condition.Label;
                                condition.LabelToDisplay = condition.Visible ? condition.Label + ' (' + condition.Value + ' ' + $rootScope.globalLabels.Currency + ')' : condition.Label;
                            });
                            if (bestPath && bestPath.Conditions) {// Si on a le meilleur chemin de condPart, alors on les pré-selectionnes
                                var infoBestCondPartInGroup = $filter('filter')(bestPath.Conditions, { IdTreeGroupCondition: group.IdTreeGroupCondition }, true)[0];
                                if (infoBestCondPartInGroup) {// On pré-selectionne la condPart, et on va chercher ses fils
                                    if (!group.IdTypeFreeInput) // si il sagit d'un Autocomplete, on set avec le label car ce n'est pas un select, c'est un input le remplissage est différent
                                        group.selectedValue = !group.AutoComplete ? infoBestCondPartInGroup.IdTreeCondition : infoBestCondPartInGroup.Label;
                                    else
                                        group.TextFreeInput = infoBestCondPartInGroup.Label;
                                }
                            }
                            else if (!group.IdTypeFreeInput){
                                if (!group.Hidden)
                                    group.LstOptions.unshift({
                                        "IdTreeCondition": 0,
                                        "Label": "Sélectionnez..",
                                        "LabelToDisplay": "Sélectionnez..",
                                        "Value": 0,
                                        "Visible": false
                                    });
                                group.selectedValue = group.LstOptions[0].IdTreeCondition;
                            }
                            if (group.selectedValue)
                                _conditionParticularsOnChange(idOffer, group, bestPath, idExpiry, idVariety);
                        });
                    }
                    callback(res);
                    return;
                });
            }
        };

        this.ConditionParticularsOnChange = function (idOffer, condition, bestPath, idExpiry, idVariety) {
            _conditionParticularsOnChange(idOffer, condition, bestPath, idExpiry, idVariety);
        };

        this.GetTotalLstGroupConditionsSelectedValues = function (lstGroupConditionsSelectedValues) {
            var total = 0;

            if (lstGroupConditionsSelectedValues) {
                lstGroupConditionsSelectedValues.forEach(function (item) {
                    if (item && item.Value)
                        total += item.Value;
                });
            }

            return total;
        };

        this.GetBestPath = function (idOffer, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Tree/GetOfferBestPath',
                params: { idOffer: idOffer }
            };
            SendRequest(config, callback);
        };

        this.GetContractCondParts = function (idContract, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Tree/GetContractCondParts',
                params: { idContract: idContract }
            };
            SendRequest(config, callback);
        };

        this.GetTypeFreeInputs = function(callback){
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'GroupCondition/GetTypeFreeInputs'
            };
            SendRequest(config, callback);
        };
    }]);