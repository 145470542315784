AgrimarketControllers.controller('subscribeContractBaseController', ['$scope', '$rootScope', '$location', 'currentUserFactory', 'enumFactory', 'configurationFactory', 'parametersFactory', '$routeParams', 'offerService', 'parametersService', 'contractsService', '$filter', 'conditionsService', 'colonnesContractTypeCommitmentAndProduct', 'signatureService', 'contractingDelayService', 'typeOrdersService', 'colonnesContractFutureCompatibles', 'targetPriceService', 'contractBaseService', 'userService', 'dateService',
    function ($scope, $rootScope, $location, currentUserFactory, enumFactory, configurationFactory, parametersFactory, $routeParams, offerService, parametersService, contractsService, $filter, conditionsService, colonnesContractTypeCommitmentAndProduct, signatureService, contractingDelayService, typeOrdersService, colonnesContractFutureCompatibles, targetPriceService, contractBaseService, userService, dateService) {

        var init = function () {

            $scope.paramsCompatibleCommitments = { reverse: false, sortKey: "IdContract", maxContracts: 10, pageNo: 1 };
            $scope.paramsCompatibleFutures = { reverse: false, sortKey: "IdContract", maxContracts: 10, pageNo: 1 };
            $scope.IsUneal = $rootScope.paramNameCoop === enumFactory.Coops.Uneal ? true : false;
            $scope.selectedVariety = {};
            $scope.selectedConnection = {
                Connection: {}
            };
            $scope.listConnection = [];
            $scope.isSell = false;
            $scope.signatureYouSign = {};
            $scope.isObjective = false;
            $scope.showListChooseContact = false;
            $scope.checkValidCondition = false;
            $scope.showUrlSellConditionAttachment = false;
            $scope.showUrlUtilConditionAttachment = false;
            $scope.idContractCommitment = $routeParams.idContractCommitment;
            $scope.idContractFuture = $routeParams.idContractFuture;
            $scope.enumTypeCommitment = enumFactory.TypeCommitment;
            $scope.enumTypeOfferCommitment = enumFactory.TypeOfferCommitment;
            $scope.enumTypeTargetPriceMessage = enumFactory.TypeTargetPriceMessage;
            $scope.user = currentUserFactory.User;
            $scope.mobileNumberToSendSms = $rootScope.selectedClientFromTc ? $rootScope.selectedClientFromTc.MobileNumber : currentUserFactory.Connection ? currentUserFactory.Connection.MobileNumber : $scope.user.MobileNumber;
            $scope.withConditions = true;
            $scope.isModuleAlertesSmsActive = $rootScope.Modules.ModuleAlertesSms.Active;
            $scope.enumRoles = enumFactory.Roles;
            $scope.effortBaseTcEnabled = false;
            $scope.tcEffortInputIsDelta = parametersFactory.Parameters.TcEffortInputMode.Value == 'DELTA';
            $scope.enumTypeEffortTc = enumFactory.TypeEffortTc;
            $scope.quantity = $routeParams.quantity;
            $scope.targetPrice = $routeParams.targetPrice;
            $scope.enumFactory = enumFactory;
            $scope.moduleYousignIsActive = $rootScope.ModuleSignatureElectroniqueYouSign.Active;
            $scope.showConfirmButton = true;
            $scope.enumRoles = enumFactory.Roles;
            $scope.bUseDepositVolume = (parametersFactory.Parameters.bActiveDepositVolume.Value === "1" && !!parametersFactory.Parameters.IdsOperationForDepositVolume);

            $scope.targetPriceNotification = {
                IsMail: true
            };
            $scope.termsAndConditions = {
                isChecked: false
            };
            $scope.security = {
                Code: ""
            };
            $scope.counter = {
                start: false,
                pause: false
            };

            if ($rootScope.User.IdRole === $scope.enumRoles.TC && $rootScope.User.EffortBaseTcEnabled) { //Si on est un TC et qu'il y a des efforts de base possible alors le TC à le droit de saisir un effort
                $scope.effortBaseTcEnabled = true;
            }

            //Prise en compte des modifications des parametres par l'Admin
            parametersService.GetParameters(function (dataParam, statusParam) {
                if (statusParam !== undefined && statusParam !== null) {
                    if (statusParam === 400) {
                        $scope.loginErrorMessage = dataParam[0].errors[0].Message;
                    }
                } else {
                    parametersFactory.Parameters = dataParam;
                    $scope.paramCanChooseTargetPriceMessage = parametersFactory.Parameters.CanChooseTargetPriceMessage.Value === "1";
                    $scope.paramDisplaySurfaceYield = parametersFactory.Parameters.DisplaySurfaceYield.Value === "1";

                    if (!parametersFactory.Parameters.FileTermCondition.Value)
                        $scope.showUrlSellConditionAttachment = false;
                    else {
                        $scope.urlSellConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FileTermCondition.Value;
                        $scope.showUrlSellConditionAttachment = true;
                    }

                    if (!parametersFactory.Parameters.FileTermeUse.Value)
                        $scope.showUrlUtilConditionAttachment = false;
                    else {
                        $scope.urlUtilConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FileTermeUse.Value;
                        $scope.showUrlUtilConditionAttachment = true;
                    }
                }
            });

            if ($rootScope.idOfferSelected) {
                // Récupération de l'offre à souscrire
                offerService.GetOfferByIdAndIdExpiry($rootScope.idOfferSelected, $rootScope.idExpirySelected, "", function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        $scope.resumeOffer = data;
                        $scope.ProductQuantityLeftDepositToSellFunction();

                        $scope.signingEnabled = ($scope.moduleYousignIsActive || $rootScope.ModuleElectronicSignature.Active) && $rootScope.activateSignature && $scope.resumeOffer.ContractSigningEnabled;

                        if ($scope.targetPrice) {
                            $scope.resumeOffer.targetPriceCurrent = $scope.targetPrice;
                        }
                        if ($scope.quantity) {
                            $scope.resumeOffer.tonnageCurrent = Number($scope.quantity);
                        }
                        ManageStepCompatibleFutures();
                    }
                });
            } else
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_IdentifierOfOfferIsMissing.Content;
        };

        var GetCompatibleCommitments = function () {
            contractsService.GetCompatibleCommitmentsWithFilter($rootScope.idExpirySelected, $scope.paramsCompatibleCommitments.maxContracts, $scope.paramsCompatibleCommitments.pageNo, $scope.paramsCompatibleCommitments.sortKey, $scope.paramsCompatibleCommitments.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    $scope.lstCompatibleCommitments = data.ListVm;
                    $scope.paramsCompatibleCommitments.total = data.Total;
                    $scope.tableCompatibleCommitmentsDataVisible = colonnesContractTypeCommitmentAndProduct.data.DataVisible.split(',');
                    $scope.tableCompatibleCommitmentsLibelle = colonnesContractTypeCommitmentAndProduct.data.ColumnName.split(',');
                }
            });
        };

        var GetCompatibleFutures = function () {

            contractsService.GetCompatibleContractFuturesWithFilter($rootScope.idExpirySelected, $scope.paramsCompatibleFutures.maxContracts, $scope.paramsCompatibleFutures.pageNo, $scope.paramsCompatibleFutures.sortKey, $scope.paramsCompatibleFutures.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    if (data.ListVm.length < 1)
                        $scope.SelectContractFuture(null);
                    else {
                        $scope.lstCompatibleFutures = data.ListVm;
                        $scope.paramsCompatibleFutures.total = data.Total;
                        $scope.tableCompatibleFuturesDataVisible = colonnesContractFutureCompatibles.data.DataVisible.split(',');
                        $scope.tableCompatibleFuturesLibelle = colonnesContractFutureCompatibles.data.ColumnName.split(',');
                        goToStep("compatibleFutures");
                    }
                }
            });
        };

        var ManageStepCompatibleFutures = function () {
            if (!$scope.idContractFuture) {

                GetCompatibleFutures();
            } else {
                if ($scope.idContractFuture) {
                    SetContractFutureByIdContractFuture($scope.idContractFuture);
                }
                else
                    ManageStepCompatibleCommitments();
            }
        };

        var ManageStepCompatibleCommitments = function () {
            if ($scope.resumeOffer.IdTypeOfferCommitment === $scope.enumTypeOfferCommitment.EngagementFacultatif && !$scope.idContractCommitment) {
                goToStep("compatibleCommitments");
                GetCompatibleCommitments();
            } else {
                // Récupération de l'engagement du contrat
                if ($scope.idContractCommitment) {
                    SetContractCommitmentByIdContractCommitment($scope.idContractCommitment);
                } else {
                    ManageStepContractualisation();
                }
            }
        };

        var ManageStepContractualisation = function () {
            goToStep("contractualisation");
            $scope.withConditions = (!$scope.idContractCommitment || ($scope.contractualisationWithCommitment && !$scope.contractualisationWithCommitment.HasContractConditions)) && (!$scope.idContractFuture || ($scope.contractualisationWithFuture && !$scope.contractualisationWithFuture.HasContractConditions));

            //On vérifie si on doit préremplir ou pas le champ tonnage
            if ($rootScope.parambPreFillTonnage) {
                $scope.resumeOffer.tonnageCurrent = Number(GetTonnageMaxLimit());
            }

            // Initialisation du compteur pour le delai de contractualisation
            $scope.timeToContracting = new Date();
            contractingDelayService.InitialisationContractingDelay($scope.counter);

            // Vérification du type de prix
            typeOrdersService.CheckIsValidTypeOrder($rootScope.idTypeOrderSelected, function (res) {
                $scope.isSell = res.isSell;
                $scope.isObjective = res.isObjective;
                $scope.resumeOffer.idTypeOrder = res.idTypeOrder;
                $scope.errorMessage = res.errorMessage;
            });

            if ($scope.effortBaseTcEnabled) {
                $scope.resumeOffer.EffortBaseTc = ($scope.isSell && !$scope.idContractFuture) ? $scope.resumeOffer.BasePriceWithIncidence : ($scope.isSell && $scope.contractualisationWithFuture) ? $scope.resumeOffer.FinalPriceWithCoeffConverted : null;
                $scope.resumeOffer.DeltaEffortBaseTc = 0;
                $scope.effortBaseTcInitial = angular.copy($scope.resumeOffer.EffortBaseTc);
            }
            // Récupération des conditions particulères
            GetCondParts();
        };

        var SetContractCommitmentByIdContractCommitment = function (idContractCommitment) {
            var marketPrice = "";
            var targetPrice = "";

            if ($scope.contractualisationWithFuture) {
                if ($scope.contractualisationWithFuture.TargetPrice)
                    targetPrice = $scope.contractualisationWithFuture.TargetPrice;

                if ($scope.contractualisationWithFuture.MarketPrice)
                    marketPrice = $scope.contractualisationWithFuture.MarketPrice;
            }

            contractsService.GetContractCommitmentByIdContract(idContractCommitment, $scope.resumeOffer.IdOffer, $scope.resumeOffer.BasePrice, "", marketPrice, targetPrice, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    $scope.contractualisationWithCommitment = data;
                    if ($scope.contractualisationWithCommitment.HasContractConditions && (!$scope.contractualisationWithFuture || ($scope.contractualisationWithFuture && !$scope.contractualisationWithFuture.HasContractConditions))) {
                        $scope.resumeOffer.TotalConditionsValue = $scope.contractualisationWithCommitment.TotalConditionsValue;
                        $scope.resumeOffer.FinalPrice = $scope.contractualisationWithCommitment.FinalPrice;
                        $scope.resumeOffer.FinalPriceWithCoeffConverted = $filter('roundByDecimalAndConverter')($scope.resumeOffer.FinalPrice, $scope.resumeOffer.DecimalCurrencyUnitFinal, $scope.resumeOffer.ConverterCoeffValue);
                    }

                    if ($scope.idContractCommitment) {
                        ManageStepContractualisation();
                    }
                }
            });
        };

        var SetContractFutureByIdContractFuture = function (idContractFuture) {
            contractsService.GetContractFutureByIdContractFuture(idContractFuture, $scope.resumeOffer.BasePrice, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    $scope.contractualisationWithFuture = data;
                    $scope.resumeOffer.TotalConditionsValue = $scope.contractualisationWithFuture.TotalConditions;
                    $scope.resumeOffer.FinalPriceWithCoeffConverted = $filter('roundByDecimalAndConverter')($scope.contractualisationWithFuture.FinalPrice, $scope.resumeOffer.DecimalCurrencyUnitFinal, $scope.resumeOffer.ConverterCoeffValue);
                    $scope.idContractCommitment = $scope.contractualisationWithFuture.IdContractCommitment;
                    if ($scope.idContractCommitment)
                        SetContractCommitmentByIdContractCommitment($scope.idContractCommitment);
                    else
                        ManageStepContractualisation();
                }
            });
        };

        var GetCondParts = function () {
            if ($scope.IsUneal) {
                conditionsService.GetBestPath($scope.resumeOffer.IdOffer, function (bestPath) {
                    conditionsService.GetCondParts($scope.resumeOffer.IdOffer, $scope.resumeOffer.IdTree, $scope.idContractCommitment, $scope.contractualisationWithCommitment, bestPath, $rootScope.idExpirySelected, $scope.resumeOffer.IdVariety, function (res) {
                        $scope.errorMessage = res.errorMessage;
                        $scope.conditionParticulars = res.conditionParticulars;
                    });
                });
            }
            else {
                conditionsService.GetCondParts($scope.resumeOffer.IdOffer, $scope.resumeOffer.IdTree, $scope.idContractCommitment, $scope.contractualisationWithCommitment, '', $rootScope.idExpirySelected, $scope.resumeOffer.IdVariety, function (res) {
                    $scope.errorMessage = res.errorMessage;
                    $scope.conditionParticulars = res.conditionParticulars;
                });
            }
        };

        var SaveNewContratBase = function (newContractBaseVm) {
            contractBaseService.SaveNewContractBase(newContractBaseVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.idContractBase = data.idContractBase ;
                    $scope.hashContractBase = data.hashContractBase ;
                    $scope.counter = {};
                    $scope.counter.start = false;
                    goToStep("confirmation");
                }
            });
        };

        var CheckIsValidTonnageCurrent = function (tonnageMaxLimit, tonnageMaxCropProduct) {
            var qtyCommitmentToFix = '';
            if ($scope.idContractCommitment) {
                if ($scope.contractualisationWithFuture)
                    qtyCommitmentToFix = $scope.contractualisationWithFuture.Quantity;
                else
                    qtyCommitmentToFix = $scope.contractualisationWithCommitment.TonnageToFix;
            }
            offerService.CheckIsValidTonnageCurrent($scope.resumeOffer.tonnageCurrent, $scope.resumeOffer.TonnageMin, $scope.resumeOffer.TonnageMax, $scope.resumeOffer.QuantityLeft, $scope.resumeOffer.Quotity, qtyCommitmentToFix, $scope.resumeOffer.idTypeOrder, $scope.resumeOffer.IdTypeCover, tonnageMaxLimit, tonnageMaxCropProduct, function (error) {
                $scope.errorTonnageMessage = error.substring(1, error.length - 1); // Permet de supprimer les guillemets en début et fin de message
                if (tonnageMaxCropProduct) { //demande ducroquet deuxieme check, on laisse passer 
                    $scope.resumeOffer.MaxCropProductTonnage = null;
                }
                if ($scope.errorTonnageMessage)
                    return;

                if ($scope.effortBaseTcEnabled) {

                    var isGlobalPriceOrSecondComponent = false;
                    var coeffValue = "";

                    if ($scope.contractualisationWithFuture != null) // si fixation 2eme composante, on envoie le coeff value
                    {
                        coeffValue = $scope.resumeOffer.ConverterCoeffValue;
                        isGlobalPriceOrSecondComponent = true;
                    }
                    else
                        coeffValue = ""; //sinon, on envoie une chaine vide

                    var totalEffort = $scope.resumeOffer.EffortBaseTc + $scope.resumeOffer.DeltaEffortBaseTc;

                    userService.CheckEffortTc(totalEffort, $scope.effortBaseTcInitial, $scope.enumTypeEffortTc.Base, coeffValue, isGlobalPriceOrSecondComponent, $scope.resumeOffer.DecimalCurrencyUnitForComponents, function (data) {
                        if (data.ErrorMessage) {
                            $scope.errorEffortBaseTcMessage = data.ErrorMessage;
                            return;
                        }

                        $scope.effortBaseTc = data.EffortTc;
                        $scope.basePriceEffortBaseTc = $scope.resumeOffer.BasePrice + $scope.effortBaseTc;
                        $scope.basePriceWithIncidenceEffortBaseTc = $scope.resumeOffer.BasePriceWithIncidence + $scope.effortBaseTc;
                        $scope.basePriceFinalEffortBaseTc = $scope.resumeOffer.BasePriceFinal + $scope.effortBaseTc;
                        $scope.finalPriceEffortBaseTc = $filter('roundByDecimalAndConverter')(parseFloat($scope.resumeOffer.FinalPrice) + $scope.effortBaseTc, $scope.resumeOffer.DecimalCurrencyUnitForComponents);//On fait un parsefloat car il considère comme une chaine de caractère et au lieu de faire 16+4 et afficher 20 il affiche 164 si on ne fait pas de parsefloat
                        $scope.finalPriceWithCoeffEffortBaseTcConverted = $filter('roundByDecimalAndConverter')(parseFloat($scope.resumeOffer.FinalPrice) + $scope.effortBaseTc, $scope.resumeOffer.DecimalCurrencyUnitFinal, $scope.resumeOffer.ConverterCoeffValue);//On fait un parsefloat car il considère comme une chaine de caractère et au lieu de faire 16+4 et afficher 20 il affiche 164 si on ne fait pas de parsefloat

                        goToStep("validation");
                    });
                }
                else {
                    goToStep("validation");
                }
            });
        };

        $scope.GetDataCompatibleFutures = function (pageNo) {
            $scope.paramsCompatibleFutures.pageNo = pageNo;
            GetCompatibleFutures();
        };

        $scope.GetDataCompatibleCommitments = function (pageNo) {
            $scope.paramsCompatibleCommitments.pageNo = pageNo;
            GetCompatibleCommitments();
        };

        $scope.SelectContractFuture = function (idContractFuture) {
            $scope.idContractFuture = idContractFuture;
            if ($scope.idContractFuture) {
                SetContractFutureByIdContractFuture($scope.idContractFuture);
            }
            else
                ManageStepCompatibleCommitments();
        };

        $scope.SelectContractCommitment = function (idContractCommitment) {
            $scope.idContractCommitment = idContractCommitment;
            if ($scope.idContractCommitment) {
                // Récupération de l'engagement du contrat
                SetContractCommitmentByIdContractCommitment($scope.idContractCommitment);
                // On refraichit juste le finalprice pour le cas où on sélectionne l'engagement et les cond part à afficher ou pas
                offerService.GetOfferByIdAndIdExpiry($rootScope.idOfferSelected, $rootScope.idExpirySelected, "", function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        GetCondParts();
                    }
                });
            }
            ManageStepContractualisation();
        };

        var GetTonnageMaxLimit = function () {
            var tonnageMaxLimit = null;
            if ($scope.contractualisationWithCommitment && $scope.contractualisationWithFuture && !$scope.contractualisationWithFuture.IdContractCommitment)
                tonnageMaxLimit = Math.min($scope.contractualisationWithCommitment.TonnageToFix, $scope.contractualisationWithFuture.Quantity);
            else if ($scope.contractualisationWithFuture)
                tonnageMaxLimit = $scope.contractualisationWithFuture.Quantity;
            else if ($scope.contractualisationWithCommitment)
                tonnageMaxLimit = $scope.contractualisationWithCommitment.TonnageToFix;

            return tonnageMaxLimit;
        };

        $scope.Validate = function () {
            $scope.errorMessage = "";
            $scope.errorTonnageMessage = "";
            $scope.errorPriceMessage = "";
            $scope.errorEffortBaseTcMessage = "";
            $rootScope.lastOfferSelected = $scope.resumeOffer.IdOffer;

            // Vérification des conditions particulières
            $scope.resumeOffer.LstGroupConditionsSelectedValues = [];
            if ($scope.withConditions) {
                $scope.errorMessage = conditionsService.CheckAndSetGroupConditions($scope.conditionParticulars, $scope.resumeOffer.LstGroupConditionsSelectedValues);
                if ($scope.errorMessage)
                    return;
            }


            // Vérification de la variété
            if ($scope.resumeOffer.LstVarieties.length > 0 && !$scope.resumeOffer.IdVariety && (!$scope.idContractCommitment || ($scope.idContractCommitment && !$scope.contractualisationWithCommitment.HasVariety))) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterAVariety.Content;
                return;
            }
            else if ($scope.resumeOffer.LstVarieties.length > 0)
                $scope.selectedVariety = $filter('filter')($scope.resumeOffer.LstVarieties, { IdVariety: $scope.resumeOffer.IdVariety }, true)[0];

            // Vérification du tonnage du contrat
            var tonnageMaxLimit = GetTonnageMaxLimit();

            // Vérification du prix objectif
            var priceToDisplay = null;
            if ($scope.contractualisationWithFuture)
                priceToDisplay = $scope.resumeOffer.BasePrice;
            else
                priceToDisplay = $scope.resumeOffer.BasePriceWithIncidence;

            $scope.errorPriceMessage = targetPriceService.CheckPriceAndDateIsValid($scope.resumeOffer, $scope.idContractCommitment, $scope.contractualisationWithCommitment, priceToDisplay, false);
            if ($scope.errorPriceMessage)
                return;

            // Vérification du choix de notification du prix objectif
            $scope.errorPriceMessage = targetPriceService.CheckTargetPriceNotification($scope.resumeOffer, $scope.targetPriceNotification.IsMail, $scope.paramCanChooseTargetPriceMessage, $scope.user);
            if ($scope.errorPriceMessage)
                return;

            // Calcul de la somme des conditions particulières séléctionnées
            $scope.totalLstGroupConditionsSelectedValues = conditionsService.GetTotalLstGroupConditionsSelectedValues($scope.resumeOffer.LstGroupConditionsSelectedValues);

            // Calcul du prix base final
            $scope.resumeOffer.BasePriceFinal = ($scope.resumeOffer.targetPriceCurrent ? $scope.resumeOffer.targetPriceCurrent : priceToDisplay) + $scope.totalLstGroupConditionsSelectedValues;

            // Calcul du preview prix final
            if ($scope.isObjective) {
                if ($scope.contractualisationWithFuture) {
                    var refPrice = $scope.contractualisationWithFuture.TargetPrice ? $scope.contractualisationWithFuture.TargetPrice : $scope.contractualisationWithFuture.RefPrice;
                    var totalCondPart = $scope.totalLstGroupConditionsSelectedValues ? $scope.totalLstGroupConditionsSelectedValues : $scope.resumeOffer.TotalConditionsValue;
                    var finalPrice = targetPriceService.GetPreviewFinalPrice($scope.resumeOffer.targetPriceCurrent, refPrice, totalCondPart);
                    $scope.resumeOffer.FinalPriceWithCoeffConverted = $filter('roundByDecimalAndConverter')(finalPrice, $scope.resumeOffer.DecimalCurrencyUnitFinal, $scope.resumeOffer.ConverterCoeffValue);
                }
                else if ($scope.contractualisationWithCommitment) {
                    $scope.resumeOffer.FinalPrice = targetPriceService.GetPreviewFinalPrice($scope.resumeOffer.targetPriceCurrent, null, $scope.resumeOffer.TotalConditionsValue);
                }
            }

            // Vérification de la date de forçage
            if ($rootScope.paramIsTcAuthorizedToForcedDate && $rootScope.User.IdRole === $scope.enumRoles.TC && $scope.isSell && !$scope.isObjective) {
                if (!$scope.resumeOffer.DateSubscriptionForcedString) {
                    $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterDateForced.Content;
                    return;
                }
                if ($scope.resumeOffer.DateSubscriptionForcedString) {
                    if (!dateService.compareDate1SuperiorDate2(new Date(), dateService.ConvertDateToDateAPI($scope.resumeOffer.DateSubscriptionForcedString))) {
                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterAnteriorDateForced.Content;
                        return;
                    }
                }
            }


            // Vérification du délai de contractualisation
            $scope.errorMessage = contractingDelayService.VerifyContractingDelay($scope.timeToContracting);
            if ($scope.errorMessage) {
                $scope.isToLate = true;
                return;
            }

            CheckIsValidTonnageCurrent(tonnageMaxLimit, $scope.resumeOffer.MaxCropProductTonnage);

        };

        $scope.ValidateConnection = function () {
            if (!$scope.selectedConnection.Connection.IdConnection) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty.Content;
                return;
            }
            $scope.showConfirmButton = false;
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.mobileNumberToSendSms = $scope.selectedConnection.Connection.MobileNumber;

            // Création de la vm du contrat
            var newContractBaseVm = getContractBaseVm();

            if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active)
                checkContractAndSendSmsForWithoutThirdParty(newContractBaseVm);
            else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                checkContractAndSendSmsForYouSign(newContractBaseVm);
        };

        $scope.Confirm = function () {
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.counter.pause = true;

            if ($rootScope.idConnectionLogged)
                    $scope.selectedConnection.Connection.IdConnection = $rootScope.idConnectionLogged;

            // Vérification de l'acceptation des condition générale de vente
            if (!$scope.termsAndConditions.isChecked) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseAcceptGeneralConditionsOfSaleBeforeValidation.Content;
                $scope.isConfirmed = false;
                $scope.counter.pause = false;
                return;
            }

            // Vérification du delai de contractualisation
            $scope.errorMessage = contractingDelayService.VerifyContractingDelay($scope.timeToContracting);
            if ($scope.errorMessage) {
                $scope.isToLate = true;
                $scope.isConfirmed = false;
                return;
            }

            // Création de la vm du contrat
            var newContractBaseVm = getContractBaseVm();

            if ($scope.step === "validation") {
                // Gestion des contacts
                if ($scope.signingEnabled && parametersFactory.Parameters.bUseTableConnections.Value === "1" && !$scope.selectedConnection.Connection.IdConnection)
                    getListConnections();
                // Envoi signature YOUSIGN
                else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                    checkContractAndSendSmsForYouSign(newContractBaseVm);
                // Envoi signature SANS TIERS DE CONFIANCE
                else if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active)
                    checkContractAndSendSmsForWithoutThirdParty(newContractBaseVm);
                else
                    SaveNewContratBase(newContractBaseVm);
            }
            else if ($scope.step === "signatureContract") {
                // Vérification du code pour signature SANS TIERS DE CONFIANCE et sauvegarde du contrat
                if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active)
                    checkSmsCodeAndSaveContractBaseForWithoutThirdParty(newContractBaseVm);
                // Vérification du code pour signature YOUSIGN et mise à jour du contrat
                else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                    checkSmsCodeForYouSign();
            }
        };

        $scope.ConditionParticularsOnChange = function (condition) {
            conditionsService.ConditionParticularsOnChange($scope.resumeOffer.IdOffer, condition, '', $rootScope.idExpirySelected, $scope.resumeOffer.IdVariety);
        };

        $scope.AutocompletConditionParticularsOnChange = function (selectedValue, condition) {
            condition.selectedValue = selectedValue.originalObject.IdTreeCondition;
            $scope.conditionSelected = condition;
            conditionsService.ConditionParticularsOnChange($scope.resumeOffer.IdOffer, condition, '', $rootScope.idExpirySelected, $scope.resumeOffer.IdVariety);
        };

        $scope.AutocompleteCondPartSearch = function (str, LstOptions) {
            var matches = [];
            LstOptions.forEach(function (option) {
                if ((option.Label.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
                    matches.push(option);
                }
            });
            return matches;
        };

        $scope.inputChanged = function (str) {
            if ($scope.conditionSelected) {
                $scope.conditionSelected.LstConditionParticularsChilds = [];
            }
        };

        $scope.SendSecurityCode = function () {
            $scope.security.Code = "";

            if ($rootScope.ModuleElectronicSignature.Active && $rootScope.activateSignature) {
                var idConnection = $scope.selectedConnection.Connection.IdConnection ? $scope.selectedConnection.Connection.IdConnection : "";
                signatureService.SendSmsForElectronicSignature($scope.timeToContracting, "", idConnection, function (res) {
                    $scope.isToLate = res.isToLate;
                    $scope.errorMessage = res.errorMessage;
                    $scope.successMessage = res.successMessage;
                });
            }
            else if ($scope.moduleYousignIsActive && $rootScope.activateSignature) {
                signatureService.ResendCodeYouSign($scope.signatureYouSign.Signature.MemberId, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.errorMessage = data[0].errors[0].Message;
                            $scope.isConfirmed = false;
                        }
                    }
                    else {
                        if (data) {
                            $scope.newAuthenticationId = data.AuthenticationId;
                            $scope.errorMessage = "";
                            $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_OfferSubscribe_CodeResend.Content;
                        }
                    }
                });
            }
        };

        $scope.Cancel = function () {
            $location.path("user/offers/view/" + enumFactory.TypeOffer.Ferme);
        };

        $scope.ChangeVariety = function () {
            if ($scope.bUseDepositVolume) {
                $scope.selectedVariety = $filter('filter')($scope.resumeOffer.LstVarieties, { IdVariety: $scope.resumeOffer.IdVariety }, true)[0];
                $scope.TextQtyLeftDepositToSell = $rootScope.editableObjectsFactory.EditableObjects.User_Subscribe_TextQtyLeftDepositToSell.Content.replace("[QtyLeftDeposit]", $scope.selectedVariety.QuantityLeftDepositToSell ? $scope.selectedVariety.QuantityLeftDepositToSell : 0);
            }
            GetCondParts();
        };

        $scope.ProductQuantityLeftDepositToSellFunction = function () {
            if ($scope.bUseDepositVolume && $scope.resumeOffer.LstVarieties.length === 0)
            {
                $scope.TextQtyLeftDepositToSell = $rootScope.editableObjectsFactory.EditableObjects.User_Subscribe_TextQtyLeftDepositToSell.Content.replace("[QtyLeftDeposit]", $scope.resumeOffer.ProductQuantityLeftDepositToSell ? $scope.resumeOffer.ProductQuantityLeftDepositToSell : 0);
            }
        };

        $rootScope.onRefresh = function () {
            init();
        };

        function goToStep(step) {
            $scope.step = step;
            moveVehicle();
        }

        function getContractBaseVm() {
            return {
                IdTypeOrder: $scope.resumeOffer.idTypeOrder,
                IdOffer: $scope.idOfferSelected,
                IdCrop: $scope.resumeOffer.IdCrop,
                IdProduct: $scope.resumeOffer.IdProduct,
                Quantity: $scope.resumeOffer.tonnageCurrent,
                BasePrice: $scope.resumeOffer.idTypeOrder === enumFactory.TypeOrders.Vente.IdTypeOrder ? ($scope.contractualisationWithFuture ? $scope.resumeOffer.BasePrice : $scope.resumeOffer.BasePriceWithIncidence) : null, // Uniquement pour les ventes: Prix de la base de l'offre + incidences
                OfferBasePrice: $scope.resumeOffer.BasePrice, // Prix de la base de l'offre
                TargetBasePrice: !$scope.resumeOffer.targetPriceCurrent ? null : $scope.resumeOffer.targetPriceCurrent, // Uniquement pour les prix objectifs : prix saisi par l'agri lors de la souscription (donc contient les incidences)
                IdVariety: $scope.resumeOffer.IdVariety,
                LstTreeUserGroupConditionSelectedVms: $scope.resumeOffer.LstGroupConditionsSelectedValues,
                DateEndTargetPrice: $scope.resumeOffer.DateEndTargetPriceUser,
                IdOfferExpiry: $scope.resumeOffer.IdOfferExpiry,
                IdContractCommitment: $scope.idContractCommitment,
                IdTypeTargetBasePriceMessage: $scope.targetPriceNotification.IsMail ? $scope.enumTypeTargetPriceMessage.Mail : $scope.enumTypeTargetPriceMessage.Sms,
                IdContractFuture: $scope.idContractFuture,
                EffortBaseTc: $scope.effortBaseTc,
                PaymentDateString: $rootScope.User.IsSuperTc && $rootScope.paramNameCoop === enumFactory.Coops.Ducroquet ? $scope.resumeOffer.PaymentDateString : null,
                DateSubscriptionForcedString: $scope.resumeOffer.DateSubscriptionForcedString,
                IdConnection: $scope.selectedConnection.Connection.IdConnection
            };
        }

        function checkContractAndSendSmsForWithoutThirdParty(newContractBaseVm) {
            signatureService.CheckContractBaseAndSendSmsForElectronicSignature(newContractBaseVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.counter.pause = false;
                    $scope.isConfirmed = false;
                    $scope.security.Code = "";
                    $scope.security.CodeSendingAway = false;
                    $scope.showListChooseContact = false;
                    goToStep("signatureContract");
                }
            });
        }

        function checkContractAndSendSmsForYouSign(newContractBaseVm) {
            contractBaseService.SaveNewContractBaseAndSendSmsYouSign(newContractBaseVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                        $scope.showLoader = false;
                    }
                } else {
                    $scope.isConfirmed = true;
                    $scope.idContractBase = data.IdContractBase;
                    $scope.hashContractBase = data.HashContractBase;
                    if (data) {
                        if (data.CanAvoidSigning) {
                            $scope.showConfirmButton = true;
                            $scope.counter = {};
                            $scope.counter.start = false;
                            goToStep("confirmation");
                        }
                        else {
                            $scope.showConfirmButton = true;
                            $scope.showListChooseContact = false;
                            $scope.signatureYouSign = data;
                            $scope.isConfirmed = false;
                            goToStep("signatureContract");
                        }
                    } else {
                        $scope.showConfirmButton = true;
                    }
                }
            });
        }

        function getListConnections() {
            signatureService.GetListConnection($rootScope.selectedClientFromTcForSubscribe.IdUser, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.listConnection = data;
                    if ($scope.listConnection.length === 0)
                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty_NoBinding_Contacte.Content;
                    else if ($scope.listConnection.length === 1)
                    {
                        $scope.selectedConnection.Connection.IdConnection = $scope.listConnection[0].IdConnection;
                        $scope.ValidateConnection();
                        return;
                    }
                    $scope.isConfirmed = false;
                    $scope.showListChooseContact = true;
                    goToStep("signatureContract");
                }
            });
        }

        function checkSmsCodeAndSaveContractBaseForWithoutThirdParty(newContractBaseVm) {
            var idContractBase = "";
            if ($scope.idContractBase)
                idContractBase = $scope.idContractBase;
            
            signatureService.CheckSecurityCodeBase($scope.security.Code, idContractBase, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    newContractBaseVm.IsSignedWithoutThirdParty = true;
                    SaveNewContratBase(newContractBaseVm);
                }
            });
        }

        function checkSmsCodeForYouSign() {
            $scope.isConfirmed = true;
            $scope.showConfirmButton = false;
            var idConnection = $scope.selectedConnection.Connection.IdConnection ? $scope.selectedConnection.Connection.IdConnection : "";
            signatureService.SignatureWithCodeYouSign($scope.signatureYouSign.Signature.FilesIds[0], $scope.newAuthenticationId ? $scope.newAuthenticationId : $scope.signatureYouSign.Signature.AuthenticationId, $scope.security.Code, idConnection, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    if (data && (data === "true" || data === "1")) {
                        $scope.errorMessage = "";
                        goToStep("confirmation");
                    }
                    else {
                        $scope.successMessage = "";
                        $scope.showConfirmButton = true;
                        $scope.isConfirmed = false;
                        if (!$scope.security.Code)
                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterCodeReceivedBySms.Content;
                        else
                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_CodeEnteredNotCorrespondToCodeSended.Content;
                    }
                }
            });
        }

        init();
    }]);
