AgrimarketControllers.controller('loginController', [
    '$scope', "$route", '$rootScope', '$http', '$location', "currentUserFactory", 'configurationFactory', '$log', '$timeout', '$filter', 'cropsService', 'parametersService',
    'parametersFactory', 'modulesFactory', 'authenticationService', 'quoteService', 'enumFactory', 'contentService', 'userService', '$routeParams',
    'editableObjectsFactory', 'editableObjectsService', '$templateCache', '$window', 'routeService', '$sce',
    function ($scope, $route, $rootScope, $http, $location, currentUserFactory, configurationFactory, $log, $timeout, $filter, cropsService, parametersService,
        parametersFactory, moduleFactory, authenticationService, quoteService, enumFactory, contentService, userService, $routeParams, editableObjectsFactory,
        editableObjectsService, $templateCache, $window, routeService, $sce) {

        if (($rootScope.AuthorizedBackdoor !== true || $rootScope.AuthorizedBackdoor === undefined) && ($location.$$path === "/backdoor/" || $location.$$path === "/backdoor/profile") && !sessionStorage.getItem("LOG_AVIV"))
            $location.path("/backdoor");

        if ($location.protocol().indexOf("https") !== -1) {
            for (var url in configurationFactory.Url) {
                configurationFactory.Url[url] = configurationFactory.Url[url].replace("http:", "https:");
            }
        }

        if ($routeParams.showMenu) { 
            sessionStorage.setItem("showMenu", $routeParams.showMenu);
        }

        $scope.titleBloc = "Connexion";
        $scope.displayLoginBloc = true;
        $scope.displaySelectExploitationBloc = false;
        $rootScope.currentUserRole = '';
        currentUserFactory.User = null;
        $rootScope.isLogged = false;
        $rootScope.loading = false;
        $rootScope.showMenuCotation = false;
        $rootScope.editableMenus = [];
        $rootScope.menuAndPageContentVms = [];
        $scope.sendMailErrorMessage = "";
        $scope.loginErrorMessage = "";
        $rootScope.selectedClientFromTc = undefined;
        $rootScope.selectedClientFromTcForSubscribe = undefined;
        $scope.modeEdition = false;
        $scope.user = {};
        $scope.user.Login = $routeParams.login ? $routeParams.login : $scope.user.Login;
        $scope.user.Password = $routeParams.password ? $routeParams.password : $scope.user.Password;
        $rootScope.showMenu = sessionStorage.getItem("showMenu") ? sessionStorage.getItem("showMenu") == "1" : true;

        $scope.editableObjectConnected = 'Se connecter';
        $scope.editableObjectPassword = 'Mot de passe';
        $scope.editableObjectForgetPassword = 'Mot de passe oublié ?';
        $scope.editableObjectPasswordOrLoginForget = 'Veuillez renseigner un login/mot de passe';
        $scope.editableObjectSendButton = 'Envoyer';
        $scope.editableObjectBackButton = 'Retour';    

        $rootScope.buildReactAppUrl = function (path, args) {
            if (path.length > 0 && path[0] !== "/")
                path = "/" + path;

            var params = args !== undefined ? Object.keys(args).map(function(str) { return str + "=" + args[str]; }) : [];

            if (sessionStorage.getItem("modeEdition") === "true") {
                params.push("modeEdition=1");
            }

            var strParams = params.join("&");

            if (strParams.length > 0)
                strParams = "?" + strParams;

            if ($location.host() === "localhost")
                return $sce.trustAsResourceUrl("http://localhost:12999/v2" + path + strParams);

            return "/v2" + path + strParams;
        };

        /*** Gestion de la connexion par le connecteur UAC ***/
        $scope.loginTiers = $routeParams.loginTiers;
        $scope.jeton = $routeParams.jeton;
        $scope.adresseIpSlc = $routeParams.AdresseIpSlc;
        $scope.redirect = $routeParams.redirect;
        $rootScope.fromConnectorOrUAC = $routeParams.loginTiers && $routeParams.jeton && $routeParams.AdresseIpSlc;

        /*** Gestion du connecteur par hashCode ***/
        $scope.paramForLoginHash = $routeParams.paramForLoginHash;
        $scope.hashForLoginHash = $routeParams.hashForLoginHash;
        $scope.idLanguage = $routeParams.idLanguage;

        /*** Gestion de la connexion par le connecteur désinscription newsletter informations de marché ***/
        $scope.param = $routeParams.param;
        $scope.hash = $routeParams.hash;

        /*** Gestion de la connexion par le connecteur SSO ***/
        $scope.token = $routeParams.token;
        $scope.param = $routeParams.param;

        /*** Gestion de la connexion par le connecteur Url referrer ***/
        $scope.paramConnectorUrlReferrer = $routeParams.paramConnectorUrlReferrer;
        $scope.hideMenu = $routeParams.hideMenu;

        /*** Gestion de la visibilité du menu et de la redirection pour les connecteurs SSO et par hashcode ***/
        if (!$routeParams.redirect)
            $scope.redirect = $routeParams.page;

        /*** Gestion du skin de la page login en fonction de l'URL ***/
        $rootScope.skin = configurationFactory.Skin;
        if (configurationFactory.Skin.skinMultiple) {
            var skin = $filter('filter')(configurationFactory.Skin.skinMultiple, { urlLogin: window.location.href }, true)[0];
            if (skin)
                $rootScope.skin = skin;
        }

        /*** Initialisation des listes des profils de la Backdoor ***/
        if (sessionStorage.getItem("LOG_AVIV")) {
            $scope.useTableConnections = $rootScope.allConnections != null;
            if ($rootScope.allUsers)
                $scope.userlst_selection = $rootScope.allUsers[0];
            if ($rootScope.allConnections)
                $scope.connectionlst_selection = $rootScope.allConnections[0];
            if ($rootScope.allTc)
                $scope.tclst_selection = $rootScope.allTc[0];
            if ($rootScope.allAdmin)
                $scope.adminlst_selection = $rootScope.allAdmin[0];
            if (sessionStorage.getItem("UsersActived"))
                $scope.nbUserActif = sessionStorage.getItem("UsersActived");
        }

        function setRootScopeUserSelected(user) {
            $rootScope.userSelected = user;
        }

        function manageMenu() {
            $(".replaceDiv").each(function () {
                $(this).replaceWith($(this).children());
            });

            //if ($rootScope.skin.css === 'basic-h' || $rootScope.skin.css === 'walagri' || $rootScope.skin.css === "agri-activ") {
            //    menu('horizontal');
            //} else {
            //    menu('vertical');
            //}

            if ($rootScope.skin.type === 1) {
                menu('vertical');
            } else if ($rootScope.skin.type === 2) {
                menu('horizontal');
            } else if ($rootScope.skin.type === 3) {
                menu('updated');
            }

            userMenu('.personnal', '.personnal_nav');
            userMenu('.edition', '.edition_nav');

            if (parametersFactory.Parameters.bDeployMenuWhenLogged.Value === "1")
                $('#primary_nav').find('.deploy').click(); // On simule le click du deploiment du menu afin de recalculer les widths etc          
        }

        function redirectFromLogin() {
            $rootScope.crop = { IdCrop: parseInt(parametersFactory.Parameters.DefaultCrop.Value) };
            $timeout(manageMenu, 0);
            $rootScope.loading = false;
            $rootScope.isLogged = true;

            if ($scope.redirect) { // accès pour connecteur UAC ou SSO
                routeService.locationByParam($scope.redirect);
                return;
            }
            
            if ($rootScope.CurrentUserRole === "User" && sessionStorage.getItem("currentPath") && sessionStorage.getItem("currentPath").indexOf("/user/subscribe/") !== -1)
                $location.path("/user/offers/view/1");
            else if ($rootScope.CurrentUserRole !== "TC" && sessionStorage.getItem("currentPath") && sessionStorage.getItem("currentPath") !== "/backdoor/profile" && sessionStorage.getItem("currentPath") !== "/backdoor/" && sessionStorage.getItem("currentPath") !== "/refresh/") {
                $rootScope.currentPath = $location.$$path;
                $location.path(sessionStorage.getItem("currentPath"));
            } else {
                var firstMenu = $filter('filter')($rootScope.editableMenus, { Enabled: true, Visible: true }, true)[0];
                if (!firstMenu) {
                    $rootScope.isLogged = false;
                    $scope.loginErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_Login_NotAccessMenu.Content;
                }
                else if (firstMenu.PathRoute)
                    $location.path(firstMenu.PathRoute);
                else {
                    var firstMenuPage = $filter('filter')(firstMenu.PagesEditableVms, { Enabled: true, Visible: true }, true)[0];
                    if (!firstMenuPage) {
                        $rootScope.isLogged = false;
                        $scope.loginErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_Login_NotAccessToMenuPage.Content + firstMenu.Label;
                    }
                    else
                        $location.path(firstMenuPage.PathRoute);
                }
            }
        }

        function authenticateResult(data, status) {
            $templateCache.removeAll();
            $rootScope.editableMenus = [];
            $rootScope.menuAndPageContentVms = [];

            if (status === 400) {
                $scope.loginErrorMessage = data[0].errors[0].Message;
                $rootScope.loading = false;
            } else if (status === 500) { 
                $scope.loginErrorMessage = "Veuillez recommencer. Une erreur 500 est survenue.";
                $rootScope.loading = false;
            } else {
                if (data.Connection && !data.User) { // Connexion via un contact
                    $http.defaults.headers.common['Authorization'] = 'Basic ' + data.Token;
                    $scope.titleBloc = "Choix de l'exploitation";
                    $rootScope.loading = false;
                    $scope.idExploitationSelected = null;
                    $scope.displaySelectExploitationBloc = true;
                    $scope.displayLoginBloc = false;
                    $rootScope.usersOfConnection = data.UsersOfConnection;
                }
                else if (data.User.ResetPassword !== undefined && data.User.ResetPassword !== null && data.User.ResetPassword === true) {
                    $rootScope.loading = false;
                    $location.path("/resetPassword").search({ keyPassword: data.User.KeyPassword, title: "Sécurisez votre mot de passe" });
                } else 
                    authenticateProcess(data);
            }
        }

        function authenticateProcess(data) {
            if (data.User.IdCompagny && configurationFactory.Skin.skinMultiple)
                $rootScope.skin = $filter('filter')(configurationFactory.Skin.skinMultiple, { IdCompagny: data.User.IdCompagny }, true)[0];

            gtag('set', 'userId', data.User.IdUser);

            /*** Gestion objets éditables ***/
            editableObjectsFactory.EditableObjects = data.ContentMenuEditable.EditableObjects;
            $rootScope.editableObjectsFactory = editableObjectsFactory;

            $rootScope.globalLabels = {
                Table_Item: $rootScope.editableObjectsFactory.EditableObjects.Table_Item.Content,
                AllItems: $rootScope.editableObjectsFactory.EditableObjects.AllItems.Content,
                AllItems_F: $rootScope.editableObjectsFactory.EditableObjects.AllItems_F.Content,
                Edit: $rootScope.editableObjectsFactory.EditableObjects.Edit.Content,
                Cancel: $rootScope.editableObjectsFactory.EditableObjects.Cancel.Content,
                Edit_Table: $rootScope.editableObjectsFactory.EditableObjects.Edit_Table.Content,
                Edit_Actions: $rootScope.editableObjectsFactory.EditableObjects.Edit_Actions.Content,
                Edit_Fixations_Actions: $rootScope.editableObjectsFactory.EditableObjects.Edit_Fixations_Actions.Content,
                Edit_Blocks: $rootScope.editableObjectsFactory.EditableObjects.Edit_Blocks.Content,
                Reduce_Menu: $rootScope.editableObjectsFactory.EditableObjects.Reduce_Menu.Content,
                Text: $rootScope.editableObjectsFactory.EditableObjects.Text.Content,
                BlockText: $rootScope.editableObjectsFactory.EditableObjects.BlockText.Content,
                UnitQuotes: $rootScope.editableObjectsFactory.EditableObjects.UnitQuotes.Content,
                LastUpdatedOn: $rootScope.editableObjectsFactory.EditableObjects.LastUpdatedOn.Content,
                PublishedOn: $rootScope.editableObjectsFactory.EditableObjects.PublishedOn.Content,
                Crop: $rootScope.editableObjectsFactory.EditableObjects.Crop.Content,
                Language: $rootScope.editableObjectsFactory.EditableObjects.Language.Content,
                Welcome: $rootScope.editableObjectsFactory.EditableObjects.Welcome.Content,
                SocialCapital: $rootScope.editableObjectsFactory.EditableObjects.SocialCapital.Content,
                MyPersonalInfo: $rootScope.editableObjectsFactory.EditableObjects.MyPersonalInfo.Content,
                Disconnect: $rootScope.editableObjectsFactory.EditableObjects.Disconnect.Content,
                Save: $rootScope.editableObjectsFactory.EditableObjects.Save.Content,
                EnterContractNumber: $rootScope.editableObjectsFactory.EditableObjects.EnterContractNumber.Content,
                EnterClientNumber: $rootScope.editableObjectsFactory.EditableObjects.EnterClientNumber.Content,
                EnterContractorNumber: $rootScope.editableObjectsFactory.EditableObjects.EnterContractorNumber.Content,
                EnterContractBaseNumber: $rootScope.editableObjectsFactory.EditableObjects.EnterContractBaseNumber.Content,
                ModificationOfThe: $rootScope.editableObjectsFactory.EditableObjects.ModificationOfThe.Content,
                Yes: $rootScope.editableObjectsFactory.EditableObjects.Yes.Content,
                No: $rootScope.editableObjectsFactory.EditableObjects.No.Content,
                Validate: $rootScope.editableObjectsFactory.EditableObjects.Validate.Content,
                ValidateOption: $rootScope.editableObjectsFactory.EditableObjects.ValidateOption.Content,
                SocialCapitalCurrency: $rootScope.editableObjectsFactory.EditableObjects.SocialCapitalCurrency.Content,
                All: $rootScope.editableObjectsFactory.EditableObjects.All.Content,
                NoCommitment: $rootScope.editableObjectsFactory.EditableObjects.NoCommitment.Content,
                Confirmation: $rootScope.editableObjectsFactory.EditableObjects.Confirmation.Content,
                BackToOffers: $rootScope.editableObjectsFactory.EditableObjects.BackToOffers.Content,
                SendNewCode: $rootScope.editableObjectsFactory.EditableObjects.SendNewCode.Content,
                Varieties: $rootScope.editableObjectsFactory.EditableObjects.Varieties.Content,
                Select: $rootScope.editableObjectsFactory.EditableObjects.Select.Content,
                ClickHere: $rootScope.editableObjectsFactory.EditableObjects.ClickHere.Content,
                SeeOffers: $rootScope.editableObjectsFactory.EditableObjects.SeeOffers.Content,
                SeeContracts: $rootScope.editableObjectsFactory.EditableObjects.SeeContracts.Content,
                Fix: $rootScope.editableObjectsFactory.EditableObjects.Fix.Content,
                Details: $rootScope.editableObjectsFactory.EditableObjects.Details.Content,
                ShowPdf: $rootScope.editableObjectsFactory.EditableObjects.ShowPdf.Content,
                NoBase: $rootScope.editableObjectsFactory.EditableObjects.NoBase.Content,
                NoFuture: $rootScope.editableObjectsFactory.EditableObjects.NoFuture.Content,
                EnterAgriNumber: $rootScope.editableObjectsFactory.EditableObjects.EnterAgriNumber.Content,
                EnterNameOffer: $rootScope.editableObjectsFactory.EditableObjects.EnterNameOffer.Content,
                Currency: $rootScope.editableObjectsFactory.EditableObjects.Currency.Content,
                CurrencyWithMarketWsdCoefficent: $rootScope.editableObjectsFactory.EditableObjects.CurrencyWithMarketWsdCoefficent.Content,
                UnitQuantity: $rootScope.editableObjectsFactory.EditableObjects.UnitQuantity.Content,
                QuotesAccess: $rootScope.editableObjectsFactory.EditableObjects.QuotesAccess.Content,
                SettingMenu: $rootScope.editableObjectsFactory.EditableObjects.SettingMenu.Content,
                Overview: $rootScope.editableObjectsFactory.EditableObjects.Overview.Content
            };

            if (data.User.Role === "User" && data.Parameters.bValidateCGUIsFirstConnection.Value === "1" && !data.User.bValidatedCGU) {
                sessionStorage.setItem("hasRefresh", false);
                $rootScope.isLogged = false;
                parametersFactory.Parameters = data.Parameters;
                $rootScope.IdUserToValidateCGU = data.User.IdUser;
                sessionStorage.setItem("LOG", "{ \"login\" : \"" + data.User.Login + "\", \"token\" : \"" + data.Token + "\"}");
                $location.path("/validateCGU");
                return;
            } else {
                if (sessionStorage.getItem("hasValidateCGU"))
                    sessionStorage.removeItem("hasValidateCGU");
            }

            if (data.SiteUnavailable && !$rootScope.modeEdition) {
                sessionStorage.removeItem("LOG");
                $rootScope.isLogged = false;
                $location.path("/siteUnavailable");
            }
            else {
                if (data.SiteUnavailable && $rootScope.modeEdition) {
                    $location.path("/siteUnavailable");
                    $rootScope.showMenu = false;
                }

                $http.defaults.headers.common['Authorization'] = 'Basic ' + data.Token;
                sessionStorage.setItem("hasRefresh", false);
                if (data.User !== null && data.User.Login)
                    sessionStorage.setItem("LOG", "{ \"login\" : \"" + data.User.Login + "\", \"token\" : \"" + data.Token + "\"}");

                if (sessionStorage.getItem("LOG_AVIV")) {
                    if ($rootScope.modeEdition !== "undefined" && $rootScope.modeEdition !== undefined && $rootScope.modeEdition !== "false" && $rootScope.modeEdition !== false) {
                        $rootScope.modeEdition = true;
                        sessionStorage.setItem("modeEdition", true);
                    } else {
                        if (sessionStorage.getItem("modeEdition"))
                            $rootScope.modeEdition = sessionStorage.getItem("modeEdition");
                        else
                            $rootScope.modeEdition = false;
                    }
                }

                /*** Gestion utilisateur courant ***/
                currentUserFactory.User = data.User;
                currentUserFactory.Connection = data.Connection;
                $rootScope.User = data.User;
                $rootScope.idConnectionLogged = data.Connection ? data.Connection.IdConnection : undefined;
                $rootScope.CurrentUserRole = currentUserFactory.User.Role;
                setRootScopeUserSelected(currentUserFactory.User);
                
                /*** DEBUT ENUMFACTORY ***/
                /*** Gestion typeOrders ***/
                enumFactory.TypeOrders.Vente.Label = $filter('filter')(data.ListTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Vente.IdTypeOrder }, true)[0].TypeOrderLabel;
                enumFactory.TypeOrders.Prixobjectif.Label = $filter('filter')(data.ListTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Prixobjectif.IdTypeOrder }, true)[0].TypeOrderLabel;
                /*** Gestion Actions ***/
                enumFactory.Action.Action_Signing.ContentDefault = editableObjectsFactory.EditableObjects.Action_Signing.Content;
                enumFactory.Action.Action_Modification.ContentDefault = editableObjectsFactory.EditableObjects.Action_Modification.Content;
                enumFactory.Action.Action_Cancel.ContentDefault = editableObjectsFactory.EditableObjects.Action_Cancel.Content;
                enumFactory.Action.Action_Detail.ContentDefault = editableObjectsFactory.EditableObjects.Action_Detail.Content;
                enumFactory.Action.Action_DisplayPdf.ContentDefault = editableObjectsFactory.EditableObjects.Action_DisplayPdf.Content;
                enumFactory.Action.Action_Delete.ContentDefault = editableObjectsFactory.EditableObjects.Action_Delete.Content;
                enumFactory.Action.Action_Duplicate.ContentDefault = editableObjectsFactory.EditableObjects.Action_Duplicate.Content;

                enumFactory.SubscribeActions.GlobalSold.Label = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Vente.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.GlobalPrice.IdTypeFixation }, true)[0].LabelAction;
                enumFactory.SubscribeActions.GlobalSold.LabelDefault = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Vente.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.GlobalPrice.IdTypeFixation }, true)[0].TypeOrderLabel;
                enumFactory.SubscribeActions.GlobalTargetPrice.Label = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Prixobjectif.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.GlobalPrice.IdTypeFixation }, true)[0].LabelAction;
                enumFactory.SubscribeActions.GlobalTargetPrice.LabelDefault = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Prixobjectif.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.GlobalPrice.IdTypeFixation }, true)[0].TypeOrderLabel;
                enumFactory.SubscribeActions.BaseSold.Label = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Vente.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.Base.IdTypeFixation }, true)[0].LabelAction;
                enumFactory.SubscribeActions.BaseSold.LabelDefault = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Vente.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.Base.IdTypeFixation }, true)[0].TypeOrderLabel;
                enumFactory.SubscribeActions.BaseTargetPrice.Label = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Prixobjectif.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.Base.IdTypeFixation }, true)[0].LabelAction;
                enumFactory.SubscribeActions.BaseTargetPrice.LabelDefault = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Prixobjectif.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.Base.IdTypeFixation }, true)[0].TypeOrderLabel;
                enumFactory.SubscribeActions.FutureSold.Label = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Vente.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.Future.IdTypeFixation }, true)[0].LabelAction;
                enumFactory.SubscribeActions.FutureSold.LabelDefault = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Vente.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.Future.IdTypeFixation }, true)[0].TypeOrderLabel;
                enumFactory.SubscribeActions.FutureTargetPrice.Label = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Prixobjectif.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.Future.IdTypeFixation }, true)[0].LabelAction;
                enumFactory.SubscribeActions.FutureTargetPrice.LabelDefault = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Prixobjectif.IdTypeOrder, IdTypeFixation: enumFactory.TypeFixations.Future.IdTypeFixation }, true)[0].TypeOrderLabel;
                enumFactory.SubscribeActions.CommitCommitment.Label = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Engagement.IdTypeOrder, IdTypeFixation: null }, true)[0].LabelAction;
                enumFactory.SubscribeActions.CommitCommitment.LabelDefault = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Engagement.IdTypeOrder, IdTypeFixation: null }, true)[0].TypeOrderLabel;
                enumFactory.SubscribeActions.SubscribeOption.Label = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Option.IdTypeOrder, IdTypeFixation: null }, true)[0].LabelAction;
                enumFactory.SubscribeActions.SubscribeOption.LabelDefault = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.Option.IdTypeOrder, IdTypeFixation: null }, true)[0].TypeOrderLabel;
                enumFactory.SubscribeActions.SubscribeBlankContract.Label = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.ContratVierge.IdTypeOrder, IdTypeFixation: null }, true)[0].LabelAction;
                enumFactory.SubscribeActions.SubscribeBlankContract.LabelDefault = $filter('filter')(data.ListTypeFixationTypeOrders, { IdTypeOrder: enumFactory.TypeOrders.ContratVierge.IdTypeOrder, IdTypeFixation: null }, true)[0].TypeOrderLabel;
                /*** Gestion ActionOption ***/
                enumFactory.ActionOption.Action_Execute.ContentDefault = editableObjectsFactory.EditableObjects.Action_Execute.Content;
                enumFactory.ActionOption.Action_Abandon.ContentDefault = editableObjectsFactory.EditableObjects.Action_Abandon.Content;
                /*** Gestion OfferActions ***/
                enumFactory.OfferActions.Action_SetLimitsAlertSms.ContentDefault = editableObjectsFactory.EditableObjects.Action_SetLimitsAlertSms.Content;
                /*** Gestion CommitmentAction ***/
                enumFactory.CommitmentAction.CommitFixation.ContentDefault = editableObjectsFactory.EditableObjects.CommitFixation.Content;
                /*** Gestion ProductTypeCommitAction ***/
                enumFactory.ProductAndTypeCommitAction.CommitOffer.ContentDefault = editableObjectsFactory.EditableObjects.CommitOffer.Content;
                enumFactory.ProductAndTypeCommitAction.CommitOffer.IsVisible = editableObjectsFactory.EditableObjects.CommitOffer.IsVisible;
                enumFactory.ProductAndTypeCommitAction.CommitDetail.ContentDefault = editableObjectsFactory.EditableObjects.CommitDetail.Content;
                enumFactory.ProductAndTypeCommitAction.CommitDetail.IsVisible = editableObjectsFactory.EditableObjects.CommitDetail.IsVisible;
                /*** Gestion UsersOfTcActions ***/
                enumFactory.UsersOfTcActions.Action_EditInfosPerso.ContentDefault = editableObjectsFactory.EditableObjects.Action_EditInfosPerso.Content;
                enumFactory.UsersOfTcActions.Action_SendIdentifiers.ContentDefault = editableObjectsFactory.EditableObjects.Action_SendIdentifiers.Content;

                /*** Gestion elementInfoMarketHomePages ***/
                enumFactory.ElementsInfoMarketHomePage.Quotes.Label = ($filter('filter')(data.ListElementInfoMarketHomePages, { IdElementInfoMarketHomePage: enumFactory.ElementsInfoMarketHomePage.Quotes.IdElementInfoMarketHomePage }, true)[0]).Label;
                enumFactory.ElementsInfoMarketHomePage.News.Label = ($filter('filter')(data.ListElementInfoMarketHomePages, { IdElementInfoMarketHomePage: enumFactory.ElementsInfoMarketHomePage.News.IdElementInfoMarketHomePage }, true)[0]).Label;
                enumFactory.ElementsInfoMarketHomePage.Synthesis.Label = ($filter('filter')(data.ListElementInfoMarketHomePages, { IdElementInfoMarketHomePage: enumFactory.ElementsInfoMarketHomePage.Synthesis.IdElementInfoMarketHomePage }, true)[0]).Label;
                enumFactory.ElementsInfoMarketHomePage.Quotes.LabelDefault = ($filter('filter')(data.ListElementInfoMarketHomePages, { IdElementInfoMarketHomePage: enumFactory.ElementsInfoMarketHomePage.Quotes.IdElementInfoMarketHomePage }, true)[0]).LabelDefault;
                enumFactory.ElementsInfoMarketHomePage.News.LabelDefault = ($filter('filter')(data.ListElementInfoMarketHomePages, { IdElementInfoMarketHomePage: enumFactory.ElementsInfoMarketHomePage.News.IdElementInfoMarketHomePage }, true)[0]).LabelDefault;
                enumFactory.ElementsInfoMarketHomePage.Synthesis.LabelDefault = ($filter('filter')(data.ListElementInfoMarketHomePages, { IdElementInfoMarketHomePage: enumFactory.ElementsInfoMarketHomePage.Synthesis.IdElementInfoMarketHomePage }, true)[0]).LabelDefault;
                /*** Gestion typeFixations ***/
                enumFactory.TypeFixations.Base.LabelAction = ($filter('filter')(data.ListTypeFixations, { IdTypeFixation: enumFactory.TypeFixations.Base.IdTypeFixation }, true)[0]).Label;
                enumFactory.TypeFixations.Future.LabelAction = ($filter('filter')(data.ListTypeFixations, { IdTypeFixation: enumFactory.TypeFixations.Future.IdTypeFixation }, true)[0]).Label;
                enumFactory.TypeFixations.GlobalPrice.LabelAction = ($filter('filter')(data.ListTypeFixations, { IdTypeFixation: enumFactory.TypeFixations.GlobalPrice.IdTypeFixation }, true)[0]).Label;

                $rootScope.enumFactory = enumFactory;
                /*** FIN ENUMFACTORY ***/

                /*** Gestion menus ***/
                $rootScope.editableMenus = data.ContentMenuEditable.MenuEditableVms;
                $rootScope.menuAndPageContentVms = data.ContentMenuEditable.MenuAndPageContentVms;
                
                /*** Gestion modules (global aux 3 roles) ***/
                moduleFactory.Modules = data.Modules;
                $rootScope.Modules = moduleFactory.Modules;
                $rootScope.ModuleAutomaticCover = moduleFactory.Modules.ModuleAutomaticCover;
                $rootScope.ModuleObjectifAutomatique = moduleFactory.Modules.ModuleObjectifAutomatique;
                $rootScope.ModuleEcommerce = moduleFactory.Modules.ModuleEcommerce;
                $rootScope.ModulePrixConstruit = moduleFactory.Modules.ModulePrixConstruit;
                $rootScope.ModuleNews = moduleFactory.Modules.ModuleNews;
                $rootScope.ModuleCotation = moduleFactory.Modules.ModuleCotation;
                $rootScope.ModuleMyAccount = moduleFactory.Modules.ModuleMyAccount;
                $rootScope.ModuleMyRecommendation = moduleFactory.Modules.ModuleMyRecommendation;
                $rootScope.ModuleHashtagri = moduleFactory.Modules.ModuleHashtagri;
                $rootScope.ModuleMultipleCompagnies = moduleFactory.Modules.ModuleMultipleCompagnies;
                $rootScope.ModuleMultilingue = moduleFactory.Modules.ModuleMultilingue;
                $rootScope.ModuleAlertesSms = moduleFactory.Modules.ModuleAlertesSms;
                $rootScope.ModuleEngagementALaMasse = moduleFactory.Modules.ModuleEngagementALaMasse;
                $rootScope.ModuleEngagementDeSurface = moduleFactory.Modules.ModuleEngagementSurface;
                $rootScope.ModuleMultiCampagne = moduleFactory.Modules.ModuleMultiCampagne;

                /*** Gestion parametres (global aux 3 roles) ***/
                parametersFactory.Parameters = data.Parameters;
                $rootScope.paramEuronextRoyaltyChoice = parametersFactory.Parameters.EuronextRoyaltyChoice.Value;
                $rootScope.paramUrlEuronextQuotes = parametersFactory.Parameters.UrlEuronextQuotes.Value;
                $rootScope.parambDesactivateModificationObjectifPrice = parametersFactory.Parameters.bDesactivateModificationObjectifPrice.Value === "1";
                $rootScope.languages = angular.copy(data.ListLanguages);
                $rootScope.language = data.ListLanguages ? $filter('filter')(data.ListLanguages, { IdLanguage: data.IdLanguageDefaultOrSave }, true)[0] : { IdLanguage: data.IdLanguageDefaultOrSave, IsDefault: true };
                $rootScope.paramIsTerrenaGroup = parametersFactory.Parameters.IsTerrenaGroup.Value === "1";
                $rootScope.paramIsSynoaCoop = parametersFactory.Parameters.IsSynoaCoop.Value === "1";
                $rootScope.paramBCommitmentHomePageAll = parametersFactory.Parameters.bCommitmentHomePageAll.Value === "1";
                $rootScope.parambPreFillTonnage = parametersFactory.Parameters.bPreFillTonnage.Value === "1";
                $rootScope.paramNameCoop = parametersFactory.Parameters.NameCoop.Value;
                $rootScope.paramDelayedSigning = parametersFactory.Parameters.DelayedSigning.Value === "1";
                $rootScope.paramBUseOperation = parametersFactory.Parameters.bUseOperation.Value === "1";
                $rootScope.paramBDisableSubscription = parametersFactory.Parameters.bDisableSubscription.Value === "1";
                $rootScope.paramIsTCAuthorizedToSignInModeDelayed = parametersFactory.Parameters.IsTCAuthorizedToSignInModeDelayed.Value === "1";
                $rootScope.paramIsTcAuthorizedToForcedDate = parametersFactory.Parameters.IsTcAuthorizedToForcedDate.Value === "1";        
                $rootScope.parambActivedParamRollExpiry = parametersFactory.Parameters.bActivedParamRollExpiry.Value === "1";
                $rootScope.parambActivedSubscribeOfferWithZeroPrice = parametersFactory.Parameters.ActiveSubscribeOfferWithZeroPrice.Value === "1";
                $rootScope.parambAuthorizeContributionsForBuiltPrice = parametersFactory.Parameters.bAuthorizeContributionsForBuiltPrice.Value === "1";

                switch (currentUserFactory.User.Role) {
                    case 'Admin':
                        sessionStorage.removeItem("IdUserAdmin");
                        $rootScope.DefaultUserEditionMode = parametersFactory.Parameters.DefaultUserEditionMode ? parametersFactory.Parameters.DefaultUserEditionMode.Value : null;
                        $rootScope.DefaultTcEditionMode = parametersFactory.Parameters.DefaultTcEditionMode ? parametersFactory.Parameters.DefaultTcEditionMode.Value : null;
                        $rootScope.ModuleExportProjection = moduleFactory.Modules.ModuleExportProjection;
                        $rootScope.ModuleElectronicSignature = moduleFactory.Modules.ModuleElectronicSignature;
                        $rootScope.ModuleSignatureElectroniqueYouSign = moduleFactory.Modules.ModuleSignatureElectroniqueYouSign;
                        $rootScope.paramBUseDeliveriesDates = parametersFactory.Parameters.bUseDeliveriesDates.Value === "1";
                        $rootScope.paramBUsePaymentDate = parametersFactory.Parameters.bUsePaymentDate.Value === "1";
                        $rootScope.paramActivateNetPrice = parametersFactory.Parameters.ActivateNetPrice.Value === "1";
                        $rootScope.activateSignature = parametersFactory.Parameters.ActivateSignature.Value === "1";
                        $rootScope.paramBUseTableConnections = parametersFactory.Parameters.bUseTableConnections.Value === "1";
                        $rootScope.parambUseLoginSecondary = parametersFactory.Parameters.bUseLoginSecondary.Value === "1";
                        break;
                    case 'User':
                        $rootScope.typePresentOffers = parametersFactory.Parameters.TypePresentOffers.Value;
                        $rootScope.ModuleRequestPrices = moduleFactory.Modules.ModuleRequestPrices;
                        $rootScope.ModuleElectronicSignature = moduleFactory.Modules.ModuleElectronicSignature;
                        $rootScope.ModuleSignatureElectroniqueYouSign = moduleFactory.Modules.ModuleSignatureElectroniqueYouSign;
                        $rootScope.activateSignature = (parametersFactory.Parameters.ActivateSignature.Value === "1" && parametersFactory.Parameters.bExclusiveSignatureForTc.Value === "0");
                        $rootScope.bDisplayCapitalSocial = parametersFactory.Parameters.bDisplayCapitalSocial.Value === "1";
                        $rootScope.crops = data.ListCrops;
                        break;
                    case 'TC':
                        $rootScope.typePresentOffers = parametersFactory.Parameters.TypePresentOffers.Value;
                        $rootScope.ModuleElectronicSignature = moduleFactory.Modules.ModuleElectronicSignature;
                        $rootScope.ModuleSignatureElectroniqueYouSign = moduleFactory.Modules.ModuleSignatureElectroniqueYouSign;
                        $rootScope.activateSignature = parametersFactory.Parameters.ActivateSignature.Value === "1";
                        $rootScope.parambManageActionUsersOfTc = parametersFactory.Parameters.bManageActionUsersOfTc.Value === "1";
                        $rootScope.paramSelectTcUserGlobally = parametersFactory.Parameters.SelectTcUserGlobally.Value === "1";
                        $rootScope.crops = data.ListCrops;
                        $rootScope.lstClientsFromTc = data.UsersOfTc;
                        $rootScope.minlengthAutocomplete = data.User.IsSuperTc ? 3 : 1;
                        break;
                    default:
                        break;
                }
                redirectFromLogin();
            }
        }

        function getUserSession() {
            var user = JSON.parse(sessionStorage.getItem("LOG"));
            $scope.user.Login = user.login;
            $scope.user.Token = user.token;
            if (sessionStorage.getItem("referrer"))
                $scope.LoginByConnectorWithUrlReferrer();
            else {
                sessionStorage.removeItem("referrer");

                if ($scope.paramForLoginHash && $scope.hashForLoginHash)
                    $scope.ConnectorLoginHash();
                else
                    $scope.Login();
            }
        }

        authenticationService.GetEditableObjectsForLoginPage(function (data, status) {
            if (status == null && !$rootScope.goToSelectExploitation) {
                data.forEach(function (item) {
                    $scope.titleBloc = item.Key == 'LoginPage_Connexion' ? item.Text : $scope.titleBloc;
                    $scope.editableObjectConnected = item.Key == 'LoginPage_Connected' ? item.Text : $scope.editableObjectConnected;
                    $scope.editableObjectPassword = item.Key == 'LoginPage_Password' ? item.Text : $scope.editableObjectPassword;
                    $scope.editableObjectForgetPassword = item.Key == 'LoginPage_ForgetPassword' ? item.Text : $scope.editableObjectForgetPassword;
                    $scope.editableObjectPasswordOrLoginForget = item.Key == 'LoginPage_PasswordOrLoginForget' ? item.Text : $scope.editableObjectPasswordOrLoginForget;
                    $scope.editableObjectSendButton = item.Key == 'LoginPage_SendButton' ? item.Text : $scope.editableObjectSendButton;
                    $scope.editableObjectBackButton = item.Key == 'LoginPage_BackButton' ? item.Text : $scope.editableObjectBackButton;    
                });
            }
        });
        
        $rootScope.GoToEuronextQuote = function (controlerLink, idExpiry) {
            if (parseInt($rootScope.paramEuronextRoyaltyChoice) === parseInt(enumFactory.EuronextRoyalTy.Choice4)) {
                var url = $rootScope.paramUrlEuronextQuotes;

                if (idExpiry)
                    url += (url.indexOf('?') !== -1 ? '&' : '?') + 'idExpiryDefault=' + idExpiry;

                $window.open(url, "_blank");
            }
            else
                $rootScope.goTo(controlerLink);
        };

        $rootScope.GoToInterfaceTc = function () {
            $templateCache.removeAll();
            $rootScope.resetInput = 1;
            userService.DisconnectClientSelectedFromTC(function (data, status) {
                if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $rootScope.selectedClientFromTc = undefined;
                    $rootScope.selectedClientFromTcForSubscribe = undefined;
                    $rootScope.editableMenus = data.MenuEditableVms;
                    $rootScope.menuAndPageContentVms = data.MenuAndPageContentVms;
                    editableObjectsFactory.EditableObjects = data.EditableObjects;
                    $rootScope.editableObjectsFactory = editableObjectsFactory;
                    $location.path($filter('filter')($rootScope.editableMenus, { Enabled: true, Visible: true }, true)[0].PathRoute);
                }
            });
        };

        $rootScope.GoToInterfaceAdmin = function () {
            sessionStorage.setItem("IdUser", sessionStorage.getItem("IdUserAdmin"));
            sessionStorage.removeItem("modeEdition");
            sessionStorage.removeItem("IdUserAdmin");
            $location.path("/loginEditMode/");
        };

        $rootScope.GoToSelectExploitation = function () {
            $templateCache.removeAll();
            userService.DisconnectExploitation(function (data, status) {
                if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $rootScope.idConnectionLogged = undefined;
                    currentUserFactory.Connection = undefined;
                    $rootScope.usersOfConnection = data;
                    $rootScope.goToSelectExploitation = true;
                    $location.path('/login');
                }
            });
        };

        $rootScope.CancelPopUpChangeClientFromTc = function () {
            $rootScope.resetInput = 2;
            $rootScope.selectedClientFromTc = $rootScope.oldClientSelectedFromTc;
        };

        $rootScope.ConnectionToClient = function (client, openPopUp) {
            window.postMessage(JSON.stringify({ type:"idUser", idUser : (currentUserFactory.User.IdClientSelectedFromTc ? currentUserFactory.User.IdClientSelectedFromTc : currentUserFactory.User.IdUser)  }), "*");
            if ($rootScope.selectedClientFromTc && openPopUp !== undefined) {
                $rootScope.resetInput = 0;
                if ($rootScope.selectedClientFromTc.IdUser !== client.IdUser) {
                    $rootScope.selectedClientFromTc = angular.copy(client);
                    $rootScope.selectedClientFromTcForSubscribe = angular.copy(client);
                    openPopUp();
                }
            } else {
                if (client) {
                    $rootScope.selectedClientFromTc = client;
                    $rootScope.selectedClientFromTcForSubscribe = client;
                }
                $rootScope.oldClientSelectedFromTc = angular.copy($rootScope.selectedClientFromTc);
                currentUserFactory.User.IdClientSelectedFromTc = angular.copy($rootScope.selectedClientFromTc.IdUser);
                userService.SaveClientSelectedFromTC($rootScope.selectedClientFromTc.IdUser, true, function (data, status) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else {
                        setRootScopeUserSelected($rootScope.selectedClientFromTc);
                        $rootScope.editableMenus = data.MenuEditableVms;
                        $rootScope.menuAndPageContentVms = data.MenuAndPageContentVms;
                        editableObjectsFactory.EditableObjects = data.EditableObjects;
                        $rootScope.editableObjectsFactory = editableObjectsFactory;
                        $rootScope.resetInput = 3;
                        $timeout(manageMenu, 0);
                        $rootScope.activateSignature = parametersFactory.Parameters.ActivateSignature.Value === "1";
                        $location.path($filter('filter')($rootScope.editableMenus, { Enabled: true, Visible: true }, true)[0].PathRoute);
                        $route.reload();
                    }
                });
            }
        };

        $scope.Login = function () {
            $rootScope.modeEdition = !sessionStorage.getItem("IdUserAdmin") ? false : sessionStorage.getItem("modeEdition");
            $scope.loginErrorMessage = null;
            $rootScope.loading = true;
            if (sessionStorage.getItem("ConnectedToBackdoor") == "true" && $scope.user.Password)
                    authenticationService.LoginForBackdoor($scope.user.Login, $scope.user.Password, authenticateResult);
            else if (sessionStorage.getItem("ConnectedToBackdoor") == "true" && $scope.connection && $scope.connection.Password)
                authenticationService.LoginForBackdoor($scope.connection.Login, $scope.connection.Password, authenticateResult);
            else if ($scope.user.Token)
                authenticationService.LoginByToken($scope.user.Token, authenticateResult);
            else if ($scope.user.Login && $scope.user.Password)
                authenticationService.Login($scope.user.Login, $scope.user.Password, authenticateResult);
            else if (sessionStorage.getItem("IdUser")) {
                if ((sessionStorage.getItem("IdUser") === "undefined" || sessionStorage.getItem("IdUser") === "null") && $scope.user.IdUser)
                    sessionStorage.setItem("IdUser", $scope.user.IdUser);

                authenticationService.LoginEditionMode(sessionStorage.getItem("IdUser"), authenticateResult);
                sessionStorage.removeItem("IdUser");
            }
            else {
                //Pour la backdoor on ne traduit pas les messages d'erreur
                if (sessionStorage.getItem("LOG_AVIV")) {
                    if ($rootScope.allUsers.length === 0 && $rootScope.allTc.length === 0 && $rootScope.allAdmin.length === 0)
                        $scope.loginErrorMessage = "Aucun profil n'a été chargé";
                    else
                        $scope.loginErrorMessage = "Veuillez sélectionner un profil";
                }
                else
                    $scope.loginErrorMessage = $scope.editableObjectPasswordOrLoginForget;

                $rootScope.loading = false;
            }
        };

        $scope.LoginByConnectorOrUAC = function () {
            $scope.loginErrorMessage = null;
            $rootScope.loading = true;

            authenticationService.LoginByConnectorOrUAC($scope.loginTiers, $scope.jeton, $scope.adresseIpSlc, function (data, status) {
                sessionStorage.removeItem("currentPath");
                authenticateResult(data, status);
            });
        };

        $scope.LoginByConnectorForCerelio = function () {
            $scope.loginErrorMessage = null;
            $rootScope.loading = true;

            authenticationService.LoginByConnectorForCerelio($scope.loginTiers, $scope.jeton,function (data, status) {
                sessionStorage.removeItem("currentPath");
                authenticateResult(data, status);
            });
        };

        $scope.LoginByConnectorSso = function () {
            $scope.loginErrorMessage = null;
            $rootScope.loading = true;

            $rootScope.showMenu = $scope.showMenu;
            authenticationService.LoginByConnectorSso($scope.token, $scope.param, function (data, status) {
                if (status === 400) {
                    $scope.loginErrorMessage = data[0].errors[0].Message;
                    $rootScope.loading = false;
                } else {
                    sessionStorage.removeItem("currentPath");
                    authenticateResult(data, status);
                }
            });
        };

        $scope.UnsubscribeNewsMarketInfoByIdExt = function () {
            $scope.errorMessageConnector = null;
            userService.UnsubscribeNewsMarketInfoByIdExt($scope.param, $scope.hash, function (data, status) {
                if (status === 400) {

                    $rootScope.errorMessageUnsubscribeNewsMarketInfoByIdExt = data[0].errors[0].Message;
                    routeService.locationByParam("UnsubscribeNewsMarketInfo");
                } else {
                    editableObjectsFactory.EditableObjects = data;
                    $rootScope.editableObjectsFactory = editableObjectsFactory;
                    routeService.locationByParam("UnsubscribeNewsMarketInfo");
                }

            });
        };

        $scope.LogProfileByBackdoor = function (profile) {
            $scope.user = profile;
            sessionStorage.setItem("ConnectedToBackdoor", true);
            sessionStorage.setItem("IdUser", profile.IdUser);
            sessionStorage.setItem("IdUserAdmin", $scope.user.IdUser);
            sessionStorage.setItem("modeEdition", $scope.modeEdition);

            $scope.Login();
        };

        $scope.LogProfileConnectionByBackdoor = function (profile) {
                $scope.connection = profile;
                sessionStorage.setItem("ConnectedToBackdoor", true);
                sessionStorage.setItem("IdConnection", profile.IdConnection);
                sessionStorage.setItem("IdUserAdmin", profile.IdConnection);
                sessionStorage.setItem("modeEdition", $scope.modeEdition);
            $scope.Login();
        };

        $scope.LoginByConnectorWithUrlReferrer = function () {
            $scope.loginErrorMessage = null;
            $rootScope.loading = true;
            var referrer = (typeof window !== 'undefined' && window.document && window.document.referrer ? window.document.referrer : '');
            if (!referrer) {
                referrer = sessionStorage.getItem("referrer");
                if (!referrer)
                    referrer = '';
            }
            var paramConnectorUrlReferrer = sessionStorage.getItem("paramConnectorUrlReferrer") ? sessionStorage.getItem("paramConnectorUrlReferrer") : $scope.paramConnectorUrlReferrer;
            authenticationService.LoginByConnectorWithUrlReferrer(referrer, paramConnectorUrlReferrer, function (data, status) {
                sessionStorage.removeItem("currentPath");
                sessionStorage.setItem("referrer", referrer);

                //pour gérer le bouton précédent dans iframe, bug remonté par arterris
                if (!sessionStorage.getItem("paramConnectorUrlReferrer")) {
                    sessionStorage.setItem("paramConnectorUrlReferrer", $scope.paramConnectorUrlReferrer);
                }
                if (!sessionStorage.getItem("hideMenu")) {
                    sessionStorage.setItem("hideMenu", $scope.hideMenu);
                } else {
                    $scope.hideMenu = sessionStorage.getItem("hideMenu");
                }
                $rootScope.showMenu = !$scope.hideMenu;
                authenticateResult(data, status);
            });
        };

        $scope.LoginByConnectorWithGuid = function () {
            $scope.loginErrorMessage = null;
            $rootScope.loading = true;

            authenticationService.LoginByConnectorWithGuid(localStorage.getItem("guid"), function (data, status) {
                sessionStorage.removeItem("currentPath");
                authenticateResult(data, status);
            });
        };

        $scope.ConnectorLoginHash = function () {
            $rootScope.modeEdition = $scope.modeEdition;
            $scope.loginErrorMessage = null;
            $rootScope.loading = true;
            $rootScope.showMenu = $scope.showMenu;
            if (!$scope.idLanguage)
                $scope.idLanguage = "";

            authenticationService.ConnectorLoginHash($scope.paramForLoginHash, $scope.hashForLoginHash, $scope.idLanguage, function (data, status) {
                sessionStorage.removeItem("currentPath");
                authenticateResult(data, status);
                $rootScope.loading = false;
            });
        };

        $scope.SendResetPasswordMail = function () {
            $scope.sendMailMessage = null;
            $scope.sendMailErrorMessage = null;

            if (!$scope.login)
                return;
            
            $scope.loading = true;
            authenticationService.SendResetPasswordMail($scope.login, function (data, status) {
                $scope.loading = false;
                if (status !== null) {
                    if (status === 400)
                        $scope.sendMailErrorMessage = data[0].errors[0].Message;
                    return;
                }
                else {
                    $scope.login = null;
                    $scope.mail = null;
                    $scope.sendMailMessage = data;
                }
            });
        };

        $scope.AskAccessCode = function () {
            $scope.sendMailMessage = null;
            $scope.sendMailErrorMessage = null;
            $scope.askAccessCodeErrorMessage = null;
            $scope.askAccessCodeMessage = null;

            //Il faut laisser ces initialisations en cas de champs non renseignés sinon ça met une erreur console et ça n'appelle pas la méthode car les champs sont undefined
            if (!$scope.firstname)
                $scope.firstname = "";
            if (!$scope.name)
                $scope.name = "";
            if (!$scope.socialReason)
                $scope.socialReason = "";
            if (!$scope.mail)
                $scope.mail = "";
            if (!$scope.nTva)
                $scope.nTva = "";
            if (!$scope.siret)
                $scope.siret = "";

            authenticationService.SendMailAskAccessCode($scope.firstname, $scope.name, $scope.mail, $scope.nTva, $scope.socialReason, $scope.siret, function (data, status) {
                
                if (status !== null) {
                    if (status === 400)
                        $scope.askAccessCodeErrorMessage = data[0].errors[0].Message;
                }
                else {
                    $scope.firstname = null;
                    $scope.name = null;
                    $scope.socialReason = null;
                    $scope.mail = null;
                    $scope.nTva = null;
                    $scope.siret = null;
                    $scope.askAccessCodeMessage = data;
                }
            });
        };

        $scope.SelectExploitation = function () {
            $rootScope.goToSelectExploitation = false;
            authenticationService.SelectExploitation($scope.idExploitationSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.selectExploitationErrorMessage = data[0].errors[0].Message;
                    return;
                }
                else
                    authenticateProcess(data);
            });
        };

        $scope.ResetMessages = function () {
            $scope.sendMailErrorMessage = "";
            $scope.loginErrorMessage = "";
            $scope.askAccessCodeErrorMessage = "";
            $scope.askAccessCodeMessage = "";
            $scope.mail = "";

            if ($scope.user) {
                if ($scope.user.Login)
                    $scope.user.Login = "";
                if ($scope.user.Password)
                    $scope.user.Password = "";
            }
            return true;
        };

        $rootScope.MenuLoaded = function () {
            if (!$rootScope.count && $rootScope.editableMenus) {
                var result = $rootScope.editableMenus.filter(function (menu) {
                    return (menu.Visible && menu.Enabled);
                });
                $rootScope.countMax = result.length;
                $rootScope.count = 0;
            }

            $rootScope.count++;

            if ($rootScope.count === $rootScope.countMax) {
                $rootScope.count = undefined;
                $timeout(function () {
                    manageMenu();
                }, 0);
            }
        };

        $rootScope.ManageMenu = function () {
            $timeout(function () {
                manageMenu();
            }, 0);
        };

        $rootScope.SetCurrentClass = function (listPathCurrentClass) {
            var currentclass = "";
            listPathCurrentClass.forEach(function (item) {
                if ($rootScope.currentPath && $rootScope.currentPath.match(item))
                    currentclass = "current";                                    
            });            
            return currentclass;
        };

        $rootScope.AsChangedLanguage = function (idLanguage) {
            $rootScope.language.IdLanguage = idLanguage;
            userService.SaveLanguageSelected($rootScope.language.IdLanguage, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                }
                else
                    window.location.reload();
            });
        };

        if (!sessionStorage.getItem("hasRefresh")) {
            parametersService.ShowAskAccessCodeLink(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.sendMailErrorMessage = data[0].errors[0].Message;
                }
                else
                    $rootScope.ShowAskAccessCodeLink = data.ShowAskAccessCodeLink;
                    $scope.EditableObjectNumTvaIsVisible = data.EditableObjectNumTvaIsVisible;
                    $scope.EditableObjectSiretIsVisible = data.EditableObjectSiretIsVisible;
                    $scope.EditableObjectSocialReasonIsVisible = data.EditableObjectSocialReasonIsVisible;
                    $scope.EditableObjectFirstNameIsVisible = data.EditableObjectFirstNameIsVisible;
                    $scope.EditableObjectLastNameIsVisible = data.EditableObjectLastNameIsVisible;
            });
        }

        if (sessionStorage.getItem("hasRefresh") && sessionStorage.getItem("hasRefresh") === "true" && sessionStorage.getItem("LOG")) {
            getUserSession();
        }
        else if ($scope.user.Login && $scope.user.Password) {
            $scope.Login();
        } else if (sessionStorage.getItem("IdUser") && sessionStorage.getItem("IdUser") !== "undefined") {
            sessionStorage.removeItem("currentPath");
            $scope.Login();
        } else if ($scope.loginTiers && $scope.jeton && $scope.adresseIpSlc) {
            $scope.LoginByConnectorOrUAC();
        } else if ($scope.loginTiers && $scope.jeton && !$scope.adresseIpSlc) {
            $scope.LoginByConnectorForCerelio();
        } else if ($scope.param && $scope.hash) {
            $scope.UnsubscribeNewsMarketInfoByIdExt();
        } else if ($scope.token && $scope.param && $scope.redirect) {
            $scope.LoginByConnectorSso();
        } else if ($scope.paramConnectorUrlReferrer) {
            $scope.LoginByConnectorWithUrlReferrer();
        } else if (sessionStorage.getItem("hasValidateCGU")) {
            sessionStorage.removeItem("currentPath");
            getUserSession();
        } else if ($location.path() === "/loginOuifield") {
            $scope.LoginByConnectorWithGuid();
        }
        else if ($scope.paramForLoginHash && $scope.hashForLoginHash) {
            $scope.ConnectorLoginHash();
        }

        if ($rootScope.goToSelectExploitation) {
            $scope.titleBloc = "Choix de l'exploitation";
            $scope.displaySelectExploitationBloc = true;
            $scope.displayLoginBloc = false;
        }
    }
]);
