AgrimarketControllers.controller('userSubscribeContractBlankController', ['$scope', '$rootScope', '$location', 'enumFactory', 'configurationFactory', 'parametersFactory', 'offerService', 'parametersService', 'contractsService', '$filter', 'conditionsService', 'productsService', 'categoryOfferService', 'signatureService', 'currentUserFactory', 'wsdApiService',
    function ($scope, $rootScope, $location, enumFactory, configurationFactory, parametersFactory, offerService, parametersService, contractsService, $filter, conditionsService, productsService, categoryOfferService, signatureService, currentUserFactory, wsdApiService) {

        var init = function () {
            $scope.checkValidCondition = false;
            $scope.showUrlSellConditionAttachment = false;
            $scope.showUrlUtilConditionAttachment = false;
            $scope.urlpdf = configurationFactory.Url['Api'] + '?idcontract=';
            $scope.withConditions = true;
            $scope.delayedSigning = parametersFactory.Parameters.DelayedSigning.Value === "1";
            $scope.firstSendSmsMessageDelayedSigning = false;
            $scope.user = currentUserFactory.User;
            $scope.enumRoles = enumFactory.Roles;
            $scope.showCheckedCondition = !($rootScope.paramDelayedSigning == '1' && $rootScope.User.IdRole == $scope.enumRoles.TC) || ($rootScope.User.IdRole === $scope.enumRoles.TC && $rootScope.isTcSigningForUser);       
            $scope.mobileNumberToSendSms = $rootScope.selectedClientFromTc ? $rootScope.selectedClientFromTc.MobileNumber : currentUserFactory.Connection ? currentUserFactory.Connection.MobileNumber : $scope.user.MobileNumber;
            $scope.showListChooseContact = false;
            $scope.showConfirmButton = true;
            $scope.orderRoutingEnabled = false;
            $scope.selectedConnection = {
                Connection: {}
            };
            $scope.termsAndConditions = {
                isChecked: false
            };
            $scope.security = {
                Code: ""
            };
            $scope.counter = {
                start: false,
                pause: false
            };

            //Prise en compte des modifications des parametres par l'Admin
            parametersService.GetParameters(function (dataParam, statusParam) {
                if (statusParam !== undefined && statusParam !== null) {
                    if (statusParam === 400) {
                        $scope.loginErrorMessage = dataParam[0].errors[0].Message;
                    }
                } else {
                    parametersFactory.Parameters = dataParam;

                    if (!parametersFactory.Parameters.FileTermCondition.Value)
                        $scope.showUrlSellConditionAttachment = false;
                    else {
                        $scope.urlSellConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FileTermCondition.Value;
                        $scope.showUrlSellConditionAttachment = true;
                    }

                    if (!parametersFactory.Parameters.FileTermeUse.Value)
                        $scope.showUrlUtilConditionAttachment = false;
                    else {
                        $scope.urlUtilConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FileTermeUse.Value;
                        $scope.showUrlUtilConditionAttachment = true;
                    }
                }
            });

            if ($rootScope.goToDelayedSigning && $rootScope.idContractForDelayedSigning) {
                $scope.isGlobalFixation = true;
                $rootScope.goToDelayedSigning = false;
                $scope.delayedSigning = false;
                $rootScope.isTcSigningForUser = false;
                contractsService.GetContractForDelayedSigning($rootScope.idContractForDelayedSigning, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.errorMessage = data[0].errors[0].Message;
                        }
                    } else {
                        $scope.resumeOffer = data;
                        $scope.signingEnabled = $rootScope.ModuleElectronicSignature.Active && $rootScope.activateSignature;
                        $scope.resumeOffer.blankContractPrice = $scope.resumeOffer.FinalPrice - $scope.resumeOffer.TotalConditionsValue;
                        $scope.selectedClientFromTcForSubscribe = $scope.resumeOffer.userOfTc;
                        $rootScope.hasSmsCodeSigningAlreadySend = data.HasSmsCodeSigningAlreadySend;
                        $scope.isSell = data.IdTypeOrder === enumFactory.TypeOrders.Vente.IdTypeOrder;
                        $scope.isObjective = data.IdTypeOrder === enumFactory.TypeOrders.Prixobjectif.IdTypeOrder;
                        goToStep("validation");
                    }
                });
            } else if ($rootScope.idOfferSelected) {
                $rootScope.isTcSigningForUser = false;
                $rootScope.idContractForDelayedSigning = null;
                $rootScope.goToDelayedSigning = false;
                $rootScope.hasSmsCodeSigningAlreadySend = false;
                // Récupération de l'offre à souscrire
                offerService.GetOfferByIdAndIdExpiry($rootScope.idOfferSelected, $rootScope.idExpirySelected, "", function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        $scope.resumeOffer = data;
                        if(!$scope.showCheckedCondition){
                            $scope.showCheckedCondition = !$scope.resumeOffer.ContractSigningEnabled;
                        }
                        $scope.signingEnabled = $rootScope.ModuleElectronicSignature.Active && $rootScope.activateSignature && $scope.resumeOffer.ContractSigningEnabled && ($scope.resumeOffer.ContractValidationDisabled && !$scope.delayedSigning) ;
                        $scope.orderRoutingEnabled = parametersFactory.Parameters.bActivateOrderRoutingForBlankContract.Value === "1" && $scope.resumeOffer.IdTypeCover !== enumFactory.TypeCover.Manuel;
                        
                        if($scope.orderRoutingEnabled)
                            GetMarkets();

                        GetCondParts();

                        if (!$scope.step)
                            goToStep("contractualisation");
                    }
                });
            }
            else
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_IdentifierOfOfferIsMissing.Content;

            productsService.GetAllProducts(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else
                    $scope.products = data;
            });

            categoryOfferService.GetAllCategoryOffers(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.categoryOffers = data;
                    if (data.length > 0) {
                        $scope.categoryOffers.push({ IdCategoryOffer: -1, Label: $rootScope.editableObjectsFactory.EditableObjects.None_female.Content });
                    }
                }
            });
        };

        var GetCondParts = function () {
            var idProduct = $scope.resumeOffer.IdProduct;
            if (!idProduct && $scope.products && $scope.products.length > 0)
                idProduct = $scope.products[0].IdProduct;
            conditionsService.GetCondParts($scope.resumeOffer.IdOffer, $scope.resumeOffer.IdTree, null, false, null, $rootScope.idExpirySelected, $scope.resumeOffer.IdVariety, function (res) {
                $scope.errorMessage = res.errorMessage;
                $scope.conditionParticulars = res.conditionParticulars;
                $scope.withConditions = res.conditionParticulars.length > 0;
            });
        };

        $scope.ChangeVariety = function () {
            GetCondParts();           
        };

        var SaveNewContrat = function (newContractVm) {
            contractsService.SaveNewContract(newContractVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.idContractBlank = data.idContract;
                    $scope.hashContract = data.hashContract;
                    $scope.counter = {};
                    $scope.counter.start = false;
                    goToStep("confirmation");
                }
            });
        };

        $scope.Validate = function () {
            $scope.errorMessage = "";

            // Vérification des conditions particulières
            $scope.resumeOffer.LstGroupConditionsSelectedValues = [];
            if ($scope.withConditions) {
                $scope.errorMessage = conditionsService.CheckAndSetGroupConditions($scope.conditionParticulars, $scope.resumeOffer.LstGroupConditionsSelectedValues);
                if ($scope.errorMessage)
                    return;
            }

            // Vérification du produit
            if (!$scope.resumeOffer.IdProduct) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterAProduct.Content;
                return;
            }

            // Vérification de la variété
            if ($scope.productSelected && $scope.productSelected.Varieties && $scope.productSelected.Varieties.length > 0 && !$scope.resumeOffer.IdVariety) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterAVariety.Content;
                return;
            }
            else
                $scope.varietySelected = $filter('filter')($scope.productSelected.Varieties, { IdVariety: $scope.resumeOffer.IdVariety }, true)[0];

            // Vérification de la catégorie
            if ($scope.categoryOffers.length > 0 && !$scope.resumeOffer.IdCategoryOffer) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterACategoryOffer.Content;
                return;
            }

            // Vérification du tonnage du contrat
            if (!$scope.resumeOffer.tonnageCurrent || isNaN($scope.resumeOffer.tonnageCurrent) || $scope.resumeOffer.tonnageCurrent <= 0) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterAValidQuantity.Content;
                return;
            }

            // Vérification du prix du contrat
            if (!$scope.resumeOffer.blankContractPrice || isNaN($scope.resumeOffer.blankContractPrice) || $scope.resumeOffer.blankContractPrice <= 0) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterAValidPrice.Content;
                return;
            }

            // Vérification du template
            if (!$scope.resumeOffer.IdTemplate && $scope.templates && $scope.templates.length > 0) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseSelectATemplate.Content;
                return;
            }
            else if (!$scope.resumeOffer.IdTemplate && (!$scope.templates || $scope.templates.length === 0)) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_NoTemplateInProductPleaseContactAdmin.Content;
                return;
            }

            // Vérification de la selection du marché d'indexation
            if ( $scope.orderRoutingEnabled && !$scope.resumeOffer.IdMarket) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_RoutinOrderForBlankContract_NoMarketSelected.Content;
                return;
            }

            // Vérification de la selection de l'échéance
            if ( $scope.orderRoutingEnabled && !$scope.resumeOffer.IdWsdExpiry ) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_RoutinOrderForBlankContract_NoExpirySelected.Content;
                return;
            }

            var finalBlankContractPrice = +$scope.resumeOffer.blankContractPrice + conditionsService.GetTotalLstGroupConditionsSelectedValues($scope.resumeOffer.LstGroupConditionsSelectedValues);
            $scope.finalBlankContractPrice = $filter('roundByDecimalAndConverter')(finalBlankContractPrice, $scope.resumeOffer.DecimalCurrencyUnitFinal, $scope.resumeOffer.ConverterCoeffValue);
            
            goToStep("validation");
        };

        $scope.ValidateConnection = function () {
            if (!$scope.selectedConnection.Connection.IdConnection) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty.Content;
                return;
            }
            $scope.showConfirmButton = false;
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.mobileNumberToSendSms = $scope.selectedConnection.Connection.MobileNumber;

            if ($rootScope.idContractForDelayedSigning){
                $scope.SendSecurityCode();
                $scope.firstSendSmsMessageDelayedSigning = false;
                $scope.counter.pause = false;
                $scope.isConfirmed = false;
                $scope.security.Code = "";
                $scope.security.CodeSendingAway = false;
                $scope.showConfirmButton = true;
                $scope.showListChooseContact = false;
            }
            else {
                // Création de la vm du contrat
                var newContractVm = getContractVm();

                checkContractAndSendSmsForWithoutThirdParty(newContractVm);
            }
        };

        var UpdateContract = function (idContract) {
            contractsService.SignContractForDelayedSigning(idContract, $scope.selectedConnection.Connection.IdConnection, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.idContractBlank = data.idContract;
                    $scope.hashContract = data.hashContract;
                    $scope.counter = {};
                    $scope.counter.start = false;
                    goToStep("confirmation");
                }
            });
        };

        $scope.Confirm = function () {
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.counter.pause = true;

            if ($rootScope.idConnectionLogged)
                    $scope.selectedConnection.Connection.IdConnection = $rootScope.idConnectionLogged;

            // Vérification de l'acceptation des condition générale de vente
            if (!$scope.termsAndConditions.isChecked && $scope.showCheckedCondition) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseAcceptGeneralConditionsOfSaleBeforeValidation.Content;
                $scope.isConfirmed = false;
                $scope.counter.pause = false;
                return;
            }

            if ($rootScope.idContractForDelayedSigning) {
                if (!$rootScope.hasSmsCodeSigningAlreadySend) 
                {
                    // Gestion des contacts
                    if ($scope.signingEnabled && parametersFactory.Parameters.bUseTableConnections.Value === "1" && !$scope.selectedConnection.Connection.IdConnection)
                        getListConnections();
                    else
                        $scope.SendSecurityCode();
                    $scope.firstSendSmsMessageDelayedSigning = false;
                }
                else
                    $scope.firstSendSmsMessageDelayedSigning = true;

                $scope.isConfirmed = false;
                goToStep("signatureContract");
            } else {
                // Création de la vm du contrat
                var newContractVm = getContractVm();

                if ($scope.step === "validation") {
                    // Gestion des contacts
                    if ($scope.signingEnabled && parametersFactory.Parameters.bUseTableConnections.Value === "1" && !$scope.selectedConnection.Connection.IdConnection)
                        getListConnections();
                    // Envoi signature SANS TIERS DE CONFIANCE
                    else if ($scope.signingEnabled && $scope.showCheckedCondition)
                        checkContractAndSendSmsForWithoutThirdParty(newContractVm);
                    else
                        SaveNewContrat(newContractVm);
                }
                // Vérification du code pour signature SANS TIERS DE CONFIANCE et sauvegarde du contrat
                else if ($scope.step === "signatureContract")
                    checkSmsCodeAndSaveContractForWithoutThirdParty(newContractVm);
            }
        };

        $scope.UpdateContractDelayedSigning = function () {
            $scope.isConfirmed = true;
            signatureService.CheckSecurityCodeFirmOrCommitment($scope.security.Code, $rootScope.idContractForDelayedSigning, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else
                    UpdateContract($rootScope.idContractForDelayedSigning);
            });
        };

        $scope.SendSecurityCode = function () {
            $scope.security.Code = "";
            var idConnection = $scope.selectedConnection.Connection.IdConnection ? $scope.selectedConnection.Connection.IdConnection : "";
            signatureService.SendSmsForElectronicSignature($scope.timeToContracting, $rootScope.idContractForDelayedSigning, idConnection, function (res) {
                $scope.isToLate = res.isToLate;
                $scope.errorMessage = res.errorMessage;
                if (!$scope.firstSendSmsMessageDelayedSigning)
                    $scope.successMessage = res.successMessage;
                else
                    $scope.successMessage = "";
                $scope.firstSendSmsMessageDelayedSigning = false;
            });
        };

        $scope.ConditionParticularsOnChange = function (condition) {
            var idProduct = $scope.resumeOffer.IdProduct;
            if (!idProduct && $scope.products && $scope.products.length > 0)
                idProduct = $scope.products[0].IdProduct;
            conditionsService.ConditionParticularsOnChange($scope.resumeOffer.IdOffer, condition, '', $rootScope.idExpirySelected, $scope.resumeOffer.IdVariety);
        };

        $scope.AutocompletConditionParticularsOnChange = function (selectedValue, condition) {
            condition.selectedValue = selectedValue.originalObject.IdTreeCondition;
            $scope.conditionSelected = condition;
            var idProduct = $scope.resumeOffer.IdProduct;
            if (!idProduct && $scope.products && $scope.products.length > 0)
                idProduct = $scope.products[0].IdProduct;
            conditionsService.ConditionParticularsOnChange($scope.resumeOffer.IdOffer, condition, '', $rootScope.idExpirySelected, $scope.resumeOffer.IdVariety);
        };

        $scope.AutocompleteCondPartSearch = function (str, LstOptions) {
            var matches = [];
            LstOptions.forEach(function (option) {
                if ((option.Label.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
                    matches.push(option);
                }
            });
            return matches;
        };

        $scope.inputChanged = function (str) {
            if ($scope.conditionSelected) {
                $scope.conditionSelected.LstConditionParticularsChilds = [];
            }
        };

        $scope.Cancel = function () {
            $location.path("user/offers/view/" + enumFactory.TypeOffer.ContratVierge);
        };

        $scope.SelectProduct = function () {
            $scope.productSelected = $filter('filter')($scope.products, { IdProduct: $scope.resumeOffer.IdProduct }, true)[0];
            if ($scope.productSelected) {
                $scope.resumeOffer.IdProduct = $scope.productSelected.IdProduct;
                $scope.templates = $scope.resumeOffer.Templates.filter(function (t) {
                    return $filter('filter')($scope.productSelected.Templates, { IdTemplate: t.IdTemplate }, true)[0] !== undefined;
                });

                if ($scope.templates.length === 0)
                    $scope.resumeOffer.IdTemplate = null;
                else if ($scope.templates.length === 1)
                    $scope.resumeOffer.IdTemplate = $scope.templates[0].IdTemplate;

                $scope.lstVarieties = [];
                $scope.lstVarietiesByProduct = $scope.productSelected.Varieties;
                if ($scope.resumeOffer.IdCompagny != null) {
                    $scope.lstVarietiesByProduct.forEach(function (variety) {
                        if (variety.Compagnies.length > 0) {
                            if ($filter('filter')(variety.Compagnies, { IdCompagny: $scope.resumeOffer.IdCompagny })[0] !== undefined)
                                $scope.lstVarieties.push(variety);
                        }
                    });
                }
                else
                    $scope.lstVarietiesByProduct.forEach(function (variety) {
                        if (variety.Compagnies.length > 0)
                            $scope.lstVarieties.push(variety);
                    });
            }
            else {
                $scope.resumeOffer.IdProduct = null;
                $scope.resumeOffer.IdTemplate = null;
                $scope.templates = [];
                $scope.lstVarieties = [];
            }
        };

        $rootScope.onRefresh = function () {
            init();
        };

        function goToStep(step) {
            $scope.step = step;
            moveVehicle();
        }

        function getContractVm() {
            return {
                IdTypeContract: enumFactory.TypeContract.ContratVierge,
                IdTypeOrder: enumFactory.TypeOrders.ContratVierge.IdTypeOrder,
                Price: $scope.resumeOffer.blankContractPrice,
                IdOfferExpiry: $scope.resumeOffer.IdOfferExpiry,
                IdOffer: $scope.idOfferSelected,
                IdCrop: $scope.resumeOffer.IdCrop,
                IdProduct: $scope.resumeOffer.IdProduct,
                Quantity: $scope.resumeOffer.tonnageCurrent,
                IdVariety: $scope.resumeOffer.IdVariety,
                LstTreeUserGroupConditionSelectedVms: $scope.resumeOffer.LstGroupConditionsSelectedValues,
                FreeText: $scope.resumeOffer.FreeText,
                IdCategoryOffer: $scope.resumeOffer.IdCategoryOffer > 0 ? $scope.resumeOffer.IdCategoryOffer : null,
                IdTemplate: $scope.resumeOffer.IdTemplate,
                IdConnection: $scope.selectedConnection.Connection.IdConnection,
                IdWsdExpiry: $scope.resumeOffer.IdWsdExpiry
            };
        }

        function checkContractAndSendSmsForWithoutThirdParty(newContractVm) {
            signatureService.CheckAndSendSmsForElectronicSignature(newContractVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.counter.pause = false;
                    $scope.isConfirmed = false;
                    $scope.security.Code = "";
                    $scope.security.CodeSendingAway = false;
                    $scope.showConfirmButton = true;
                    $scope.showListChooseContact = false;
                    goToStep("signatureContract");
                }
            });
        }

        function getListConnections() {
            signatureService.GetListConnection($rootScope.selectedClientFromTcForSubscribe.IdUser, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.listConnection = data;
                    if ($scope.listConnection.length === 0)
                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty_NoBinding_Contacte.Content;
                    else if ($scope.listConnection.length === 1)
                    {
                        $scope.selectedConnection.Connection.IdConnection = $scope.listConnection[0].IdConnection;
                        $scope.ValidateConnection();
                        return;
                    }
                    $scope.isConfirmed = false;
                    $scope.showListChooseContact = true;
                    goToStep("signatureContract");
                }
            });
        }

        function checkSmsCodeAndSaveContractForWithoutThirdParty(newContractVm) {
            var idContract = "";
            if ($scope.idContract)
                idContract = $scope.idContract;

            signatureService.CheckSecurityCodeFirmOrCommitment($scope.security.Code, idContract, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    newContractVm.IsSignedWithoutThirdParty = true;
                    SaveNewContrat(newContractVm);
                }
            });
        }

        $scope.GetExpiries = function () {
            $scope.resumeOffer.IdWsdExpiry = null;
            var market = $filter('filter')($scope.lstMarkets, { IdMarket: $scope.resumeOffer.IdMarket })[0];
            $scope.lstExpiry = [];
            if(!!$scope.resumeOffer.IdMarket){
                wsdApiService.GetExpiriesWSDByIdMarketWithQuotes($scope.resumeOffer.IdMarket, null, market.TypeLocat, function (data, status) {
                    if (status === null) {
                        $scope.lstExpiry = data.Expiries;
                    } else if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else {
                        $scope.errorMessage = status;
                    }
                });
            }
        };

        function GetMarkets() {
                wsdApiService.GetMarketsWSDToEuronext(function (data, status) {
                    if (status === null)
                        $scope.lstMarkets = data;
                    else
                        $scope.errorMessage = status;
                });
        }

        init();
    }]);
